import {
  NominatedPurchaserTerms,
  ProductionOrderId,
  PurchaseRequest,
  PurchaseRequestId,
  PurchaseRequestState,
  Purchaser,
  StorageLocation,
  snPurchaseRequest,
} from "adl-gen/ferovinum/app/db";
import {
  EstimateOrActualPrice,
  PurchaseRequestBreakdownForPurchaser,
  PurchaseRequestReq,
  PurchaserAcceptPurchaseRequestReq,
  PurchaserPurchaseRequestReq,
  PurchaserPurchaseRequestView,
  PurchaserStorageLocationView,
  UpdatePurchaseRequestResp,
} from "adl-gen/ferovinum/app/api";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useCallback } from "react";
import { useAppService } from "./use-app-service";
import { AppService } from "adl-gen/app-service";
import { getDefaultValueForField } from "utils/adl-utils";
import {
  OrgPurchaseRequestSummary,
  PurchaseRequestTimeline,
  OrgRejectedPurchaseRequestSummary,
} from "adl-gen/ferovinum/app/nompurchaser";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";

export const PURCHASE_REQUEST_DEFAULT_EXPIRY_DAYS = getDefaultValueForField<number>(snPurchaseRequest, "expiryDays");
// The number of days that the purchaser has to collect the order
export const PURCHASE_REQUEST_DEFAULT_COLLECTION_DAYS = getDefaultValueForField<number>(
  snPurchaseRequest,
  "collectionDays",
);

type RejectedExpiredStates = "expired" | "purchaserRejected";
type RejectedOrExpiredPurchaseRequestStates = Extract<PurchaseRequestState, RejectedExpiredStates>;
type ValidPurchaseRequestStates = Exclude<PurchaseRequestState, RejectedExpiredStates>;
export interface OrganisationBasePurchaseRequestData
  extends Pick<
    PurchaseRequest,
    | "stateEvents"
    | "paymentTermsPeriod"
    | "expiryDays"
    | "collectionDays"
    | "stockPreparationDays"
    | "purchaserCurrency"
    | "settlementCurrency"
    | "purchaseRequestNumber"
    | "purchaseRequestDeliveryOption"
    | "salePriceType"
    | "deliveryStatuses"
    | "advanceUponDeliveryPaymentDate"
    | "orgNetReceivablePaymentDate"
    | "poFileUrl"
    | "deliveryInstructionDocs"
    | "collectedDate"
    | "deliveredDate"
    | "purchaserInvoicePdfUrl"
  > {
  purchaserCoversDeliveryCost: boolean;
  storageLocation: StorageLocation;
  purchaserName: string;
  terms: NominatedPurchaserTerms;
  productionOrderReference?: {
    orderNumber: string;
    productionOrderId: ProductionOrderId;
  };
  timeline: PurchaseRequestTimeline;
  availablePdfs: AvailablePdf[];
}

export interface OrganisationValidPurchaseRequestData extends OrganisationBasePurchaseRequestData {
  state: ValidPurchaseRequestStates;
  summary: OrgPurchaseRequestSummary;
}

export interface OrganisationRejectedOrExpiredPurchaseRequestData extends OrganisationBasePurchaseRequestData {
  state: RejectedOrExpiredPurchaseRequestStates;
  summary: OrgRejectedPurchaseRequestSummary;
}

export type OrganisationPurchaseRequestData =
  | OrganisationValidPurchaseRequestData
  | OrganisationRejectedOrExpiredPurchaseRequestData;

export const loadOrganisationPurchaseRequest = async (
  appService: AppService,
  purchaseRequestId: string,
): Promise<OrganisationPurchaseRequestData> => {
  const details = appService.getPurchaseRequestDetailsForOrganisation(purchaseRequestId);
  const breakdown = appService.thirdPartySalesSummaryById(purchaseRequestId);
  const timeline = appService.getPurchaseRequestTimeline(purchaseRequestId);

  return await Promise.all([details, breakdown, timeline]).then(results => {
    const details = results[0];
    const valuation = results[1];
    const timeline = results[2];

    const purchaseRequest = details.purchaseRequest.value;

    if (valuation.kind !== "success" && valuation.kind !== "rejectedOrExpired") {
      throw new Error(valuation.kind);
    }

    const productionOrderReference =
      purchaseRequest.productionOrderId && details.productionOrderNumber
        ? {
            productionOrderId: purchaseRequest.productionOrderId,
            orderNumber: details.productionOrderNumber,
          }
        : undefined;

    return {
      purchaseRequestId: purchaseRequestId,
      ...purchaseRequest,
      products: valuation.value.lineItems,
      summary: valuation.value,
      purchaserName: details.purchaserName,
      storageLocation: details.storageLocation,
      terms: details.terms,
      productionOrderReference: productionOrderReference,
      timeline: timeline,
      availablePdfs: details.availablePdfs,
    } as OrganisationPurchaseRequestData;
  });
};

/** Hook that returns the details of a purchase request relevant to an organisation */
export const useOrganisationPurchaseRequest = (purchaseRequestId: string) => {
  const appService = useAppService();

  const [loadingPurchaseRequest, refresh] = useLoadingDataState<OrganisationPurchaseRequestData>(
    useCallback(
      async () => await loadOrganisationPurchaseRequest(appService, purchaseRequestId),
      [appService, purchaseRequestId],
    ),
  );
  return { loadingPurchaseRequest, refresh };
};

export function isNegativePrice(price: EstimateOrActualPrice) {
  return price.kind === "estimate"
    ? Number(price.value.from) < 0 || Number(price.value.from) < 0
    : Number(price.value) < 0;
}
export function isPositiveOrZeroPrice(price: EstimateOrActualPrice) {
  return !isNegativePrice(price);
}

export interface PurchaserPurchaseRequestData
  extends Pick<
    PurchaseRequest,
    | "state"
    | "stateEvents"
    | "paymentTermsPeriod"
    | "expiryDays"
    | "collectionDays"
    | "stockPreparationDays"
    | "purchaserCurrency"
    | "purchaseRequestNumber"
    | "purchaseRequestDeliveryOption"
    | "salePriceType"
    | "collectedDate"
    | "deliveredDate"
  > {
  purchaseRequestId: string;
  breakdown: PurchaseRequestBreakdownForPurchaser;
  purchaserCoversDeliveryCost: boolean;
  storageLocation: PurchaserStorageLocationView;
  organisationName: string;
  terms: NominatedPurchaserTerms;
  timeline: PurchaseRequestTimeline;
  purchaserName: string;
  purchaserDetails: Purchaser;
}

export interface PurchaseRequestService {
  getPurchaseRequestDetailsForPurchaser(reqId: PurchaseRequestId): Promise<PurchaserPurchaseRequestView>;
  getPurchaseRequestTimeline(reqId: PurchaseRequestId): Promise<PurchaseRequestTimeline>;
  getPurchaseRequestBreakdownForPurchaser(
    req: PurchaserPurchaseRequestReq,
  ): Promise<PurchaseRequestBreakdownForPurchaser>;
  getNominatedPurchaserTermsForPurchaser(req: PurchaseRequestId): Promise<NominatedPurchaserTerms>;
  purchaserRejectPurchaseRequest(req: PurchaseRequestReq): Promise<UpdatePurchaseRequestResp>;
  purchaserAcceptPurchaseRequest(req: PurchaserAcceptPurchaseRequestReq): Promise<UpdatePurchaseRequestResp>;
}

const loadPurchaserPurchaseRequest = async (
  purchaseRequestService: PurchaseRequestService,
  purchaseRequestId: string,
): Promise<PurchaserPurchaseRequestData> => {
  const [purchaserPurchaseRequestView, breakdown, terms, timeline] = await Promise.all([
    purchaseRequestService.getPurchaseRequestDetailsForPurchaser(purchaseRequestId),
    purchaseRequestService.getPurchaseRequestBreakdownForPurchaser({ purchaseRequestId }),
    purchaseRequestService.getNominatedPurchaserTermsForPurchaser(purchaseRequestId),
    purchaseRequestService.getPurchaseRequestTimeline(purchaseRequestId),
  ]);

  return {
    ...purchaserPurchaseRequestView,
    purchaserCurrency: purchaserPurchaseRequestView.currency,
    breakdown,
    terms,
    timeline,
  };
};

/** Hook that returns the details of a purchase request relevant to a purchaser */
export const usePurchaserPurchaseRequest = (
  purchaseRequestService: PurchaseRequestService,
  purchaseRequestId: string,
) => {
  const [loadingPurchaseRequest, refresh] = useLoadingDataState<PurchaserPurchaseRequestData>(
    useCallback(
      async () => await loadPurchaserPurchaseRequest(purchaseRequestService, purchaseRequestId),
      [purchaseRequestService, purchaseRequestId],
    ),
  );
  return { loadingPurchaseRequest, refresh };
};
