import React, { useCallback } from "react";
import { Loader } from "components/widgets/loader/loader";
import { OrganisationProductionOrderCreatedPageView } from "./organisation-production-order-created/organisation-production-order-created-page-view";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { FinishingService, NumberOfUnits, Product, ProductionOrderId } from "adl-gen/ferovinum/app/db";
import { isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { OrganisationProductionOrderAcceptedPageView } from "./organisation-production-order-accepted/organisation-production-order-accepted-page-view";
import {
  ProductionOrderDetailsView,
  ProductionOrderTaskRunView,
  PurchaseRequestReference,
} from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useIsProducer } from "../../../../../hooks/use-is-producer";
import { assertNotUndefined } from "utils/hx/util/types";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { WithDbId } from "adl-gen/common/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { Instant } from "adl-gen/common";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";

export const OrganisationProductionOrderDetailsPage = () => {
  const history = useHistory();
  const [showAlert] = useAlert();
  const { productionOrderId } = useParams<{
    productionOrderId: ProductionOrderId;
  }>();
  const appService = useAppService();
  const getProductOrder = useCallback(
    () => appService.getProductionOrder({ productionOrderId }),
    [productionOrderId, appService],
  );
  const [loadingProductionOrderDetailsView] = useLoadingDataState(getProductOrder);
  const isProducer = useIsProducer({
    storageLocationId: isLoaded(loadingProductionOrderDetailsView)
      ? loadingProductionOrderDetailsView.value.storageLocation.id
      : undefined,
    organisationId: useSelectedOrgId(),
  });

  const onCancelProductionOrder = useCallback(async () => {
    try {
      await appService
        .cancelProductionOrder({ productionOrderId })
        .then(_ => history.push(AppRoutes.OrgProductionOrders));
    } catch {
      await showAlert({ title: "Error", body: "Production order could not be cancelled" }).then(_ =>
        history.push(`${AppRoutes.OrgProductionOrderDetails}/${encodeURI(productionOrderId)}`),
      );
    }
  }, [appService, history, productionOrderId, showAlert]);

  if (!productionOrderId) {
    return <Redirect to={AppRoutes.OrgProductionOrders} />;
  } else {
    return (
      <Loader loadingStates={[loadingProductionOrderDetailsView, isProducer]} fullScreen>
        {isLoaded(loadingProductionOrderDetailsView) && isLoaded(isProducer) && (
          <OrganisationProductionOrderDetailsPageView
            productionOrderId={productionOrderId}
            productionOrderDetailsView={loadingProductionOrderDetailsView.value}
            onCancelProductionOrder={onCancelProductionOrder}
            isProducer={assertNotUndefined(isProducer.value)}
          />
        )}
      </Loader>
    );
  }
};

export interface OrgProductionOrderTaskView {
  sourceProduct: WithDbId<Product>;
  targetQuantity: NumberOfUnits;
  budgetQuantity?: NumberOfUnits;
  finishingService: FinishingService;
  finishingProduct: Product;
  productionOrderTaskRuns: ProductionOrderTaskRunView[];
  pricePerUnit: MonetaryValue;
  setupCost: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  isComplete: boolean;
}

export interface OrganisationInnerProductionOrderDetailsPageViewProps {
  orderNumber: string;
  storageLocationName: string;
  orderDate: string;
  productionOrderTasks: OrgProductionOrderTaskView[];
  requestedCompletionDate?: string;
  notes?: string;
  totalSetupCost: MonetaryValue;
  subtotalCost: MonetaryValue;
  totalCost: MonetaryValue;
  isProducer: boolean;
  productionOrderId: ProductionOrderId;
  purchaseRequestReference: PurchaseRequestReference | null;
  cancelledAt?: Instant;
  availablePdfs: AvailablePdf[];
}

interface OrganisationProductionOrderDetailsPageViewProps {
  productionOrderId: ProductionOrderId;
  isProducer: boolean;
  productionOrderDetailsView: ProductionOrderDetailsView;
  onCancelProductionOrder: () => Promise<void>;
}
const OrganisationProductionOrderDetailsPageView = ({
  productionOrderId,
  isProducer,
  productionOrderDetailsView,
  onCancelProductionOrder,
}: OrganisationProductionOrderDetailsPageViewProps) => {
  const productionOrderTasks: OrgProductionOrderTaskView[] = productionOrderDetailsView.productionOrderTasks.map(
    poTask => {
      return {
        ...poTask,
        // Note(Nicole): Production orders currently support only 1 source product item but the underlying model
        // allows multiple already
        sourceProduct: poTask.sourceProductItems[0].product,
        finishingProduct: poTask.resultingProduct,
        budgetQuantity: poTask.sourceProductItems[0].budgetQuantity ?? undefined,
      };
    },
  );

  const props: OrganisationInnerProductionOrderDetailsPageViewProps = {
    ...productionOrderDetailsView,
    storageLocationName: productionOrderDetailsView.storageLocation.value.locationName,
    orderDate: productionOrderDetailsView.createdAt,
    requestedCompletionDate: productionOrderDetailsView.requestedCompletionDate ?? undefined,
    notes: productionOrderDetailsView.comments ?? undefined,
    productionOrderId,
    isProducer,
    productionOrderTasks,
    purchaseRequestReference: productionOrderDetailsView.purchaseRequestReference,
    cancelledAt: productionOrderDetailsView.cancelledAt ?? undefined,
    availablePdfs: productionOrderDetailsView.availablePdfs,
  };

  // Created state
  if (productionOrderDetailsView.latestStatus === null || productionOrderDetailsView.latestStatusCreatedAt === null) {
    return (
      <OrganisationProductionOrderCreatedPageView
        {...props}
        onCancel={productionOrderDetailsView.latestStatusCreatedAt ? undefined : onCancelProductionOrder}
      />
    );
  }

  // Pending state from a purchase request that has not been accepted yet by the purchaser
  if (productionOrderDetailsView.latestStatus === "pending") {
    return <OrganisationProductionOrderCreatedPageView {...props} />;
  }

  return (
    <OrganisationProductionOrderAcceptedPageView
      {...props}
      latestStatus={{
        status: productionOrderDetailsView.latestStatus,
        date: productionOrderDetailsView.latestStatusCreatedAt,
      }}
      completionPercentage={productionOrderDetailsView.completionPercentage}
    />
  );
};
