import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Currency,
  NewDealRequestState,
  NewDealRequestStateEvent,
  TopLevelUnitType,
  snTopLevelUnitType,
} from "adl-gen/ferovinum/app/db";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { DealTermsView } from "adl-gen/ferovinum/app/views";
import { useConfirmationDialog, useInfoDialog } from "components/context/global-dialog/use-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { Link } from "components/widgets/link/link";
import { ProductSummary, ProductSummaryProps } from "components/widgets/product-summary/product-summary";
import React, { isValidElement, useCallback, useMemo, useState } from "react";
import { getFormLabelForUnionField, getTopLevelForUnitType } from "utils/adl-utils";
import { formatLocalDate } from "utils/date-utils";
import { NewDealRequestDetailsView, ProductRequest } from "utils/hooks/use-new-deal-request-impl";
import { assertNotUndefined } from "utils/hx/util/types";
import {
  BulkQuantityUnitType,
  add,
  getVesselCapacity,
  isCase,
  isSinglesUnitType,
  unitsLabelForUnitType,
} from "utils/model-utils";
import { isNegativeNumber, isPositiveNumber } from "utils/ts-utils";
import { CurrencyRenderer } from "../currency-renderer/currency-renderer";
import { ProvisionalCurrencyRenderer } from "../currency-renderer/provisional-currency-renderer";
import { TermsLink } from "../link/terms-link";
import { PaginatedTable } from "../paginated-table/paginated-table";

type NewStockVariantTotalsDetails = {
  kind: "newStock";
  grossTotalDepositAmount?: MonetaryValue;
  netTotalDepositAmount?: MonetaryValue;
  totalDiscountAmount?: MonetaryValue;
  totalNetAdvanceAmount?: MonetaryValue;
  additionalDetails?: AdditionalPayableDetails;
};
type ExistingStockVariantTotalsDetails = {
  kind: "existingStock";
  grossTotalDepositAmount?: MonetaryValue;
  totalNetAdvanceAmount?: MonetaryValue;
  additionalDetails?: AdditionalPayableDetails;
};
type VariantTotalsDetails = NewStockVariantTotalsDetails | ExistingStockVariantTotalsDetails;

interface AdditionalPayableDetails {
  purchaseCurrencyFeroGrossTotal: MonetaryValue;
  settlementCurrencyFeroGrossTotal?: MonetaryValue;
}

interface NewDealRequestContractNoteProps {
  newDealRequest: NewDealRequestDetailsView;
  stateEvents: NewDealRequestStateEvent[];
}

export interface NewDealRequestSummaryTableProps {
  variantTotalsDetails: VariantTotalsDetails;
  products: ProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  disableProductLinks?: boolean;
  actionDetails?: NewDealRequestActionsProps;
  onProductClicked?: (productId: string) => void;
  contractNoteDetails?: NewDealRequestContractNoteProps;
}

export const NewDealRequestSummaryTable = ({
  variantTotalsDetails,
  products,
  purchaseCurrency,
  settlementCurrency,
  disableProductLinks,
  actionDetails,
  onProductClicked,
  contractNoteDetails,
}: NewDealRequestSummaryTableProps) => {
  const orgHasAccepted =
    contractNoteDetails && contractNoteDetails.stateEvents.map(e => e.state).includes("orgAccepted");
  return (
    <>
      <NewDealRequestSummaryTableWithoutActions
        variantTotalsDetails={variantTotalsDetails}
        products={products}
        purchaseCurrency={purchaseCurrency}
        settlementCurrency={settlementCurrency}
        disableProductLinks={disableProductLinks}
        onProductClicked={onProductClicked}
      />

      {actionDetails && <NewDealRequestActions {...actionDetails} />}

      {orgHasAccepted && (
        <Stack direction="row" justifyContent="flex-end" marginTop={5}>
          <TermsLink dealTermsView={contractNoteDetails.newDealRequest.dealTermsView} />
        </Stack>
      )}
    </>
  );
};

const NewDealRequestSummaryTableWithoutActions = ({
  variantTotalsDetails,
  products,
  purchaseCurrency,
  settlementCurrency,
  disableProductLinks,
  onProductClicked,
}: Omit<NewDealRequestSummaryTableProps, "actionDetails" | "contractNoteDetails">) => {
  const singles = products.filter(p => isSinglesUnitType(p.product.value.unitType));
  const nonSingles = useMemo(() => {
    const filteredProducts = products.filter(p => !isSinglesUnitType(p.product.value.unitType));
    const result = new Map<TopLevelUnitType, ProductRequest[]>();
    filteredProducts.forEach(p => {
      const topLevelUnitType = getTopLevelForUnitType(p.product.value.unitType);
      if (result.has(topLevelUnitType)) {
        assertNotUndefined(result.get(topLevelUnitType)).push(p);
      } else {
        result.set(topLevelUnitType, [p]);
      }
    });
    return result;
  }, [products]);
  const isNewStock = variantTotalsDetails.kind === "newStock";

  // This is the common case where we have a large number of non-single products of the same topLevelUnitType
  // e.g. lots of casks for whisky distilleries.
  if (singles.length === 0 && nonSingles.size === 1) {
    const [topLevelUnitType, products]: [TopLevelUnitType, ProductRequest[]] = nonSingles.entries().next().value;
    return (
      <PaginatedTable
        initialRows={products}
        HeaderContent={
          <NonSinglesHeaderRow
            isNewStock={isNewStock}
            topLevelUnitType={topLevelUnitType}
            quantityUnits={products[0].numberOfUnits.kind as BulkQuantityUnitType}
          />
        }
        BodyContent={
          <NonSinglesTableRows
            rows={products}
            purchaseCurrency={purchaseCurrency}
            settlementCurrency={settlementCurrency}
            disableProductLink={disableProductLinks}
          />
        }
        BodyBaseline={
          <SummaryTotalRow
            products={products}
            variantTotalsDetails={variantTotalsDetails}
            purchaseCurrency={purchaseCurrency}
            settlementCurrency={settlementCurrency}
          />
        }
      />
    );
  }

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }}>
      <Table>
        {singles.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <SummaryTableCell>Unit purchase price</SummaryTableCell>
              <SummaryTableCell>Deposit</SummaryTableCell>
              <SummaryTableCell>Fees</SummaryTableCell>
              <SummaryTableCell>Repurchase quantity</SummaryTableCell>
              <SummaryTableCell>Deadline</SummaryTableCell>
              <SummaryTableCell>{isNewStock ? "Deposit subtotal" : "Net advance amount"}</SummaryTableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {singles.map((p, idx) => (
            <NewDealRequestSummaryTableRow
              key={p.product.id}
              pReq={p}
              purchaseCurrency={purchaseCurrency}
              currency={settlementCurrency}
              disableProductLink={disableProductLinks}
              isLastRow={idx === singles.length - 1}
              onClick={onProductClicked ? () => onProductClicked(p.product.id) : undefined}
            />
          ))}
          {nonSingles.size > 0 && (
            <>
              {[...nonSingles.keys()].map(topLevelUnitType => {
                const products = assertNotUndefined(nonSingles.get(topLevelUnitType));
                return (
                  <React.Fragment key={topLevelUnitType}>
                    <NonSinglesHeaderRow
                      isNewStock={isNewStock}
                      topLevelUnitType={topLevelUnitType}
                      quantityUnits={products[0].numberOfUnits.kind as BulkQuantityUnitType}
                    />
                    <NonSinglesTableRows
                      rows={products}
                      purchaseCurrency={purchaseCurrency}
                      settlementCurrency={settlementCurrency}
                      disableProductLink={disableProductLinks}
                    />
                  </React.Fragment>
                );
              })}
            </>
          )}
          <SummaryTotalRow
            products={products}
            variantTotalsDetails={variantTotalsDetails}
            purchaseCurrency={purchaseCurrency}
            settlementCurrency={settlementCurrency}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function NonSinglesHeaderRow(props: {
  isNewStock: boolean;
  topLevelUnitType: TopLevelUnitType;
  quantityUnits: BulkQuantityUnitType;
}) {
  const { isNewStock, topLevelUnitType, quantityUnits } = props;
  const unitsLabel = unitsLabelForUnitType(topLevelUnitType, quantityUnits);
  const nonSingleLabel = getFormLabelForUnionField(snTopLevelUnitType, topLevelUnitType);
  return (
    <TableRow>
      <TableHeaderCell align="left">{`Product (${nonSingleLabel})`}</TableHeaderCell>
      <TableHeaderCell>{`Unit purchase price per ${unitsLabel}`}</TableHeaderCell>
      <TableHeaderCell>Deposit</TableHeaderCell>
      <TableHeaderCell>Fees</TableHeaderCell>
      <TableHeaderCell>Repurchase quantity</TableHeaderCell>
      <TableHeaderCell>Deadline</TableHeaderCell>
      <TableHeaderCell>{isNewStock ? "Deposit subtotal" : "Net advance amount"}</TableHeaderCell>
    </TableRow>
  );
}

function NonSinglesTableRows(props: {
  rows: ProductRequest[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  disableProductLink: boolean | undefined;
}) {
  const { rows, purchaseCurrency, settlementCurrency, disableProductLink } = props;
  return (
    <>
      {rows.map((p, idx) => (
        <NewDealRequestSummaryTableRow
          key={p.product.id}
          pReq={p}
          purchaseCurrency={purchaseCurrency}
          currency={settlementCurrency}
          disableProductLink={disableProductLink}
          isLastRow={idx === rows.length - 1}
        />
      ))}
    </>
  );
}

function SummaryTotalRow(props: {
  products: ProductRequest[];
  variantTotalsDetails: VariantTotalsDetails;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}) {
  const { products, variantTotalsDetails, purchaseCurrency, settlementCurrency } = props;
  return (
    <>
      {variantTotalsDetails.kind === "newStock" ? (
        <NewStockTotalsRow
          products={products}
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
          netTotalDepositAmount={variantTotalsDetails.netTotalDepositAmount}
          grossTotalDepositAmount={variantTotalsDetails.grossTotalDepositAmount}
          totalDiscountAmount={variantTotalsDetails.totalDiscountAmount}
          totalNetAdvanceAmount={variantTotalsDetails.totalNetAdvanceAmount}
          additionalDetails={variantTotalsDetails.additionalDetails}
        />
      ) : (
        <ExistingStockTotalsRow
          purchaseCurrency={purchaseCurrency}
          settlementCurrency={settlementCurrency}
          grossTotalDepositAmount={variantTotalsDetails.grossTotalDepositAmount}
          totalNetAdvanceAmount={variantTotalsDetails.totalNetAdvanceAmount}
          additionalDetails={variantTotalsDetails.additionalDetails}
        />
      )}
    </>
  );
}

const TableHeaderCell = styled(TableCell)(({ theme, align }) => ({
  fontWeight: theme.typography.fontWeightBold,
  textAlign: align ?? "right",
  padding: theme.spacing(2),
}));

const ProductTableRow = styled(TableRow, { shouldForwardProp: propName => propName !== "emphasizeBottomBorder" })<{
  emphasizeBottomBorder?: boolean;
}>(({ theme, emphasizeBottomBorder }) => ({
  ...(emphasizeBottomBorder && {
    [`& .${tableCellClasses.root}`]: {
      borderBottom: `1px solid ${theme.palette.common.grey5}`,
    },
  }),
}));

interface NewStockTotalsRowProps {
  products: ProductRequest[];
  grossTotalDepositAmount?: MonetaryValue;
  netTotalDepositAmount?: MonetaryValue;
  totalDiscountAmount?: MonetaryValue;
  totalNetAdvanceAmount?: MonetaryValue;
  additionalDetails?: AdditionalPayableDetails;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}
const NewStockTotalsRow = (props: NewStockTotalsRowProps) => {
  const {
    products,
    totalNetAdvanceAmount,
    netTotalDepositAmount,
    grossTotalDepositAmount,
    totalDiscountAmount,
    additionalDetails,
    purchaseCurrency,
    settlementCurrency,
  } = props;
  const { showInfoDialog } = useInfoDialog();
  const isDiscounted = isPositiveNumber(totalDiscountAmount);
  // We display either the gross or the net total deposit amount as the last field, depending on whether there is a discount.
  const total =
    isDiscounted && netTotalDepositAmount !== undefined
      ? netTotalDepositAmount
      : grossTotalDepositAmount
      ? grossTotalDepositAmount
      : undefined;

  const DepositCreditFieldTitle = useCallback(
    () => (
      <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
        <Typography variant="subtitle1">{"Deposit Credit ("}</Typography>
        <Link
          typographyProps={{ variant: "subtitle1" }}
          onClick={async () =>
            await showInfoDialog({
              body: (
                <CreditDialogContent
                  products={products}
                  discountTotal={totalDiscountAmount}
                  purchaseCurrency={purchaseCurrency}
                  settlementCurrency={settlementCurrency}
                />
              ),
            })
          }>
          learn more
        </Link>
        <Typography variant="subtitle1">{")"}</Typography>
      </Stack>
    ),
    [totalDiscountAmount, products, purchaseCurrency, settlementCurrency, showInfoDialog],
  );

  return (
    <>
      {additionalDetails && (
        <>
          <TotalRowInfoField
            title="Inventory Value Amount"
            value={additionalDetails.purchaseCurrencyFeroGrossTotal}
            currency={purchaseCurrency}
            provisionalValue={{
              value: additionalDetails.settlementCurrencyFeroGrossTotal,
              currency: settlementCurrency,
            }}
            noBottom
          />
          <TotalRowInfoField
            title="Net Advance Amount"
            value={totalNetAdvanceAmount}
            currency={settlementCurrency}
            bold
          />
        </>
      )}

      {isDiscounted && (
        <>
          <TotalRowInfoField
            title={"Gross Deposit Payable"}
            value={grossTotalDepositAmount}
            currency={settlementCurrency}
            noBottom
            bold
          />
          <TotalRowInfoField
            title={<DepositCreditFieldTitle />}
            value={totalDiscountAmount}
            currency={settlementCurrency}
          />
        </>
      )}

      <TotalRowInfoField
        title={"Total Net Deposit Payable"}
        value={total}
        currency={settlementCurrency}
        noBottom
        bold
        typographyProps={{ value: { color: isNegativeNumber(total) ? "error" : undefined } }}
      />
    </>
  );
};

interface ExistingStockTotalsRowProps {
  grossTotalDepositAmount?: MonetaryValue;
  totalNetAdvanceAmount?: MonetaryValue;
  additionalDetails?: AdditionalPayableDetails;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}
const ExistingStockTotalsRow = (props: ExistingStockTotalsRowProps) => {
  const { totalNetAdvanceAmount, grossTotalDepositAmount, additionalDetails, purchaseCurrency, settlementCurrency } =
    props;

  return (
    <>
      {additionalDetails && (
        <>
          <TotalRowInfoField
            title="Gross Purchase Price Payable"
            value={additionalDetails.purchaseCurrencyFeroGrossTotal}
            currency={purchaseCurrency}
            provisionalValue={{
              value: additionalDetails.settlementCurrencyFeroGrossTotal,
              currency: settlementCurrency,
            }}
            noBottom
          />
          <TotalRowInfoField
            title="Total Deposit Payable"
            currency={settlementCurrency}
            value={grossTotalDepositAmount}
          />
        </>
      )}

      <TotalRowInfoField
        title="Net Advance Amount"
        value={totalNetAdvanceAmount}
        currency={settlementCurrency}
        noBottom
        bold
        typographyProps={{ value: { color: isNegativeNumber(totalNetAdvanceAmount) ? "error" : undefined } }}
      />
    </>
  );
};

const TotalRowInfoField = ({
  title,
  currency,
  value,
  provisionalValue,
  noBottom,
  bold,
  typographyProps,
}: {
  title: React.ReactNode;
  currency: Currency;
  value?: string;
  provisionalValue?: {
    value?: string;
    currency?: Currency;
  };
  noBottom?: boolean;
  bold?: boolean;
  typographyProps?: {
    value?: TypographyProps;
  };
}) => {
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <SummaryTableCell colSpan={4} noBottom />
      <SummaryTableCell align="right" colSpan={2} noBottom={noBottom}>
        {isValidElement(title) ? (
          title
        ) : (
          <Typography variant={bold ? "subtitle1Bold" : "subtitle1"}>{title}</Typography>
        )}
      </SummaryTableCell>
      <SummaryTableCell align="right" noBottom={noBottom}>
        {provisionalValue ? (
          <ProvisionalCurrencyRenderer
            base={{
              value: value,
              currency: currency,
            }}
            provisional={{
              value: provisionalValue?.value,
              currency: provisionalValue?.currency,
            }}
            {...typographyProps?.value}
          />
        ) : (
          <CurrencyRenderer
            variant={bold ? "subtitle1Bold" : "subtitle1"}
            value={value}
            currency={currency}
            emptyValue="-"
            {...typographyProps?.value}
          />
        )}
      </SummaryTableCell>
    </TableRow>
  );
};

interface NewDealRequestSummaryTableRowProps {
  pReq: ProductRequest;
  purchaseCurrency: Currency;
  currency: Currency;
  disableProductLink?: boolean;
  onClick?: () => void;
}

const NewDealRequestSummaryTableRow = ({
  pReq,
  purchaseCurrency,
  currency,
  disableProductLink,
  isLastRow,
  onClick,
}: NewDealRequestSummaryTableRowProps & { isLastRow: boolean }) => {
  const {
    purchasePrice,
    depositPrice,
    provisionalPurchasePrice,
    monthlyFee,
    throughputFeePercentage,
    preCSDUnits,
    postCSDUnits,
    csDate,
    finalDate,
    numberOfUnits: numberOfPaidUnits,
    numberOfFreeUnits,
    product,
    grossPayableAmount,
    finalPurchasePrice,
  } = pReq;
  const totalNumberOfUnits = add(numberOfPaidUnits, numberOfFreeUnits);
  const productId = disableProductLink ? undefined : product.id;
  const summaryProps: ProductSummaryProps = {
    ...product.value,
    productId,
    vesselCapacity: getVesselCapacity(product.value.vesselSize, totalNumberOfUnits),
    // It's important that onClick is set to undefined if there is nothing to do
    // to make sure the product code in the summary is not clickable.
    onClick,
  };
  const monthlyFeeText =
    monthlyFee && monthlyFee.value
      ? monthlyFee.kind === "fixed"
        ? `${monthlyFee.value}% fixed`
        : `${monthlyFee.value.base}% variable`
      : "-";

  const finalOrProvisionalPurchasePrice = useMemo(
    () => finalPurchasePrice ?? provisionalPurchasePrice,
    [finalPurchasePrice, provisionalPurchasePrice],
  );

  const isSingles = isSinglesUnitType(product.value.unitType) || isCase(product.value.unitType);
  return (
    <ProductTableRow emphasizeBottomBorder={isLastRow}>
      <TableCell>
        <ProductSummary {...summaryProps} />
      </TableCell>
      <SummaryTableCell>
        <ProvisionalCurrencyRenderer
          base={{
            value: purchasePrice,
            currency: purchaseCurrency,
          }}
          provisional={{
            value: finalOrProvisionalPurchasePrice,
            currency: currency,
          }}
          maximumFractionDigits={4}
        />
      </SummaryTableCell>
      <SummaryTableCell>
        <CurrencyRenderer maximumFractionDigits={4} value={depositPrice} currency={currency} />
      </SummaryTableCell>

      {monthlyFee && throughputFeePercentage ? (
        <SummaryTableCell>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Monthly fees
              </Typography>
              <Typography variant="subtitle1">{monthlyFeeText}</Typography>
            </Stack>
            <Stack>
              <Typography variant="caption" color="common.grey6">
                Throughput fees
              </Typography>
              <Typography variant="subtitle1">{`${throughputFeePercentage}%`}</Typography>
            </Stack>
          </Stack>
        </SummaryTableCell>
      ) : (
        <TableCell align="right">-</TableCell>
      )}

      {preCSDUnits !== undefined && postCSDUnits !== undefined ? (
        <SplitCell
          top={isSingles ? `${preCSDUnits.value}` : undefined}
          bottom={isSingles ? `${postCSDUnits.value}` : "1"}
          sx={{ pr: 0 }}
        />
      ) : (
        <TableCell align="right">-</TableCell>
      )}

      {csDate && finalDate ? (
        <SplitCell
          top={isSingles ? formatLocalDate(csDate) : undefined}
          bottom={formatLocalDate(finalDate)}
          sx={{ pl: 0, pr: 0 }}
        />
      ) : (
        <TableCell align="right">-</TableCell>
      )}

      <SummaryTableCell>
        <CurrencyRenderer value={grossPayableAmount} currency={currency} />
      </SummaryTableCell>
    </ProductTableRow>
  );
};

const SummaryTableCell = styled(TableCell, { shouldForwardProp: propName => propName !== "noBottom" })<{
  noBottom?: boolean;
}>(({ noBottom }) => ({
  textAlign: "right",
  ...(noBottom && { borderBottom: "unset" }),
}));

const SplitCell = ({ top, bottom, ...tableCellProps }: { top?: string; bottom: string } & TableCellProps) => {
  return (
    <SplitTableCell {...tableCellProps}>
      <Stack divider={<Divider flexItem />} spacing={2}>
        {top && (
          <Typography variant="subtitle1" pr={2}>
            {top}
          </Typography>
        )}
        <Typography variant="subtitle1" pr={2}>
          {bottom}
        </Typography>
      </Stack>
    </SplitTableCell>
  );
};

const SplitTableCell = styled(TableCell)(() => ({
  textAlign: "right",
}));

interface CreditDialogContentProps {
  products: ProductRequest[];
  discountTotal?: MonetaryValue;
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
}
const CreditDialogContent = ({
  products,
  discountTotal,
  purchaseCurrency,
  settlementCurrency,
}: CreditDialogContentProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">How is this credit calculated?</Typography>
      <Typography>
        Your request contains free units for the below products. However, Ferovinum still has to commercially value and
        receive a deposit for this stock in order to finance it. This free stock value will be discounted against your
        gross deposit payable to reduce your overall/net deposit payable.
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <CreditCell align="left">Product</CreditCell>
            <CreditCell>Free units</CreditCell>
            <CreditCell>Unit price</CreditCell>
            <CreditCell>Commercial value</CreditCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products
            .filter(pReq => pReq.numberOfFreeUnits.value > 0)
            .map(pReq => {
              const product = pReq.product;
              const totalNumberOfUnits = add(pReq.numberOfUnits, pReq.numberOfFreeUnits);
              const summaryProps: ProductSummaryProps = {
                ...product.value,
                vesselCapacity: getVesselCapacity(pReq.product.value.vesselSize, totalNumberOfUnits),
              };
              return (
                <TableRow key={product.id}>
                  <CreditCell align="left">
                    <ProductSummary {...summaryProps} />
                  </CreditCell>
                  <CreditCell>{pReq.numberOfFreeUnits.value}</CreditCell>
                  <CreditCell>
                    <ProvisionalCurrencyRenderer
                      base={{
                        value: pReq.purchasePrice,
                        currency: purchaseCurrency,
                      }}
                      provisional={{
                        value: pReq.provisionalPurchasePrice,
                        currency: settlementCurrency,
                      }}
                      maximumFractionDigits={4}
                    />
                  </CreditCell>
                  <CreditCell>
                    <CurrencyRenderer
                      maximumFractionDigits={4}
                      value={pReq.discountAmount}
                      currency={settlementCurrency}
                      emptyValue="-"
                    />
                  </CreditCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TotalCreditCell colSpan={3}>
              <Typography variant="subtitle1Bold">Total commercial value</Typography>
            </TotalCreditCell>
            <TotalCreditCell>
              <CurrencyRenderer
                value={discountTotal}
                currency={settlementCurrency}
                emptyValue="-"
                variant="subtitle1Bold"
              />
            </TotalCreditCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

const TotalCreditCell = styled(TableCell)(({ theme }) => ({
  textAlign: "right",
  backgroundColor: theme.palette.common.grey2,
}));

const CreditCell = styled(TableCell)(({ theme, align }) => ({
  textAlign: align ?? "right",
  backgroundColor: theme.palette.common.grey1,
}));

interface NewDealRequestActionsProps {
  currentState: NewDealRequestState;
  dealTerms: DealTermsView;
  acknowledgeDealTerms: (accept: boolean) => Promise<void>;
  markDepositAsPaid: () => Promise<void>;
}
const NewDealRequestActions = ({
  currentState,
  dealTerms,
  acknowledgeDealTerms,
  markDepositAsPaid,
}: NewDealRequestActionsProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const isActionable = currentState === "depositInvoiced" || currentState === "feroAccepted";

  return (
    <>
      {isActionable && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            p: 2,
            backgroundColor: "common.grey3",
          }}>
          {currentState === "feroAccepted" && (
            <Stack spacing={3} direction={"row"} justifyContent={"space-between"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox value={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} disabled={loading} />
                  }
                  label={
                    <Stack direction="row" spacing={1}>
                      <Typography>I agree to the </Typography>
                      <TermsLink dealTermsView={dealTerms} />
                    </Stack>
                  }
                />
              </FormGroup>
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                onClick={async () => {
                  await showConfirmationDialog({
                    title: "Are you sure you want to reject these terms?",
                    confirmAction: {
                      color: "error",
                      title: "Reject",
                      onClick: async () => {
                        await acknowledgeDealTerms(false);
                      },
                    },
                  });
                }}>
                Reject
              </Button>
              <LoadingActionButton
                variant="contained"
                color="success"
                disabled={!acceptTerms || loading}
                onClick={async () => {
                  setLoading(true);
                  await acknowledgeDealTerms(true);
                  setLoading(false);
                }}>
                Accept
              </LoadingActionButton>
            </Stack>
          )}
          {currentState === "depositInvoiced" && (
            <LoadingActionButton
              color="secondary"
              variant="contained"
              onClick={async () => {
                setLoading(true);
                await markDepositAsPaid();
                setLoading(false);
              }}>
              {"I've paid the deposit"}
            </LoadingActionButton>
          )}
        </Stack>
      )}
    </>
  );
};
