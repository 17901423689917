import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useState, useCallback, useRef, useEffect } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

export interface DropdownMenuItem<T> {
  label: string;
  value: T;
}
export interface DropdownProps<T> {
  inputLabel: string;
  menuItems: DropdownMenuItem<T>[];
  onChange: (value?: T) => void;
  defaultValue?: DropdownMenuItem<T>;
  resettable?: boolean;
  fullWidth?: boolean;
}

export const Dropdown = function <T>({
  inputLabel,
  menuItems,
  onChange,
  defaultValue,
  resettable,
  fullWidth,
}: DropdownProps<T>) {
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>(defaultValue?.label ?? "");
  const [bgColor, setBgColor] = useState<string>("common.grey4");
  const [textColor, setTextColor] = useState<string>("primary.main");

  const inputComponent = useRef<HTMLInputElement>();

  const handleChange = useCallback(
    ({ target: { value: inputValue } }: SelectChangeEvent) => {
      const value: T | undefined = menuItems.find(i => i.label === inputValue)?.value;
      onChange(value);
      setSelectedItemLabel(inputValue);
    },
    [menuItems, onChange],
  );

  useEffect(() => {
    if (selectedItemLabel.length > 0) {
      setBgColor("primary.main");
      setTextColor("common.white");
    } else {
      setBgColor("common.grey4");
      setTextColor("primary.main");
    }
  }, [selectedItemLabel]);

  return (
    <FormControl
      variant="filled"
      size="small"
      focused={false}
      placeholder={inputLabel}
      fullWidth={fullWidth}
      sx={{
        "display": fullWidth ? "inline-flex" : "block",
        "& .MuiSvgIcon-root": {
          ml: 0,
          color: "common.white",
        },
      }}>
      {selectedItemLabel.length < 1 && (
        <InputLabel
          shrink={false}
          sx={{
            fontWeight: "bold",
            top: "-25%",
            fontSize: theme => theme.typography.body2.fontSize,
          }}>
          {inputLabel}
        </InputLabel>
      )}
      <Select
        ref={inputComponent}
        value={defaultValue?.label}
        sx={{
          "bgcolor": bgColor,
          "color": textColor,
          "minWidth": "200px",
          "borderRadius": "8px",
          "height": "32px",
          "fontWeight": theme => theme.typography.fontWeightBold ?? "bold",
          "fontSize": theme => theme.typography.body2.fontSize ?? "14px",
          ":hover": {
            bgcolor: bgColor,
          },
        }}
        SelectDisplayProps={{
          style: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: "4px",
          },
        }}
        size="small"
        variant="filled"
        onChange={handleChange}
        label={inputLabel}
        disableUnderline
        startAdornment={
          selectedItemLabel.length > 0 && (
            <CheckOutlinedIcon fontSize="small" sx={{ color: theme => theme.palette.common.white, ml: 1 }} />
          )
        }>
        {resettable && selectedItemLabel.length > 0 && (
          <MenuItem value="">
            <em>Clear selection</em>
          </MenuItem>
        )}
        {menuItems.map(item => (
          <MenuItem key={item.label} value={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
