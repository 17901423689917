import { Stack, Typography } from "@mui/material";
import { makeActionLinkConstants } from "adl-gen/ferovinum/app/api";
import { GuardedFeature } from "adl-gen/ferovinum/app/db";
import { UiConfig } from "adl-gen/ferovinum/app/uiconfig";
import { ApiServices } from "adl-service/api-services";
import { UiConfigContext } from "adl-service/hooks/use-ui-config";
import { LOGIN_BANNER_IMAGE } from "components/assets/images";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PortalPage } from "../ui/layouts/portal-page-layout/portal-page";
import { PublicPageLayout } from "../ui/layouts/public-page-layout/public-page-layout";
import { CarrierCollectionOrdersPage } from "../ui/page/carrier/purchase-orders/carrier-collection-orders-page";
import { HelpUserManualPage } from "../ui/page/help/help-user-manual-page";
import { LoginPage } from "../ui/page/login-page/login-page";
import { LogoutPage } from "../ui/page/logout-page/logout-page";
import { OrganisationCompletedDealRenewalsPage } from "../ui/page/organisation/expiring-stock/organisation-completed-deal-renewals/organisation-completed-deal-renewals-page";
import { OrganisationDealExtensionRequestDetailsPage } from "../ui/page/organisation/expiring-stock/organisation-deal-extension-request-details/organisation-deal-extension-request-details-page";
import { OrganisationExpiringStockPage } from "../ui/page/organisation/expiring-stock/organisation-expiring-stock/organisation-expiring-stock-page";
import { OrganisationCreateNewDealRequestPage } from "../ui/page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { OrganisationNewDealRequestConfirmationPage } from "../ui/page/organisation/new-deal-requests/organisation-new-deal-request-confirmation/organisation-new-deal-request-confirmation-page";
import { OrganisationNewDealRequestDeliveryOptionsPage } from "../ui/page/organisation/new-deal-requests/organisation-new-deal-request-delivery-options/organisation-new-deal-request-delivery-options-page";
import { OrganisationNewDealRequestDetailsPage } from "../ui/page/organisation/new-deal-requests/organisation-new-deal-request-details/organisation-new-deal-request-details-page";
import { OrganisationNewDealRequestSetupPage } from "../ui/page/organisation/new-deal-requests/organisation-new-deal-request-setup/organisation-new-deal-request-setup-page";
import { OrganisationNewDealRequestsPage } from "../ui/page/organisation/new-deal-requests/organisation-new-deal-requests/organisation-new-deal-requests-page";
import { OrganisationDashboardPage } from "../ui/page/organisation/organisation-dashboard/organisation-dashboard-page";
import { OrganisationInventoryPage } from "../ui/page/organisation/organisation-inventory/organisation-inventory-page";
import { OrganisationProductSelectionPage as OrgProductionSelectionPage } from "../ui/page/organisation/production-orders/organisation-product-selection/organisation-product-selection-page";
import { OrganisationProductionOrderDetailsPage } from "../ui/page/organisation/production-orders/organisation-production-order-details/organisation-production-order-details-page";
import { OrganisationProductionOrderSetupPage } from "../ui/page/organisation/production-orders/organisation-production-order-setup/organisation-production-order-setup-page";
import { OrganisationProductionOrdersPage as OrgProductionOrdersPage } from "../ui/page/organisation/production-orders/organisation-production-orders/organisation-production-orders-page";
import { OrganisationProductionOrderConfirmationPage } from "../ui/page/organisation/production-orders/organistion-production-order-confirmation/organisation-production-order-confirmation-page";
import { OrganisationPurchaseRequestConfirmationPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-confirmation/organisation-purchase-request-confirmation-page";
import { OrganisationPurchaseRequestDeliveryOptionsPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-delivery-options/organisation-purchase-request-delivery-options-page";
import { OrganisationPurchaseRequestDetailsPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-details/organisation-purchase-request-details-page";
import { OrganisationPurchaseRequestProductionPlanPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-production-plan/organisation-purchase-request-production-plan-page";
import { OrganisationPurchaseRequestSetupPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-setup/organisation-purchase-request-setup-page";
import { OrganisationPurchaseRequestsByPurchaserPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-requests-by-purchaser/organisation-purchase-requests-by-purchaser-page";
import { OrganisationPurchaseRequestsPage } from "../ui/page/organisation/purchase-requests/organisation-purchase-requests/organisation-purchase-requests-page";
import { OrganisationRepurchaseConfirmationPage } from "../ui/page/organisation/repurchase/organisation-repurchase-confirmation/organisation-repurchase-confirmation-page";
import { OrganisationRepurchaseDeliveryPage } from "../ui/page/organisation/repurchase/organisation-repurchase-delivery/organisation-repurchase-delivery-page";
import { OrganisationRepurchaseDetailsPage } from "../ui/page/organisation/repurchase/organisation-repurchase-details/organisation-repurchase-details-page";
import { OrganisationRepurchaseStockPage } from "../ui/page/organisation/repurchase/organisation-repurchase-stock/organisation-repurchase-stock-page";
import { OrganisationSaleOrdersPage } from "../ui/page/organisation/repurchase/organisation-sale-orders/organisation-sale-orders-page";
import { RevaluationRequestOverviewPage } from "../ui/page/organisation/revaluation/revaluation-request-dashboard-page";
import { OrgRevaluationRequestDetailsPage } from "../ui/page/organisation/revaluation/revaluation-request-details-page";
import {
  ActionLinkPurchaserPurchaseRequestDetailsPage,
  PurchaserPurchaseRequestDetailsPage,
} from "../ui/page/purchaser/purchaser-purchase-request-details/purchaser-purchase-request-details-page";
import { PurchaserPurchaseRequestsPage } from "../ui/page/purchaser/purchaser-purchase-requests/purchaser-purchase-requests-page";
import { QrProductInfoPage } from "../ui/page/qr/product-info-page";
import { StockMovementCreatePage } from "../ui/page/stock-movement/stock-movement-create/stock-movement-create-page";
import { StockMovementDashboardPage } from "../ui/page/stock-movement/stock-movement-dashboard/stock-movement-dashboard-page";
import { StockMovementDetailsPage } from "../ui/page/stock-movement/stock-movement-details/stock-movement-details-page";
import { OutgoingOrdersDetailPage } from "../ui/page/storage-loc/outgoing-orders/outgoing-order-detail-page/outgoing-orders-detail-page";
import { OutgoingOrdersPage } from "../ui/page/storage-loc/outgoing-orders/outgoing-orders-page/outgoing-orders-page";
import { StorageLocProductionOrderDetailsPage } from "../ui/page/storage-loc/production-orders/storage-loc-production-order-details-page/storage-loc-production-order-details-page";
import { StorageLocProductionOrdersPage as LocProductionOrdersPage } from "../ui/page/storage-loc/production-orders/storage-loc-production-orders/storage-loc-production-orders-page";
import { StorageLocStockAdjustmentConfirmationPage } from "../ui/page/storage-loc/stock-adjustments/storage-loc-stock-adjustment-confirmation/storage-loc-stock-adjustment-confirmation-page";
import { StorageLocStockAdjustmentDetailsPage } from "../ui/page/storage-loc/stock-adjustments/storage-loc-stock-adjustment-details/storage-loc-stock-adjustment-details-page";
import { StorageLocStockAdjustmentSettingsPage } from "../ui/page/storage-loc/stock-adjustments/storage-loc-stock-adjustment-settings/storage-loc-stock-adjustment-settings-page";
import { StorageLocStockAdjustmentSetupPage } from "../ui/page/storage-loc/stock-adjustments/storage-loc-stock-adjustment-setup/storage-loc-stock-adjustment-setup-page";
import { StorageLocIncomingShipmentsPage } from "../ui/page/storage-loc/storage-loc-incoming-shipments/storage-loc-incoming-shipments-page";
import { StorageLocProductBalancesPage } from "../ui/page/storage-loc/storage-loc-product-balances/storage-loc-product-balances-page";
import { ActionLinkSupplierNewDealRequestsPage } from "../ui/page/supplier/new-deal-requests/action-link-supplier-new-deal-requests-page";
import { SupplierNewDealRequestsPage } from "../ui/page/supplier/new-deal-requests/supplier-new-deal-requests-page";
import { AppRoutes } from "./app-routes";
import { LoginState, useLoginState } from "./identity-state";
import { IndexRedirect } from "./index-redirect";
import { LoginRedirect } from "./login-redirect";
import { ResetPasswordPage } from "../ui/page/reset-password/reset-password-page";
import { ForgotPasswordPage } from "../ui/page/forgot-password/forgot-password-page";
import { AlreadyLoggedInPage } from "../ui/page/auth";
import { NominatedPurchaserOrderAuthorisationPage } from "../ui/page/purchaser/nominated-purchaser-order-authorisation/nominated-purchaser-order-authorisation-page";

export interface LoggedInContextProps {
  loginState?: LoginState;
}

export const LoggedInContext = React.createContext<LoggedInContextProps>({});

interface AppProps {
  /** UI configuration */
  uiconfig: UiConfig;
  createApiServices(token?: string): ApiServices;
}

const { SupplierNewDealRequestsRoute, PurchaserActionRoute, NomPurchaserOrderAuthRoute } = makeActionLinkConstants({});

export const App = (props: AppProps) => {
  const [features, setFeatures] = useState<GuardedFeature[]>([]);
  const loginState = useLoginState({
    createApiServices: props.createApiServices,
    setFeatures: features => setFeatures(features),
  });

  const PortalPageLayout = useCallback(
    ({ children, ...props }) => {
      return (
        <PortalPage loginState={loginState} {...props}>
          {children}
        </PortalPage>
      );
    },
    [loginState],
  );

  const showNominatedPurchaser = features.includes("nominatedPurchaser");
  const showProductionOrder = features.includes("productionOrder");
  const showStockMovement = features.includes("stockMovement");
  const showStockRevaluation = features.includes("stockRevaluation");

  const uiConfig = useMemo(() => {
    return {
      ...props.uiconfig,
      features,
    };
  }, [features, props.uiconfig]);

  const loginStateMemo = useMemo(() => ({ loginState }), [loginState]);

  return (
    <Switch>
      {/* Public routes, we don't care if the user is logged in*/}
      <Route path={NomPurchaserOrderAuthRoute}>
        <PublicPageLayout imageURL={LOGIN_BANNER_IMAGE}>
          <NominatedPurchaserOrderAuthorisationPage />
        </PublicPageLayout>
      </Route>

      <UiConfigContext.Provider value={uiConfig}>
        {loginState.userProfileIsLoading ? (
          <div>Loading..</div>
        ) : (
          <LoggedInContext.Provider value={{ loginState }}>
            {loginStateMemo.loginState.user === undefined ? (
              <Switch>
                <Route path={SupplierNewDealRequestsRoute}>
                  <ActionLinkSupplierNewDealRequestsPage />
                </Route>
                <Route path={PurchaserActionRoute}>
                  <ActionLinkPurchaserPurchaseRequestDetailsPage />
                </Route>
                <PublicPageLayout imageURL={LOGIN_BANNER_IMAGE} showMobileLogo={false}>
                  <Switch>
                    <Route path={AppRoutes.Login}>
                      <LoginPage {...loginState} />
                    </Route>
                    <Route path={AppRoutes.ForgotPassword}>
                      <ForgotPasswordPage {...loginState} />
                    </Route>
                    <Route exact path={AppRoutes.ResetPassword}>
                      <ResetPasswordPage
                        onResetPassword={loginState.onResetPassword}
                        onLogout={loginState.onLogout}
                        resetPasswordError={loginState.resetPasswordError}
                      />
                    </Route>
                    <Route exact path={AppRoutes.ActivateAccount}>
                      <ResetPasswordPage
                        onResetPassword={loginState.onResetPassword}
                        onLogout={loginState.onLogout}
                        resetPasswordError={loginState.resetPasswordError}
                      />
                    </Route>
                    <Route component={LoginRedirect} />
                  </Switch>
                </PublicPageLayout>
              </Switch>
            ) : (
              <PortalPageLayout>
                <Switch>
                  <Route exact path={AppRoutes.ActivateAccount}>
                    <AlreadyLoggedInPage />
                  </Route>
                  <Route exact path={AppRoutes.ResetPassword}>
                    <AlreadyLoggedInPage />
                  </Route>
                  <Route exact path={`${AppRoutes.LocNewDealRequests}/:filter`}>
                    <StorageLocIncomingShipmentsPage />
                  </Route>
                  <Route exact path={AppRoutes.CarrierRequests}>
                    <Redirect to={AppRoutes.CarrierRequests + "/inProgress"} />
                  </Route>
                  <Route exact path={AppRoutes.CarrierRequests + "/:filter"}>
                    <CarrierCollectionOrdersPage />
                  </Route>
                  <Route path={AppRoutes.SaleOrder + "/:saleOrderId"}>
                    <OrganisationRepurchaseDetailsPage />
                  </Route>

                  {/* Org create production order flow */}
                  {showProductionOrder && (
                    <Route path={AppRoutes.OrgProductionOrderProductSelection}>
                      <OrgProductionSelectionPage />
                    </Route>
                  )}
                  {showProductionOrder && (
                    <Route path={AppRoutes.OrgProductionOrderSetup}>
                      <OrganisationProductionOrderSetupPage />
                    </Route>
                  )}
                  {showProductionOrder && (
                    <Route path={AppRoutes.OrgProductionOrderConfirmation}>
                      <OrganisationProductionOrderConfirmationPage />
                    </Route>
                  )}
                  {showProductionOrder && (
                    <Route path={AppRoutes.OrgProductionOrderDetails + "/:productionOrderId"}>
                      <OrganisationProductionOrderDetailsPage />
                    </Route>
                  )}
                  {showProductionOrder && (
                    <Route path={AppRoutes.OrgProductionOrders}>
                      <OrgProductionOrdersPage />
                    </Route>
                  )}

                  <Route exact path={AppRoutes.RepurchaseDelivery}>
                    <OrganisationRepurchaseDeliveryPage />
                  </Route>
                  <Route exact path={AppRoutes.RepurchaseConfirmation}>
                    <OrganisationRepurchaseConfirmationPage />
                  </Route>
                  <Route path={AppRoutes.RepurchaseSaleOrders}>
                    <OrganisationSaleOrdersPage />
                  </Route>
                  <Route path={AppRoutes.OrganisationNewDealRequests}>
                    <OrganisationNewDealRequestsPage />
                  </Route>
                  <Route path={AppRoutes.OrganisationNewDealRequestDetails + "/:purchaseOrderId"}>
                    <OrganisationNewDealRequestDetailsPage />
                  </Route>

                  {/* Org create or edit new deal request flow */}
                  <Route path={AppRoutes.CreateNewDealRequest + "/:existingNdrId"}>
                    <OrganisationCreateNewDealRequestPage />
                  </Route>
                  <Route path={AppRoutes.CreateNewDealRequest}>
                    <OrganisationCreateNewDealRequestPage />
                  </Route>
                  <Route path={AppRoutes.NewDealRequestSetup}>
                    <OrganisationNewDealRequestSetupPage />
                  </Route>
                  <Route path={AppRoutes.NewDealRequestDeliveryOptions}>
                    <OrganisationNewDealRequestDeliveryOptionsPage />
                  </Route>
                  <Route path={AppRoutes.NewDealRequestConfirmation}>
                    <OrganisationNewDealRequestConfirmationPage />
                  </Route>

                  <Route exact path={AppRoutes.SupplierNewDealRequests}>
                    <Redirect to={AppRoutes.SupplierNewDealRequests + "/inProgress"} />
                  </Route>
                  <Route exact path={SupplierNewDealRequestsRoute}>
                    <Redirect to={AppRoutes.SupplierNewDealRequests + "/inProgress"} />
                  </Route>
                  <Route exact path={AppRoutes.SupplierNewDealRequests + "/:filter"}>
                    <SupplierNewDealRequestsPage />
                  </Route>
                  <Route path={[AppRoutes.LocProductionOrders, AppRoutes.LocExistingProductionOrders]}>
                    <LocProductionOrdersPage />
                  </Route>
                  <Route path={AppRoutes.LocProductionOrderDetails + "/:productionOrderId"}>
                    <StorageLocProductionOrderDetailsPage />
                  </Route>
                  <Route path={AppRoutes.Repurchase}>
                    <OrganisationRepurchaseStockPage />
                  </Route>
                  <Route path={AppRoutes.Dashboard}>
                    <OrganisationDashboardPage />
                  </Route>
                  <Route path={AppRoutes.ProductBalances}>
                    <StorageLocProductBalancesPage />
                  </Route>
                  <Route exact path={AppRoutes.Inventory}>
                    <OrganisationInventoryPage />
                  </Route>
                  <Route path={`${AppRoutes.Qr}/:hash`}>
                    <QrProductInfoPage />
                  </Route>
                  {showNominatedPurchaser && (
                    <Route exact path={AppRoutes.OrganisationPurchaseRequests}>
                      <OrganisationPurchaseRequestsPage />
                    </Route>
                  )}
                  {/* Org create purchase request flow */}
                  {showNominatedPurchaser && (
                    <Route exact path={AppRoutes.OrganisationCreatePurchaseRequest}>
                      <OrganisationPurchaseRequestSetupPage />
                    </Route>
                  )}
                  {showNominatedPurchaser && showProductionOrder && (
                    <Route path={AppRoutes.OrganisationPurchaseRequestProductionPlan}>
                      <OrganisationPurchaseRequestProductionPlanPage />
                    </Route>
                  )}
                  {showNominatedPurchaser && (
                    <Route exact path={AppRoutes.OrganisationPurchaseRequestDeliveryOptions}>
                      <OrganisationPurchaseRequestDeliveryOptionsPage />
                    </Route>
                  )}
                  {showNominatedPurchaser && (
                    <Route exact path={AppRoutes.OrganisationPurchaseRequestConfirmation}>
                      <OrganisationPurchaseRequestConfirmationPage />
                    </Route>
                  )}
                  {showNominatedPurchaser && (
                    <Route path={AppRoutes.OrganisationPurchaseRequestDetails + "/:purchaseRequestId"}>
                      <OrganisationPurchaseRequestDetailsPage />
                    </Route>
                  )}
                  {showNominatedPurchaser && (
                    <Route exact path={AppRoutes.OrganisationPurchaseRequestByPurchaser}>
                      <OrganisationPurchaseRequestsByPurchaserPage />
                    </Route>
                  )}
                  <Route exact path={AppRoutes.StorageLocationOutgoingOrders}>
                    <Redirect to={`${AppRoutes.StorageLocationOutgoingOrders}/outstanding`} />
                  </Route>
                  <Route path={AppRoutes.StorageLocationOutgoingOrderDetails + "/:purchaseRequestId"}>
                    <OutgoingOrdersDetailPage />
                  </Route>
                  <Route path={AppRoutes.StorageLocationOutgoingOrders + "/:filter"}>
                    <OutgoingOrdersPage />
                  </Route>

                  {/* Purchaser pages */}
                  <Route exact path={AppRoutes.PurchaserPurchaseRequests}>
                    <PurchaserPurchaseRequestsPage />
                  </Route>
                  <Route
                    exact
                    path={PurchaserActionRoute + "/:purchaseRequestId"}
                    render={props => (
                      <Redirect
                        to={`${AppRoutes.PurchaserPurchaseRequestDetails}/${props.match.params.purchaseRequestId}`}
                      />
                    )}
                  />
                  <Route path={AppRoutes.PurchaserPurchaseRequestDetails + "/:purchaseRequestId"}>
                    <PurchaserPurchaseRequestDetailsPage />
                  </Route>

                  {/* Stock adjustment pages */}
                  <Route path={AppRoutes.StorageLocStockAdjustmentSetup + "/:variant"}>
                    <StorageLocStockAdjustmentSetupPage />
                  </Route>
                  <Route exact path={AppRoutes.StorageLocStockAdjustmentSettings}>
                    <StorageLocStockAdjustmentSettingsPage />
                  </Route>
                  <Route exact path={AppRoutes.StorageLocStockAdjustmentConfirmation}>
                    <StorageLocStockAdjustmentConfirmationPage />
                  </Route>
                  <Route exact path={AppRoutes.StorageLocStockAdjustmentDetails + "/:variant/:stockAdjustmentId"}>
                    <StorageLocStockAdjustmentDetailsPage />
                  </Route>

                  {/* Expiring stock pages */}
                  <Route exact path={AppRoutes.OrganisationExpiringStock}>
                    <OrganisationExpiringStockPage />
                  </Route>
                  <Route exact path={AppRoutes.OrganisationCompletedDealExtensionRequests}>
                    <OrganisationCompletedDealRenewalsPage />
                  </Route>
                  <Route path={AppRoutes.OrganisationDealExtensionRequestDetails + "/:dealExtensionRequestId"}>
                    <OrganisationDealExtensionRequestDetailsPage />
                  </Route>

                  {/* Stock Movement pages */}
                  {showStockMovement && (
                    <Route exact path={AppRoutes.StockMovement}>
                      <StockMovementDashboardPage />
                    </Route>
                  )}
                  {showStockMovement && (
                    <Route exact path={AppRoutes.CreateStockMovement}>
                      <StockMovementCreatePage />
                    </Route>
                  )}
                  {showStockMovement && (
                    <Route exact path={AppRoutes.StockMovementDetails + "/:stockMovementId"}>
                      <StockMovementDetailsPage />
                    </Route>
                  )}
                  {/* Stock Revaluation pages */}
                  {showStockRevaluation && (
                    <Route exact path={AppRoutes.StockRevaluation}>
                      <RevaluationRequestOverviewPage />
                    </Route>
                  )}
                  {showStockRevaluation && (
                    <Route exact path={AppRoutes.StockRevaluationDetails + "/:revaluationRequestId"}>
                      <OrgRevaluationRequestDetailsPage />
                    </Route>
                  )}
                  <Route exact path={AppRoutes.Index}>
                    <IndexRedirect />
                  </Route>
                  <Route exact path={[AppRoutes.Logout, AppRoutes.Login]}>
                    <LogoutPage loginState={loginState} />
                  </Route>

                  <Route exact path={[AppRoutes.HelpUserManual]}>
                    <HelpUserManualPage />
                  </Route>

                  <Route>
                    <Stack>
                      <Typography variant="h2">Page Not Found</Typography>
                    </Stack>
                  </Route>
                </Switch>
              </PortalPageLayout>
            )}
          </LoggedInContext.Provider>
        )}
      </UiConfigContext.Provider>
    </Switch>
  );
};
