import { OrganisationDealExtensionRequestAction } from "adl-gen/ferovinum/app/api";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { isLoaded } from "utils/utility-types";
import { useAppService } from "../../../../../hooks/use-app-service";
import { OrganisationDealExtensionRequestDetailsPageView } from "./organisation-deal-extension-request-details-page-view";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";

export const OrganisationDealExtensionRequestDetailsPage = () => {
  const service = useAppService();
  const { dealExtensionRequestId } = useParams<{
    dealExtensionRequestId: string;
  }>();
  const [showAlert] = useAlert();

  const loadContractNote = useCallback(
    async () =>
      await service.getContractNoteView({
        kind: "sourceRequest",
        value: { kind: "dealExtensionRequestId", value: dealExtensionRequestId },
      }),
    [dealExtensionRequestId, service],
  );

  const [loadingContractNote] = useLoadingDataState(loadContractNote);

  const loadDealExtensionRequestDetails = useCallback(
    async () => await service.getOrganisationDealExtensionRequestDetails({ dealExtensionRequestId }),
    [dealExtensionRequestId, service],
  );

  const [loadingDealExtensionRequestDetails, refreshDealExtensionRequestDetails] = useLoadingDataState(
    loadDealExtensionRequestDetails,
    true,
  );

  const onClickAction = useCallback(
    async (action: OrganisationDealExtensionRequestAction) => {
      const resp = await service.progressDealExtensionRequestState({ dealExtensionRequestId, action });
      if (resp !== "success") {
        void showAlert({
          title: "Error",
          body: "We are unable to perform this action for this deal. Please try again.",
          severity: "error",
        });
      }
      await refreshDealExtensionRequestDetails();
    },
    [dealExtensionRequestId, refreshDealExtensionRequestDetails, service, showAlert],
  );

  if (isLoaded(loadingDealExtensionRequestDetails) && isLoaded(loadingContractNote)) {
    let allAvailablePdfs: AvailablePdf[] = [];
    if (loadingDealExtensionRequestDetails?.value?.availablePdfs) {
      allAvailablePdfs = allAvailablePdfs.concat(loadingDealExtensionRequestDetails?.value?.availablePdfs);
    }

    if (loadingContractNote?.value?.availablePdfs) {
      allAvailablePdfs = allAvailablePdfs.concat(loadingContractNote.value.availablePdfs);
    }

    return (
      <Loader fullScreen loadingStates={[loadingDealExtensionRequestDetails, loadingContractNote]}>
        <OrganisationDealExtensionRequestDetailsPageView
          dealExtensionRequestId={dealExtensionRequestId}
          {...loadingDealExtensionRequestDetails.value}
          onClickAction={onClickAction}
          availablePdfs={allAvailablePdfs}
        />
      </Loader>
    );
  } else {
    return <Loader fullScreen loadingStates={[loadingDealExtensionRequestDetails, loadingContractNote]}></Loader>;
  }
};
