import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  Alert,
  Box,
  Collapse,
  Divider,
  Grow,
  IconButton,
  Stack,
  Tab,
  TableBodyProps,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/system";
import { Paginated, makePaginated } from "adl-gen/common";
import { OrgStockAtStorageLoc, OrganisationProductInventoryView } from "adl-gen/ferovinum/app/api";
import { TopLevelUnitType, valuesTopLevelUnitType } from "adl-gen/ferovinum/app/db";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { SearchInput } from "components/widgets/inputs/search-input/search-input";
import { Link } from "components/widgets/link/link";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getTopLevelForUnitType } from "utils/adl-utils";
import { unitTypeToString } from "utils/conversion-utils";
import { formatVesselSize, getVesselCapacity, vesselSizeMetricForUnitType } from "utils/model-utils";
import { useInfoDrawer } from "../../../layouts/info-drawer/info-drawer";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { ProductMovement } from "../../../widgets/product-movement/product-movement";
import { StockTypeToggle } from "../../../widgets/stock-type-toggle/stock-type-toggle";

export type AvailabilitySection = "available" | "depleted";
export type PaginatedProductMapping = Map<TopLevelUnitType, Paginated<OrganisationProductInventoryView>>;

export interface OrganisationInventoryPageViewProps {
  availableProducts: PaginatedProductMapping;
  depletedProducts: PaginatedProductMapping;
  onClickDownloadCSV(): Promise<void>;
  loadMoreProducts?(
    topLevelUnitType: TopLevelUnitType,
    availability: AvailabilitySection,
    offset: number,
  ): Promise<OrganisationProductInventoryView[] | undefined>;
  searchProducts: (val: string, availability: AvailabilitySection) => Promise<OrganisationProductInventoryView[]>;
}

export const OrganisationInventoryPageView = ({
  availableProducts,
  depletedProducts,
  onClickDownloadCSV,
  loadMoreProducts,
  searchProducts,
}: OrganisationInventoryPageViewProps) => {
  const [selectedSection, setSelectedSection] = useState<AvailabilitySection>("available");
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);

  const setSelectedTab = (selection: AvailabilitySection) => {
    setShowSearchResults(false);
    setSelectedSection(selection);
  };

  const hasAvailableInventory = useMemo(() => availableProducts.size > 0, [availableProducts]);
  const hasDepletedInventory = useMemo(() => depletedProducts.size > 0, [depletedProducts]);

  const loadAvailableProducts = useCallback(
    async (topLevelUnitType: TopLevelUnitType, offset: number) => {
      return loadMoreProducts && (await loadMoreProducts(topLevelUnitType, "available", offset));
    },
    [loadMoreProducts],
  );
  const loadDepletedProducts = useCallback(
    async (topLevelUnitType: TopLevelUnitType, offset: number) => {
      return loadMoreProducts && (await loadMoreProducts(topLevelUnitType, "depleted", offset));
    },
    [loadMoreProducts],
  );

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Inventory" />}>
      <Stack spacing={3}>
        <Tabs value={selectedSection} onChange={(_, v) => setSelectedTab(v)}>
          <Tab label="Available" value="available" />
          <Tab label="Depleted" value="depleted" />
        </Tabs>

        {hasAvailableInventory && selectedSection === "available" && (
          <AvailableSection
            products={availableProducts}
            onClickDownloadCSV={onClickDownloadCSV}
            loadMoreProducts={loadAvailableProducts}
            searchProducts={searchTerm => {
              setShowSearchResults(true);
              return searchProducts(searchTerm, "available");
            }}
            showSearchResults={showSearchResults}
          />
        )}
        {hasDepletedInventory && selectedSection === "depleted" && (
          <DepletedSection
            products={depletedProducts}
            loadMoreProducts={loadDepletedProducts}
            searchProducts={searchTerm => {
              setShowSearchResults(true);
              return searchProducts(searchTerm, "depleted");
            }}
            showSearchResults={showSearchResults}
          />
        )}

        {((!hasAvailableInventory && selectedSection === "available") ||
          (!hasDepletedInventory && selectedSection === "depleted")) && (
          <Alert severity="info">{`You have no ${selectedSection.toLowerCase()} products in your inventory`}</Alert>
        )}
      </Stack>
    </PortalPageContent>
  );
};

const ProductSection = (props: {
  products: PaginatedProductMapping;
  onClickDownloadCSV?(): Promise<void>;
  loadMoreProducts?(
    topLevelUnitType: TopLevelUnitType,
    offset: number,
  ): Promise<OrganisationProductInventoryView[] | undefined>;
  searchProducts: (val: string) => Promise<OrganisationProductInventoryView[]>;
  showSearchResults: boolean;
  makeTableHeaderContent(topLevelUnitType?: TopLevelUnitType): React.ReactElement;
  makeTableBodyContent(
    rows: OrganisationProductInventoryView[],
  ): React.ReactElement<{ rows: OrganisationProductInventoryView[] }>;
}) => {
  const {
    products,
    onClickDownloadCSV,
    searchProducts,
    loadMoreProducts,
    showSearchResults,
    makeTableHeaderContent,
    makeTableBodyContent,
  } = props;
  const stockTypes: Set<TopLevelUnitType> = new Set(products.keys());
  const [selectedStockType, setSelectedStockType] = useState<TopLevelUnitType | undefined>(
    stockTypes.size > 0 ? [...stockTypes].sort()[0] : undefined,
  );
  const displayedProducts = useMemo(
    () =>
      (selectedStockType && products.get(selectedStockType)) ??
      makePaginated({ items: [], current_offset: 0, total_size: 0 }),
    [selectedStockType, products],
  );
  const [searchResults, setSearchResults] = useState<
    | {
        searchTerm: string;
        results: Map<TopLevelUnitType, OrganisationProductInventoryView[]>;
      }
    | undefined
  >();

  const displayedSearchResults = useMemo(() => {
    return (selectedStockType && searchResults?.results.get(selectedStockType)) ?? [];
  }, [selectedStockType, searchResults]);
  const hasMoreThanOneStockType = useMemo(() => stockTypes.size > 1, [stockTypes.size]);

  useEffect(() => {
    !showSearchResults && setSearchResults(undefined);
  }, [showSearchResults]);

  const loadMoreData = useCallback(
    async (offset: number) => {
      if (selectedStockType) {
        return await loadMoreProducts?.(selectedStockType, offset);
      } else {
        return;
      }
    },
    [loadMoreProducts, selectedStockType],
  );
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ flex: 1 }}>
          {hasMoreThanOneStockType && (
            <StockTypeToggle
              availableTypes={stockTypes}
              onChange={s => setSelectedStockType(s)}
              topLevelUnitType={selectedStockType}
            />
          )}
        </Box>
        <Stack direction="row" spacing={2}>
          <SearchInput
            label="Product search"
            onChange={async searchTerm => {
              if (searchTerm?.trim()) {
                const searchedProducts = await searchProducts(searchTerm);
                const results = new Map<TopLevelUnitType, OrganisationProductInventoryView[]>();
                for (const unitType of valuesTopLevelUnitType) {
                  const products = searchedProducts.filter(p => getTopLevelForUnitType(p.unitType) === unitType);
                  if (products.length > 0) {
                    results.set(unitType, products);
                  }
                }

                setSearchResults({ searchTerm, results });
              } else {
                setSearchResults(undefined);
              }
            }}
          />
          {onClickDownloadCSV && (
            <LoadingActionButton variant="outlined" onClick={onClickDownloadCSV}>
              Download CSV
            </LoadingActionButton>
          )}
        </Stack>
      </Stack>
      {searchResults ? (
        <PaginatedTable
          key={searchResults.searchTerm}
          initialRowsPerPage={25}
          HeaderContent={makeTableHeaderContent(selectedStockType)}
          BodyContent={makeTableBodyContent(displayedSearchResults)}
          initialRows={displayedSearchResults}
          totalRowCount={displayedSearchResults.length}
        />
      ) : (
        <PaginatedTable
          key={selectedStockType + "-all"}
          initialRowsPerPage={25}
          HeaderContent={makeTableHeaderContent(selectedStockType)}
          BodyContent={makeTableBodyContent(displayedProducts.items)}
          initialRows={displayedProducts.items}
          totalRowCount={displayedProducts.total_size}
          loadMoreData={loadMoreData}
        />
      )}
    </>
  );
};

const AvailableSection = (props: {
  products: PaginatedProductMapping;
  onClickDownloadCSV?(): Promise<void>;
  loadMoreProducts?(
    topLevelUnitType: TopLevelUnitType,
    offset: number,
  ): Promise<OrganisationProductInventoryView[] | undefined>;
  searchProducts: (val: string) => Promise<OrganisationProductInventoryView[]>;
  showSearchResults: boolean;
}) => {
  const { products, onClickDownloadCSV, loadMoreProducts, searchProducts, showSearchResults } = props;
  return (
    <ProductSection
      products={products}
      onClickDownloadCSV={onClickDownloadCSV}
      loadMoreProducts={loadMoreProducts}
      searchProducts={searchProducts}
      showSearchResults={showSearchResults}
      makeTableHeaderContent={() => <AvailableTableHeaderRow />}
      makeTableBodyContent={rows => <AvailableTableRows rows={rows} />}
    />
  );
};

const AvailableTableHeaderRow = () => (
  <TableRow>
    <TableCell>Product</TableCell>
    <TableCell align="left" sx={{ padding: 0 }}>
      Storage Location(s)
    </TableCell>
    <TableCell align="right" sx={{ padding: 0 }}>
      Quantity Available
    </TableCell>
    <TableCell align="right" sx={{ padding: 0 }}>
      Quantity Pending
    </TableCell>
    <TableCell align="center">Details</TableCell>
  </TableRow>
);

const AvailableTableRows = ({
  rows,
}: TableBodyProps & {
  rows: OrganisationProductInventoryView[];
}) => {
  return (
    <>
      {rows.length === 0 && <NoSearchResultsRow colSpan={5} />}
      {rows.map(product => (
        <ProductRow key={product.productId} product={product} />
      ))}
    </>
  );
};

const NoSearchResultsRow = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>No search results</TableCell>
    </TableRow>
  );
};

interface ProductRowProps {
  product: OrganisationProductInventoryView;
}
const ProductRow = ({ product }: ProductRowProps) => {
  const [rowOpened, setRowOpened] = useState<boolean>(false);

  const toggleRowOpen = useCallback(() => setRowOpened(!rowOpened), [rowOpened]);

  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary
          {...product}
          name={product.productName}
          code={product.productCode}
          vesselCapacity={getVesselCapacity(product.vesselSize)}
        />
      </TableCell>
      <ExpandableCells stocks={product.storageLocStocks} isOpened={rowOpened} product={product} />
      <TableCell align="center">
        <IconButton onClick={toggleRowOpen} disabled={product.storageLocStocks.length < 2}>
          {!rowOpened ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface ExpandableCellsProps {
  stocks: OrgStockAtStorageLoc[];
  product: OrganisationProductInventoryView;
  isOpened: boolean;
}

const ExpandableCells = ({ stocks, product, isOpened }: ExpandableCellsProps) => {
  // Calculate the height of the storage location(s) cell for products with more than 3 storage locs
  const textHeight = (theme: Theme) => parseInt(theme.spacing(5).slice(0, -2));
  const calculateStorageLocCellHeight = useCallback(
    (theme: Theme) => `calc(${textHeight(theme) * stocks.length}px)`,
    [stocks],
  );

  const commonStorageLocCellProps = useMemo(() => {
    return {
      spacing: 1,
      sx: {
        minHeight: (theme: Theme) => theme.spacing(15),
        height: (theme: Theme) => (stocks.length > 3 ? calculateStorageLocCellHeight(theme) : "auto"),
      },
      justifyContent: "center",
    };
  }, [stocks.length, calculateStorageLocCellHeight]);
  const animate = useMemo(() => stocks.length > 1, [stocks.length]);

  const ExpandedCell = useCallback(
    (renderProperty: (stock: OrgStockAtStorageLoc) => string) => (
      <CollapseAndGrow animate={animate} in={isOpened}>
        <Stack {...commonStorageLocCellProps} divider={<Divider flexItem />}>
          {stocks.map(stock => (
            <Typography key={stock.storageLocationId}>{renderProperty(stock)}</Typography>
          ))}
        </Stack>
      </CollapseAndGrow>
    ),
    [animate, commonStorageLocCellProps, isOpened, stocks],
  );

  return (
    <>
      <TableCell sx={{ padding: 0 }}>
        <CollapseAndGrow animate={animate} in={!isOpened}>
          <Stack {...commonStorageLocCellProps} spacing={2}>
            {product.storageLocStocks.map(stocks => (
              <Typography key={`ex-cells-${product.productId}-${stocks.storageLocationId}`}>
                {stocks.storageLocationName}
              </Typography>
            ))}
          </Stack>
        </CollapseAndGrow>
        {animate && ExpandedCell((stock: OrgStockAtStorageLoc) => stock.storageLocationName)}
      </TableCell>

      <TableCell align="right" sx={{ padding: 0 }}>
        <CollapseAndGrow animate={animate} in={!isOpened}>
          <Typography>{product.totalQuantityAvailable.value}</Typography>
        </CollapseAndGrow>
        {animate && ExpandedCell((stock: OrgStockAtStorageLoc) => stock.totalQuantityAvailable.value.toString())}
      </TableCell>

      <TableCell align="right" sx={{ padding: 0 }}>
        <CollapseAndGrow animate={animate} in={!isOpened}>
          <Typography>{product.totalPendingQuantity.value}</Typography>
        </CollapseAndGrow>
        {animate && ExpandedCell((stock: OrgStockAtStorageLoc) => stock.totalPendingQuantity.value.toString())}
      </TableCell>
    </>
  );
};

const commonCollapseProps = { timeout: 0, mountOnEnter: false, unmountOnExit: false };
const commonGrowProps = { timeout: 500 };
interface CollapseAndGrowProps {
  animate: boolean;
  in: boolean;
  children: React.ReactElement;
}
const CollapseAndGrow = ({ animate, children, ...props }: CollapseAndGrowProps) => {
  if (!animate) {
    return children;
  }
  return (
    <Collapse {...props} {...commonCollapseProps}>
      <Grow {...props} {...commonGrowProps}>
        {children}
      </Grow>
    </Collapse>
  );
};

const DepletedSection = (props: {
  products: PaginatedProductMapping;
  loadMoreProducts?: (
    topLevelUnitType: TopLevelUnitType,
    offset: number,
  ) => Promise<OrganisationProductInventoryView[] | undefined>;
  searchProducts: (val: string) => Promise<OrganisationProductInventoryView[]>;
  showSearchResults: boolean;
}) => {
  const { products, loadMoreProducts, searchProducts, showSearchResults } = props;
  const columnCount = useMemo(() => makeDepletedTableHeaderLables().length, []);
  return (
    <ProductSection
      products={products}
      loadMoreProducts={loadMoreProducts}
      searchProducts={searchProducts}
      showSearchResults={showSearchResults}
      makeTableHeaderContent={selectedStockType => <DepletedTableHeaderRow selectedStockType={selectedStockType} />}
      makeTableBodyContent={rows => <DepletedTableRows rows={rows} columnCount={columnCount} />}
    />
  );
};

function makeDepletedTableHeaderLables(selectedStockType?: TopLevelUnitType) {
  return [
    "Product Code",
    "Product Name",
    "Product Year",
    "Producer",
    "Unit type",
    `Unit size (${vesselSizeMetricForUnitType(selectedStockType)})`,
  ];
}

const DepletedTableHeaderRow = (props: { selectedStockType?: TopLevelUnitType }) => {
  const { selectedStockType } = props;
  const tableHeaders = useMemo(() => makeDepletedTableHeaderLables(selectedStockType), [selectedStockType]);
  return (
    <TableRow>
      {tableHeaders.map(title => (
        <TableCell key={title}>
          <Typography fontWeight="bold">{title}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const DepletedTableRows = (props: { columnCount: number; rows: OrganisationProductInventoryView[] }) => {
  const { columnCount, rows: displayedProducts } = props;
  const [openProductInfo] = useInfoDrawer();

  return (
    <>
      {displayedProducts.length === 0 && <NoSearchResultsRow colSpan={columnCount} />}
      {displayedProducts.map(product => (
        <TableRow key={product.productId}>
          <TableCell>
            <Link
              variant="big"
              onClick={() => openProductInfo({ children: <ProductMovement productId={product.productId} /> })}>
              {product.productCode}
            </Link>
          </TableCell>
          <TableCell>{product.productName}</TableCell>
          <TableCell>
            {product.productDate.kind === "vintageYear" ? product.productDate.value : "Non-Vintage"}
          </TableCell>
          <TableCell>{product.producerName}</TableCell>
          <TableCell>{unitTypeToString(product.unitType)}</TableCell>
          <TableCell>
            {formatVesselSize({
              vesselSize: product.vesselSize,
              defaultString: "-",
            })}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
