import { AppService } from "adl-gen/app-service";
import { BigDecimal, LocalDate } from "adl-gen/common";
import { DbKey, WithDbId } from "adl-gen/common/db";
import {
  Currency,
  Incoterms,
  MonthlyFee,
  MonthlyFee_Variable,
  NewDealRequest,
  NewDealRequestStateEvent,
  NumberOfUnits,
  Organisation,
  Product,
  ProductLineItem,
  RatesCurve,
  StorageLocation,
} from "adl-gen/ferovinum/app/db";
import {
  FullProductLineItemView,
  NewDealRequestView,
  NewDealRequestViewNetSubtotal,
  NewDealRequestViewTotalDiscount,
  NewDealRequestViewType,
  StockAdjustmentDetails,
} from "adl-gen/ferovinum/app/procurement";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { ContractNoteView, DealTermsView } from "adl-gen/ferovinum/app/views";
import { Maybe, Maybe_Just } from "adl-gen/runtime/sys/types";
import { useCallback, useEffect, useState } from "react";
import { subtract } from "utils/model-utils";
import { isNotNull } from "utils/ts-utils";
import { LoadingValue } from "utils/utility-types";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";

function toProductRequest(productLineItem: FullProductLineItemView): ProductRequest {
  const product = productLineItem.product;
  const {
    totalNumberOfUnits,
    numberOfFreeUnits,
    individualDiscountPct,
    clientSpecifiedPurchasePrice,
    purchaseCurrencyPrice,
    compulsorySalePc,
    throughputFeePc,
    compulsorySaleDate,
    finalDate,
    depositPrice,
    provisionalPurchasePrice,
    monthlyFee,
    finalPurchasePrice,
  } = productLineItem.productLineItem.value;
  return {
    product,
    numberOfUnits: subtract(totalNumberOfUnits, numberOfFreeUnits),
    numberOfFreeUnits: numberOfFreeUnits,
    individualDiscountPct: individualDiscountPct ?? "0",
    clientSpecifiedPurchasePrice: clientSpecifiedPurchasePrice,
    purchasePrice: purchaseCurrencyPrice,
    provisionalPurchasePrice: provisionalPurchasePrice ?? undefined,
    finalPurchasePrice: finalPurchasePrice ?? undefined,
    depositPrice: depositPrice ?? undefined,
    csDate: compulsorySaleDate ?? undefined,
    preCSDUnits: productLineItem.preCsdUnits ?? undefined,
    postCSDUnits: productLineItem.postCsdUnits ?? undefined,
    finalDate: finalDate ?? undefined,
    csPercentage: compulsorySalePc ?? undefined,
    throughputFeePercentage: throughputFeePc ?? undefined,
    monthlyFee: monthlyFee ?? undefined,
    netSubtotal: productLineItem.netSubtotal,
    grossPayableAmount: productLineItem.grossPayableAmount ?? undefined,
    discountAmount: productLineItem.discountAmount ?? undefined,
    singlesPerCase: productLineItem.productLineItem.value.singlesPerCase ?? undefined,
    productLineItemId: productLineItem.productLineItem.id,
  };
}

export interface ProductRequest {
  product: WithDbId<Product>;
  numberOfUnits: NumberOfUnits;
  numberOfFreeUnits: NumberOfUnits;
  individualDiscountPct: BigDecimal;
  // Purchase price in the new deal request purchase currency
  clientSpecifiedPurchasePrice: BigDecimal;
  purchasePrice: string;
  // Provisional purchase price in the settlement currency
  provisionalPurchasePrice?: string;
  // Final purchase price in the settlement currency
  finalPurchasePrice?: string;
  csDate?: LocalDate;
  csPercentage?: string;
  // number of units to repurchase before the compulsory sale date
  preCSDUnits?: NumberOfUnits;
  // number of units to repurchase at the final sale date
  postCSDUnits?: NumberOfUnits;
  finalDate?: LocalDate;
  depositPrice?: string;
  monthlyFee?: MonthlyFee;
  throughputFeePercentage?: string;
  // valuation in purchase currency without including the free stock units of this product
  netSubtotal: string;
  // Regarding: *PayableAmount fields they represent:
  // Net advance amount for products that are existing stock
  // Deposit payable for products that are new stock

  // Note - This field includes free stock units
  // Gross payable amount in the purchase currency
  grossPayableAmount?: string;
  // Amount discounted from free units of this item
  // purchase currency
  discountAmount?: string;
  singlesPerCase?: number;
  productLineItemId?: DbKey<ProductLineItem>;
}

export interface NewDealRequestDetailsView {
  id: string;
  newDealRequestType: NewDealRequestViewType;
  organisationId: string;
  clientName: string;
  clientIsWholesaler?: boolean;
  createdAt: number;
  storageLocationName: string;
  storageFerovinumAccountCode: string | null;
  storageLocationRotationNumbersRequired: boolean;
  dealTermsView: DealTermsView;
  additionalDealTerms: string;
  supplierIsPaid: number | null;
  carrierIsPaid: number | null;
  supplierEarliestCollectionDate: LocalDate | null;
  carrierEarliestCollectionDate: LocalDate | null;
  carrierDeliveryEta?: LocalDate;

  products: ProductRequest[];
  ratesCurves: WithDbId<RatesCurve>[];
  currentState: NewDealRequestStateEvent;
  newDealRequestEvents: NewDealRequestStateEvent[];
  purchaseCurrency: Currency;
  settlementCurrency: Currency;
  dealNumber: string;
  dealId: string | null;
  // Net valuation without free stock
  // Note - This field includes free stock units if new stock
  netSubtotal: NewDealRequestViewNetSubtotal;
  // Net valutaitons with total and individual discount applied
  discount: NewDealRequestViewTotalDiscount;
  grossTotalDepositAmount?: string;
  // Note - This field has discounted the free units commercial value amount if new stock
  netTotalDepositAmount?: string;
  // Total discounted amount also known as total commercial value of the free units
  totalDiscountAmount?: string;
  // The reason that this deal request was rejected (if rejected)
  rejectionReason?: string;
  // Organisation's default fees and charges
  defaultMonthlyFee?: MonthlyFee;
  defaultThroughputFee?: string;
  defaultFeroFxServiceCharge?: string;

  stockAdjustmentDetails?: StockAdjustmentDetails;

  // The total gross amount payable by Fero
  purchaseCurrencyFeroGrossTotal: MonetaryValue;
  // Total gross payable amount in the settlement currency for foreign currency deals
  settlementCurrencyFeroGrossTotal?: MonetaryValue;
  provisionalSettlementCurrencyFeroGrossTotal?: MonetaryValue;
  totalNetAdvanceAmount?: MonetaryValue;
  provisionalTotalNetAdvanceAmount?: MonetaryValue;
  incoterms?: Incoterms;
  depositInvoiceNumber?: string;
  purchaseOrderNumber?: string;
  purchaseBillNumber?: string;
  carrierPaymentDeadline?: LocalDate;
  supplierPaymentDeadline?: LocalDate;
  purchaseToSettlementFx?: string;

  contractNote?: ContractNoteView;
  availablePdfs: AvailablePdf[];
  poUrl?: string;
}

function toNewDealRequestDetailView(
  newDealRequestType: NewDealRequestViewType,
  newDealRequest: WithDbId<NewDealRequest>,
  organisation: WithDbId<Organisation>,
  storageLocation: StorageLocation,
  products: ProductRequest[],
  ratesCurves: WithDbId<RatesCurve>[],
  dealTermsView: DealTermsView,
  netSubtotal: NewDealRequestViewNetSubtotal,
  discount: NewDealRequestViewTotalDiscount,
  grossTotalDepositAmount: string | null,
  netTotalDepositAmount: string | null,
  totalDiscountAmount: string | null,
  stockAdjustmentDetails: StockAdjustmentDetails | null,
  purchaseCurrencyFeroGrossTotal: string,
  settlementCurrencyFeroGrossTotal: string | null,
  provisionalSettlementCurrencyFeroGrossTotal: string | null,
  totalNetAdvanceAmount: string | null,
  provisionalTotalNetAdvanceAmount: string | null,
  carrierPaymentDeadline: LocalDate | null,
  supplierPaymentDeadline: LocalDate | null,
  purchaseToSettlementFx: string | null,
  contractNote: ContractNoteView | null,
  availablePdfs: AvailablePdf[],
  poUrl: string | null,
): NewDealRequestDetailsView {
  const {
    value: { businessName: clientName, facilitySegment },
    id: organisationId,
  } = organisation;
  const {
    id,
    value: {
      createdAt,
      newDealRequestEvents,
      lastStateSet,
      state,
      supplierEarliestCollectionDate,
      carrierEarliestCollectionDate,
      incoterms,
      depositInvoiceNumber,
      purchaseOrderNumber,
      purchaseBillNumber,
      carrierDeliveryEta,
    },
  } = newDealRequest;
  return {
    id,
    newDealRequestType: newDealRequestType,
    organisationId,
    clientName,
    createdAt,
    clientIsWholesaler: facilitySegment === "Wholesaler",

    storageLocationName: storageLocation.locationName,
    storageLocationRotationNumbersRequired: storageLocation.rotationNumberRequired,
    storageFerovinumAccountCode: storageLocation.ferovinumAccountCode,
    dealTermsView,
    additionalDealTerms: newDealRequest.value.additionalTermsText ?? "",
    supplierIsPaid: newDealRequest.value.supplierIsPaid,
    carrierIsPaid: newDealRequest.value.carrierIsPaid,
    supplierEarliestCollectionDate,
    carrierEarliestCollectionDate,
    carrierDeliveryEta: carrierDeliveryEta ?? undefined,
    products,
    ratesCurves,
    currentState: { time: lastStateSet, state },
    newDealRequestEvents,
    purchaseCurrency: newDealRequest.value.purchaseCurrency,
    settlementCurrency: newDealRequest.value.settlementCurrency,
    dealNumber: newDealRequest.value.dealNumber,
    dealId: newDealRequest.value.dealId,
    netSubtotal,
    discount,
    grossTotalDepositAmount: grossTotalDepositAmount ?? undefined,
    netTotalDepositAmount: netTotalDepositAmount ?? undefined,
    totalDiscountAmount: totalDiscountAmount ?? undefined,
    rejectionReason: newDealRequest.value.rejectionReason ?? undefined,
    defaultMonthlyFee: organisation.value.defaultMonthlyFee ?? undefined,
    defaultThroughputFee: organisation.value.defaultThroughputFee ?? undefined,
    defaultFeroFxServiceCharge: organisation.value.defaultFeroFxServiceCharge ?? undefined,
    stockAdjustmentDetails: stockAdjustmentDetails ?? undefined,
    purchaseCurrencyFeroGrossTotal: purchaseCurrencyFeroGrossTotal,
    settlementCurrencyFeroGrossTotal: settlementCurrencyFeroGrossTotal ?? undefined,
    provisionalSettlementCurrencyFeroGrossTotal: provisionalSettlementCurrencyFeroGrossTotal ?? undefined,
    totalNetAdvanceAmount: totalNetAdvanceAmount ?? undefined,
    provisionalTotalNetAdvanceAmount: provisionalTotalNetAdvanceAmount ?? undefined,
    incoterms: incoterms ?? undefined,
    depositInvoiceNumber: depositInvoiceNumber ?? undefined,
    purchaseOrderNumber: purchaseOrderNumber ?? undefined,
    purchaseBillNumber: purchaseBillNumber ?? undefined,
    carrierPaymentDeadline: carrierPaymentDeadline ?? undefined,
    supplierPaymentDeadline: supplierPaymentDeadline ?? undefined,
    purchaseToSettlementFx: purchaseToSettlementFx ?? undefined,
    contractNote: contractNote ?? undefined,
    availablePdfs: availablePdfs,
    poUrl: poUrl ?? undefined,
  };
}

export const useNewDealRequestImpl = (
  newDealRequestId: string,
  appService: AppService,
  opts?: Partial<{
    adminView?: boolean;
    lazyLoad?: boolean; // if true, the new deal request details will not be fetched until the refresh function is called
  }>,
): { newDealRequest: LoadingValue<NewDealRequestDetailsView>; refresh: () => void } => {
  const { adminView = false, lazyLoad = false } = opts ?? {};
  const [newDealRequest, setDealRequestDetails] = useState<LoadingValue<NewDealRequestDetailsView>>({
    state: "loading",
  });
  const [refresh, setRefresh] = useState(!lazyLoad);

  /** Allows to trigger a new fetch of the new deal request details */
  //TODO(berto): Revisit the need for this function to be wrapped into useCallback when used
  const triggerManualRefresh = useCallback(() => {
    setRefresh(true);
  }, []);

  const getRatesCurves = useCallback(
    async (newDealRequest: NewDealRequestView): Promise<WithDbId<RatesCurve>[]> => {
      if (adminView) {
        return appService.getAllRatesCurves();
      }

      const hasRatesCurve = (val: MonthlyFee): val is MonthlyFee_Variable => val !== null && val.kind === "variable";
      const isPresent = <T>(val: Maybe<T>): val is Maybe_Just<T> => val.kind === "just";

      const ratesCurvesToFetch = new Set(
        newDealRequest.productLineItems
          .map(product => product.productLineItem.value.monthlyFee)
          .filter(isNotNull)
          .filter(hasRatesCurve)
          .map(val => val.value.reference),
      );

      return (
        await Promise.all(Array.from(ratesCurvesToFetch).map(id => appService.getRatesCurve({ kind: "id", value: id })))
      )
        .filter(isPresent)
        .map(val => val.value);
    },
    [appService, adminView],
  );

  // if the new deal request id changes we need to trigger a new fetch
  useEffect(() => {
    if (newDealRequestId) {
      setRefresh(!lazyLoad);
    }
  }, [newDealRequestId, lazyLoad]);

  useEffect(() => {
    let active = true;

    const fetchNewDealRequestDetails = async () => {
      const resp = await appService.getNewDealRequestDetails({ newDealsRequestId: newDealRequestId });
      const ratesCurves = await getRatesCurves(resp);

      const { newDealRequest, organisation, storageLocation } = resp;
      if (active) {
        setRefresh(false);
        setDealRequestDetails({
          state: "success",
          value: toNewDealRequestDetailView(
            resp.newDealRequestType,
            newDealRequest,
            organisation,
            storageLocation.value,
            resp.productLineItems.map(toProductRequest),
            ratesCurves,
            resp.dealTermsView,
            resp.netSubtotal,
            resp.discount,
            resp.grossTotalDepositAmount,
            resp.netTotalDepositAmount,
            resp.totalDiscountAmount,
            resp.stockAdjustmentDetails,
            resp.purchaseCurrencyFeroGrossTotalPayableAmount,
            resp.settlementCurrencyFeroGrossTotalPayableAmount,
            resp.provisionalSettlementCurrencyFeroGrossTotalPayableAmount,
            resp.netAdvanceAmount,
            resp.provisionalNetAdvanceAmount,
            resp.carrierPaymentDeadline,
            resp.supplierPaymentDeadline,
            resp.purchaseToSettlementFx,
            resp.contractNote,
            resp.availablePdfs,
            resp.poUrl,
          ),
        });
      }
    };

    if (refresh) {
      setDealRequestDetails({ state: "loading" });
      fetchNewDealRequestDetails().catch(() => {
        if (active) {
          setDealRequestDetails({
            state: "error",
            error: new Error("Error loading new deal request details"),
          });
        }
      });
    }

    return () => {
      active = false;
    };
  }, [appService, newDealRequestId, refresh, adminView, getRatesCurves]);

  return { newDealRequest, refresh: triggerManualRefresh };
};
