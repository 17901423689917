import { WithDbId } from "adl-gen/common/db";
import {
  CaskedWhisky,
  Product,
  ProductDate,
  ProductType,
  UnitType,
  VesselSize,
  valuesCaskComposition,
  valuesCaskFill,
  valuesCaskFormat,
  valuesCaskGeographicOrigin,
  valuesCaskOrientation,
  valuesCaskWood,
  valuesCountry,
  valuesWhiskyLiquid,
  valuesWhiskyStage,
} from "adl-gen/ferovinum/app/db";
import { pick } from "lodash";
import { countryCodeToCountryName, stringToCountryCode } from "utils/conversion-utils";
import { CsvRowParser } from "utils/csv-row-parser";
import {
  enumField,
  localDateField,
  numberField,
  partialDateField,
  staticValue,
  stringField,
} from "utils/data-field/data-field-builder";
import { ObjectFields, checkHeadersMatch, makeObjectDef } from "utils/data-field/object-field-def";
import { numberOfUnitsForUnitType } from "utils/model-utils";
import { NullToUndef, mapNullToUndef, mapUndefToNull } from "utils/null-and-undefined-utils";
import { NewProductData } from "../ui/page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import {
  CsvDataField,
  CsvProductLineItemData,
  ProductCsvParser,
  createCsvDataFields,
} from "./csv-product-data-processing-types";
import { DUPLICATE_PRODUCT_MESSAGE } from "./csv-product-data-yup-schemas";

type BaseProductData = Omit<NewProductData, "alcoholDetail" | "vesselType" | "physicalDetails">;
export const BASE_PRODUCT_DATA_DEF = makeObjectDef<BaseProductData>({
  code: stringField("Product code").required(),
  name: stringField("Product name").required(),
  producerName: stringField("Producer name").required(),
  productDate: staticValue<ProductDate>({ kind: "nonVintage" }),
  vesselSize: staticValue<VesselSize | null>(null),
  unitType: staticValue<UnitType>("cask"),
  countryOfOrigin: enumField("Country of Origin", valuesCountry, {
    parse: stringToCountryCode,
    toString: countryCodeToCountryName,
  }).required(),
  regionOrigin: stringField("Region of origin").required(),
  alcoholByVolumePc: numberField("ABV", { range: { min: 0, max: 100 }, precision: 1 }).required(),
  productType: staticValue<ProductType>("spirits"),
});

type CaskedWhiskyData = NullToUndef<CaskedWhisky>;
export const CASKED_WHISKY_DATA_DEF = makeObjectDef<CaskedWhiskyData>({
  // CaskedWhisky
  area: stringField("Area of Origin").optional(),
  liquid: enumField("Whisky Liquid", valuesWhiskyLiquid).required(),
  make: stringField("Make").optional(),
  aysDate: localDateField("AYS").required(),
  stage: enumField("Fill Stage", valuesWhiskyStage).optional(),
  caskFormat: enumField("Cask Format", valuesCaskFormat).optional(),
  caskFill: enumField("Cask Fill", valuesCaskFill).optional(),
  caskComposition: enumField("Cask Composition", valuesCaskComposition).optional(),
  caskWood: enumField("Wood", valuesCaskWood).optional(),
  filledAt: partialDateField("Fill Date").optional(),
  regaugedAt: partialDateField("Regauge Date").optional(),
  caskOrientation: enumField("Orientation", valuesCaskOrientation).optional(),
  caskGeographicOrigin: enumField("Cask Geographic Origin", valuesCaskGeographicOrigin).optional(),
  caskNumber: stringField("Cask Number").optional(),
  distilleryProductionParcel: stringField("Distillery Production Parcel").optional(),
  locationInfo: stringField("Location Info").optional(),
  additionalNotes: stringField("Additional Notes").optional(),
});

type DealInfo = { lpa: number; clientSpecifiedPurchasePrice: number };
const DEAL_INFO_DEF = makeObjectDef<DealInfo>({
  lpa: numberField("LPA", { range: "positive" }).required(),
  clientSpecifiedPurchasePrice: numberField("Price", { precision: 2 }).required(),
});

export type CaskedWhiskyProductInfo = BaseProductData & CaskedWhiskyData & Pick<DealInfo, "lpa">;
export const CASKED_WHISKY_PRODUCT_INFO_DEF = makeObjectDef<CaskedWhiskyProductInfo>({
  ...BASE_PRODUCT_DATA_DEF,
  ...CASKED_WHISKY_DATA_DEF,
  ...pick(DEAL_INFO_DEF, "lpa"),
});

export class CaskedWhiskyCsvParser implements ProductCsvParser {
  // Check that the CSV file is a Casked Whisky CSV file by checking that the headers match
  // check that each column is either optional or header for the column exists
  public static matchHeaders(headers: string[]): boolean {
    return [BASE_PRODUCT_DATA_DEF, CASKED_WHISKY_DATA_DEF, DEAL_INFO_DEF].every((def: Readonly<ObjectFields<object>>) =>
      checkHeadersMatch(def, headers),
    );
  }

  private baseProductDataParser: CsvRowParser<BaseProductData>;
  private caskedWhiskyDataParser: CsvRowParser<CaskedWhiskyData>;
  private dealInfoParser: CsvRowParser<DealInfo>;

  constructor(headers: string[]) {
    this.baseProductDataParser = CsvRowParser.make(BASE_PRODUCT_DATA_DEF, headers, ({ code }, labels) => {
      if (code && code.length > 30) {
        return `${labels.code} cannot exceed 30 characters.`;
      }
    });
    this.caskedWhiskyDataParser = CsvRowParser.make(CASKED_WHISKY_DATA_DEF, headers);
    this.dealInfoParser = CsvRowParser.make(DEAL_INFO_DEF, headers);
  }

  public getProductCode(rowData: Readonly<string[]>): string {
    return this.baseProductDataParser.castData(rowData, "code") ?? "";
  }

  public createNewProduct(rowData: Readonly<string[]>): CsvProductLineItemData {
    const baseProductData = this.baseProductDataParser.parse(rowData);
    const caskedWhisky = this.caskedWhiskyDataParser.parse(rowData);
    const { lpa, clientSpecifiedPurchasePrice } = this.dealInfoParser.parse(rowData);
    const productData: NewProductData = {
      ...baseProductData,
      physicalDetails: null,
      alcoholDetail: {
        kind: "caskedWhisky",
        value: mapUndefToNull(caskedWhisky),
      },
      vesselType: {
        kind: "cask",
        value: { lpa },
      },
    };
    return {
      kind: "new",
      productCode: baseProductData.code,
      clientSpecifiedPurchasePrice,
      numberOfUnits: numberOfUnitsForUnitType("cask", lpa),
      numberOfFreeUnits: undefined,
      value: productData,
    };
  }

  public createExistingProduct(
    rowData: Readonly<string[]>,
    existingProduct: WithDbId<Product>,
  ): CsvProductLineItemData {
    const productCode = this.getProductCode(rowData);
    const { lpa, clientSpecifiedPurchasePrice } = this.dealInfoParser.parse(rowData);
    const csvDataFields = this.getCsvDataFieldsForExistingProduct(rowData, existingProduct.value);
    const hasMismatchedDetails = csvDataFields.some(field => field.isMismatched);
    return {
      kind: "existing",
      productCode,
      clientSpecifiedPurchasePrice,
      numberOfUnits: numberOfUnitsForUnitType("cask", lpa),
      numberOfFreeUnits: undefined,
      value: existingProduct,
      csvDataFields,
      hasMismatchedDetails,
    };
  }

  // Perform a string-compare for the fields from the CSV and the existing product's fields.
  // This function will only look at columns that are filled in, because we assume that if the user leaves
  // a column empty, they meant to use the system's details.
  private getCsvDataFieldsForExistingProduct(rowData: Readonly<string[]>, existingProduct: Product): CsvDataField[] {
    const coreProductDataParser = this.baseProductDataParser.omit("code");
    const coreProductData = coreProductDataParser.pickData(existingProduct);
    const coreProductFields = createCsvDataFields(rowData, coreProductData, coreProductDataParser);

    const { alcoholDetail } = existingProduct;
    if (alcoholDetail.kind === "caskedWhisky") {
      const alcoholDetailData = mapNullToUndef(alcoholDetail.value);
      const caskedWhiskyFields = createCsvDataFields(rowData, alcoholDetailData, this.caskedWhiskyDataParser);
      return [...coreProductFields, ...caskedWhiskyFields];
    } else {
      return coreProductFields;
    }
  }

  public getErrorMessagesForRow(
    isExistingProduct: boolean,
    rowData: Readonly<string[]>,
    uniqueProductCodes: Set<string>,
  ): string[] {
    const productCode = this.getProductCode(rowData);
    if (uniqueProductCodes.has(productCode)) {
      return [DUPLICATE_PRODUCT_MESSAGE];
    }

    const productErrors = this.baseProductDataParser.checkForErrors(rowData);

    if (!isExistingProduct) {
      return [
        ...productErrors,
        ...this.caskedWhiskyDataParser.checkForErrors(rowData),
        ...this.dealInfoParser.checkForErrors(rowData),
      ];
    } else {
      return [];
    }
  }
}
