import { UploadedFile } from "adl-gen/common";
import {
  CreateProductionOrderForPurchaseRequest,
  CreatePurchaseRequestLineItem,
  PurchaseRequestPreviewReq,
} from "adl-gen/ferovinum/app/api";
import { OrgPurchaseRequestSummaryPreview } from "adl-gen/ferovinum/app/nompurchaser";
import { useUiConfig } from "adl-service/hooks/use-ui-config";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { UploadFile } from "utils/file-fetch-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import { numberOfUnitsForUnitType } from "utils/model-utils";
import { isPositiveOrZeroNumber } from "utils/ts-utils";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { LoggedInContext } from "../../../../../app/app";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAppService } from "../../../../../hooks/use-app-service";
import {
  OrganisationPurchaseRequestData,
  OrganisationValidPurchaseRequestData,
  PURCHASE_REQUEST_DEFAULT_COLLECTION_DAYS,
  PURCHASE_REQUEST_DEFAULT_EXPIRY_DAYS,
  loadOrganisationPurchaseRequest,
} from "../../../../../hooks/use-purchase-request";
import { useSelectedOrg, useSelectedOrgFeatures } from "../../../../layouts/portal-page-layout/portal-page";
import { ProductionPlanPurchaseRequest } from "../organisation-purchase-request-production-plan/organisation-purchase-request-production-plan-page-view";
import { PurchaseRequestFlowState } from "../organisation-purchase-request-setup/organisation-purchase-request-setup-page";
import { OrganisationPurchaseRequestConfirmationPageView } from "./organisation-purchase-request-confirmation-page-view";

export interface PurchaseRequestConfirmationData
  extends Omit<
    OrganisationPurchaseRequestData,
    "cashAmountToAdvanceUponDelivery" | "summary" | "advanceUponDeliveryPaymentDate" | "orgNetReceivablePaymentDate"
  > {
  validEstimateNetReceivable: boolean;
  validRemainingCredit: boolean;
  isConfirmed: boolean;
  productionPlan?: ProductionPlanPurchaseRequest;
  summary: OrgPurchaseRequestSummaryPreview;
  customerPoRef?: string;
  shipmentNumber?: string;
}

export const OrganisationPurchaseRequestConfirmationPage = () => {
  const isDevelopment = useUiConfig().environment === "dev";
  const authToken: string = assertNotUndefined(useContext(LoggedInContext).loginState?.token);
  const service = useAppService();
  const location = useLocation();
  const history = useHistory();
  const [showAlert] = useAlert();
  const org = useSelectedOrg();
  const purchaseRequestFlowState = location.state as PurchaseRequestFlowState | undefined;
  const orgFeatures = assertNotUndefined(useSelectedOrgFeatures());
  // Current purchase request information. It can be either preview or confirmed
  // Preview: It's a combination of the user forms input + preview endpoint
  // Confirmed: returned by the system backend after the purchase request has been created
  const [purchaseRequestData, setPurchaseRequestData] = useState<LoadingValue<PurchaseRequestConfirmationData>>({
    state: "loading",
  });

  const hasValidNetReceivable = useCallback(
    (netReceivable: string): boolean => {
      const canCreateWithNegativeNetReceivable =
        orgFeatures.find(f => f === "allow_negative_receivable_trade_sales") !== undefined;
      const isPositive = Number(netReceivable) >= 0;
      return isPositive || canCreateWithNegativeNetReceivable;
    },
    [orgFeatures],
  );

  const createPurchaseRequestReq: PurchaseRequestPreviewReq = useMemo(() => {
    if (
      purchaseRequestFlowState?.delivery === undefined ||
      purchaseRequestFlowState?.setup === undefined ||
      purchaseRequestFlowState.setup.storageLocation === undefined
    ) {
      throw new Error("Invalid purchase request");
    }

    const lineItems: CreatePurchaseRequestLineItem[] = purchaseRequestFlowState.setup.products.map(p => {
      const productId = p.product.id;
      const fromProductionOrders: CreateProductionOrderForPurchaseRequest[] =
        purchaseRequestFlowState.productionPlan
          ?.find(pp => pp.finishedProduct.product.id === productId)
          ?.productionOrders.map(({ sourceProduct, tasks }) => {
            return tasks.map(task => {
              return {
                finishingProductMappingId: task.finishingProductMappingId,
                quantity: numberOfUnitsForUnitType(sourceProduct.value.unitType, task.quantity),
                pricePerUnit: task.pricePerUnit.toString(),
                setupCost: task.setupFee.toString(),
              };
            });
          })
          .flatMap(t => t) ?? [];
      return {
        productId,
        quantity: numberOfUnitsForUnitType(p.product.value.unitType, p.paidUnits),
        freeUnits: p.freeUnits != undefined ? numberOfUnitsForUnitType(p.product.value.unitType, p.freeUnits) : null,
        unitPrice: p.unitPrice.toString(),
        priceDiscount: p.priceDiscount ?? null,
        fromProductionOrders,
      } as CreatePurchaseRequestLineItem;
    });

    const previewRequest: PurchaseRequestPreviewReq = {
      nominatedPurchaserId: purchaseRequestFlowState.setup.purchaser.nominatedPurchaserId,
      storageLocationId: purchaseRequestFlowState.setup.storageLocation.id,
      stockPreparationDays: purchaseRequestFlowState.delivery.preparationDays,
      purchaseRequestDeliveryOption: purchaseRequestFlowState.delivery.deliveryOption,
      paymentTermsPeriod: purchaseRequestFlowState.delivery.paymentTermsPeriod,
      purchaserCoversDeliveryCost: purchaseRequestFlowState.delivery.purchaserCoversDeliveryCost,
      salePriceType: purchaseRequestFlowState.setup.salePriceType,
      lineItems,
      dealDiscountAmount: purchaseRequestFlowState.setup.dealDiscountAmount ?? null,
    };
    return previewRequest;
  }, [purchaseRequestFlowState?.delivery, purchaseRequestFlowState?.setup, purchaseRequestFlowState?.productionPlan]);

  const loadPurchaseRequestData = useCallback(
    async (purchaseRequestId?: string): Promise<LoadingValue<PurchaseRequestConfirmationData>> => {
      if (
        org?.value.currency === undefined ||
        purchaseRequestFlowState?.delivery === undefined ||
        purchaseRequestFlowState?.setup === undefined ||
        purchaseRequestFlowState.setup.storageLocation === undefined
      ) {
        return { state: "error", error: new Error("Purchase request flow state unavailable") };
      }

      if (purchaseRequestId) {
        const confirmed = (await loadOrganisationPurchaseRequest(
          service,
          purchaseRequestId,
        )) as OrganisationValidPurchaseRequestData;

        return {
          state: "success",
          value: {
            ...confirmed,
            customerPoRef: confirmed.summary.customerPoRef ?? undefined,
            shipmentNumber: confirmed.summary.shipmentNumber ?? undefined,
            isConfirmed: true,
            validEstimateNetReceivable: true,
            validRemainingCredit: true,
            // Using the local data here as the production plan is only displayed in this confirmation screen
            // all the other screens will just directly link to the production order details page
            // to display the details of the linked production order
            productionPlan: purchaseRequestFlowState?.productionPlan,
            // Rebuild summary in the same shape as preview so that the UI doesn't need to deal with two object shapes
            summary: {
              ...confirmed.summary,
              dealDiscountAmount: confirmed.summary.dealDiscountAmount,
              discountedReceivable: confirmed.summary.cashAmountToAdvanceUponDelivery
                ? {
                    ...confirmed.summary,
                    amountAdvancedUponDelivery: confirmed.summary.cashAmountToAdvanceUponDelivery ?? "0",
                  }
                : null,
            },
          },
        };
      }

      const [summary, timeline] = await Promise.all([
        service.thirdPartySalesSummaryPreview(createPurchaseRequestReq),
        service.getPurchaseRequestTimelinePreview({
          ...createPurchaseRequestReq,
          paymentTerms: purchaseRequestFlowState.delivery.paymentTermsPeriod,
          deliveryOption: purchaseRequestFlowState.delivery.deliveryOption,
        }),
      ]);

      if (summary.kind !== "success") {
        throw new Error(summary.kind);
      }

      // Preview based on user input + endpoint
      return {
        state: "success",
        value: {
          isConfirmed: false,
          state: "new",
          stateEvents: [],
          purchaseRequestNumber: "",
          purchaserCoversDeliveryCost: purchaseRequestFlowState.delivery.purchaserCoversDeliveryCost,
          purchaserCurrency: purchaseRequestFlowState.setup.purchaser.currency,
          settlementCurrency: org?.value.currency,
          stockPreparationDays: purchaseRequestFlowState.delivery.preparationDays,
          expiryDays: PURCHASE_REQUEST_DEFAULT_EXPIRY_DAYS,
          collectionDays: PURCHASE_REQUEST_DEFAULT_COLLECTION_DAYS,
          paymentTermsPeriod: purchaseRequestFlowState.delivery.paymentTermsPeriod,
          storageLocation: purchaseRequestFlowState.setup.storageLocation.value,
          purchaserName: purchaseRequestFlowState.setup.purchaser.name,
          purchaseRequestDeliveryOption: purchaseRequestFlowState.delivery.deliveryOption,
          deliveryStatuses: [],
          terms: purchaseRequestFlowState.setup.purchaser.terms,
          // Receivable value is zero or positive
          validEstimateNetReceivable: hasValidNetReceivable(summary.value.netReceivable),
          // Purchaser remaining credit after this purchase request is positive or zero
          validRemainingCredit: isPositiveOrZeroNumber(
            Number(purchaseRequestFlowState.setup.purchaser.availableCredit) - Number(summary.value.netSubtotal),
          ),
          productionPlan: purchaseRequestFlowState.productionPlan,
          summary: summary.value,
          timeline,
          salePriceType: purchaseRequestFlowState.setup.salePriceType,
          poFileUrl: null,
          deliveryInstructionDocs: null,
          collectedDate: null,
          deliveredDate: null,
          purchaserInvoicePdfUrl: null,
          availablePdfs: [],
        },
      };
    },
    [
      hasValidNetReceivable,
      org?.value.currency,
      purchaseRequestFlowState?.delivery,
      purchaseRequestFlowState?.setup,
      purchaseRequestFlowState?.productionPlan,
      service,
      createPurchaseRequestReq,
    ],
  );

  useEffect(() => {
    async function fetchPreviewData() {
      try {
        setPurchaseRequestData(await loadPurchaseRequestData());
      } catch (e: unknown) {
        setPurchaseRequestData({ state: "error", error: e as Error });
      }
    }

    void fetchPreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = useCallback(() => {
    history.push(AppRoutes.OrganisationPurchaseRequestDeliveryOptions, purchaseRequestFlowState);
  }, [history, purchaseRequestFlowState]);

  const onConfirm = useCallback(
    async (advanceCashUponDelivery: boolean) => {
      if (createPurchaseRequestReq && purchaseRequestFlowState?.setup && purchaseRequestFlowState?.delivery) {
        try {
          let poFileName: string | undefined;
          if (purchaseRequestFlowState.setup.poFile) {
            const fileUploadResp = await UploadFile(
              isDevelopment,
              service,
              purchaseRequestFlowState.setup.poFile,
              "TradeSalesPO",
              authToken,
            );
            if (fileUploadResp.kind == "success") {
              poFileName = fileUploadResp.fileName;
            } else {
              throw new Error("Unable to upload purchase order file");
            }
          }
          const deliveryInstructionDocUploads = purchaseRequestFlowState.delivery.deliveryInstructionDocs?.map(
            async file => {
              const fileUploadResp = await UploadFile(isDevelopment, service, file, "GeneralFileUpload", authToken);
              if (fileUploadResp.kind == "success") {
                return {
                  url: fileUploadResp.fileName,
                  name: file.name,
                  mimeType: file.type,
                } as UploadedFile;
              } else {
                throw new Error("Unable to upload delivery info file");
              }
            },
          );
          const deliveryInstructionDocs = deliveryInstructionDocUploads
            ? await Promise.all(deliveryInstructionDocUploads)
            : undefined;

          const resp = await service.createPurchaseRequest({
            ...createPurchaseRequestReq,
            customerPoRef: purchaseRequestFlowState.delivery.customerPoRef ?? null,
            shipmentNumber: purchaseRequestFlowState.delivery.shipmentNumber ?? null,
            expiryDays: PURCHASE_REQUEST_DEFAULT_EXPIRY_DAYS,
            advanceCashUponDelivery,
            poFileName: poFileName ?? null,
            deliveryInstructionDocs: deliveryInstructionDocs ?? null,
          });
          if (resp.kind === "success") {
            // loads the confirmed information
            setPurchaseRequestData(await loadPurchaseRequestData(resp.value));
          } else {
            const error: Error = new Error("Unknown error");
            switch (resp.kind) {
              case "noLineItems":
                error.message = "Does not contain any valid line items";
                break;
              case "invalidQuantity":
                error.message = "Invalid quantities for the selected line items";
                break;
              case "insufficientCredit":
                error.message = "Insufficient credit";
                break;
              case "invalidCashAdvance":
                error.message = "Unable to advance cash upon delivery";
                break;
              case "negativeReceivableValue":
                error.message = "Invalid net receivable amount";
                break;
              case "invalidProductionOrder":
                error.message = "Invalid production order";
                break;
              case "error":
                error.message = resp.value;
                break;
              default:
            }
            throw error;
          }
        } catch (error: unknown) {
          void showAlert({
            severity: "error",
            title: "Error while creating purchase request",
            body: error instanceof Error ? error.message : String(error),
          });
          // Reload the unconfirmed data, preview results might have changed
          setPurchaseRequestData(await loadPurchaseRequestData());
        }
      }
    },
    [
      createPurchaseRequestReq,
      loadPurchaseRequestData,
      purchaseRequestFlowState?.delivery,
      purchaseRequestFlowState?.setup,
      service,
      showAlert,
      isDevelopment,
      authToken,
    ],
  );

  const onCreateAnother = useCallback(() => {
    const purchaser = purchaseRequestFlowState?.setup?.purchaser;
    if (purchaser) {
      const initialFlowState: PurchaseRequestFlowState = {
        setup: {
          bondedSale: true,
          salePriceType: "exDutyAndVat",
          purchaser: { ...purchaser },
          products: [],
          dealDiscountAmount: undefined,
        },
      };
      history.push(AppRoutes.OrganisationCreatePurchaseRequest, initialFlowState);
      return;
    }

    throw new Error("Invalid purchase request flow state");
  }, [history, purchaseRequestFlowState?.setup?.purchaser]);

  if (purchaseRequestFlowState?.setup === undefined || purchaseRequestFlowState?.delivery === undefined) {
    return <Redirect to={AppRoutes.Index} />;
  } else {
    return (
      <Loader loadingStates={[purchaseRequestData]} fullScreen>
        {isLoaded(purchaseRequestData) && (
          <OrganisationPurchaseRequestConfirmationPageView
            purchaseRequestData={purchaseRequestData.value}
            poFileAdded={!!purchaseRequestFlowState.setup.poFile}
            onCreateAnother={onCreateAnother}
            onBack={onBack}
            onConfirm={onConfirm}
          />
        )}
      </Loader>
    );
  }
};
