import {
  Alert,
  Box,
  Button,
  Grid,
  gridClasses,
  Stack,
  TableCell,
  TableCellProps,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InProgressNewDealRequestOverview } from "adl-gen/ferovinum/app/api";
import { Currency, NewDealRequestType } from "adl-gen/ferovinum/app/db";
import { NewDealRequestOrganisationView } from "adl-gen/ferovinum/app/procurement";
import { MonetaryValue } from "adl-gen/ferovinum/app/types";
import { InfoField, InfoFieldProps } from "components/widgets/info-field/info-field";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { Link } from "components/widgets/link/link";
import { NewDealRequestProgressStepper } from "components/widgets/new-deal-requests/new-deal-request-progress-stepper/new-deal-request-progress-stepper";
import React, { useState } from "react";
import { getNewStockNewDealRequestSupplier } from "utils/adl-utils";
import { stateCodeToNewDealRequestState } from "utils/conversion-utils";
import { formatInstant } from "utils/date-utils";
import { add, getVesselCapacity, isSinglesUnitType } from "utils/model-utils";
import { isLoaded, isLoading } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { ProductRequest } from "utils/hooks/use-new-deal-request-impl";
import { useNewDealRequest } from "../../../../../hooks/use-new-deal-request";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { useSettlementCurrency } from "../../../../layouts/portal-page-layout/portal-page";
import { ActionableSummaryCard } from "../../../../widgets/common/actionable-summary-card/actionable-summary-card";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import { OrganisationNewDealRequestSummaryTable } from "../../../../widgets/new-deal-requests/organisation-new-deal-request-summary-table/organisation-new-deal-request-summary-table";
import { ORGANISATION_STEPS_BY_STATE } from "../../../../widgets/new-deal-requests/procurement-stepper/organisation-new-deal-request-stepper";
import {
  OrganisationProductSummary,
  OrganisationProductSummaryProps,
} from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { ProvisionalCurrencyRenderer } from "components/widgets/currency-renderer/provisional-currency-renderer";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { TabbedPage } from "components/library/widgets/tabbed-page";
export interface OrganisationNewDealRequestsPageViewProps {
  inProgress: {
    overviews: InProgressNewDealRequestOverview[];
    actionablePurchaseOrders: NewDealRequestOrganisationView[];
    nonActionablePurchaseOrders: NewDealRequestOrganisationView[];
  };
  completedPurchaseOrders: NewDealRequestOrganisationView[];
  acknowledgeDealTerms: (purchaseOrderId: string, newStock: boolean, accept: boolean) => Promise<void>;
  markDepositAsPaid: (purchaseOrderId: string) => Promise<void>;
}

export const OrganisationNewDealRequestsPageView = ({
  inProgress,
  completedPurchaseOrders,
  ...callbacks
}: OrganisationNewDealRequestsPageViewProps) => {
  const hasInProgressPurchaseOrders =
    inProgress.actionablePurchaseOrders.length > 0 || inProgress.nonActionablePurchaseOrders.length > 0;
  const hasCompletedPurchaseOrders = completedPurchaseOrders.length > 0;
  return (
    <PortalPageContent header={<PortalPageContentHeader title="New Deal Requests" />}>
      <TabbedPage
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <Box>
                {hasInProgressPurchaseOrders ? (
                  <InProgressSection {...inProgress} {...callbacks} />
                ) : (
                  <Alert severity="info">You currently do not have any in progress new deal requests</Alert>
                )}
              </Box>
            ),
            toolbar: <CreateNewDealRequestButton />,
          },
          {
            label: "Completed",
            key: "completed",
            content: (
              <Box paddingTop={8}>
                {hasCompletedPurchaseOrders ? (
                  <CompletedSection purchaseOrders={completedPurchaseOrders} />
                ) : (
                  <Alert severity="info">You currently do not have any completed new deal requests order/s</Alert>
                )}
              </Box>
            ),
            toolbar: <CreateNewDealRequestButton />,
          },
        ]}
      />
    </PortalPageContent>
  );
};

const CreateNewDealRequestButton = () => <Button href={AppRoutes.CreateNewDealRequest}>Create new deal request</Button>;

interface InProgressToggleButtonProps {
  value: InProgressToggleType;
  onChange: (val: InProgressToggleType) => void;
  availableToggleTypes: Partial<Record<InProgressToggleType, boolean>>;
}
type InProgressToggleType = "Overview" | "Action required" | "No action required";
const InProgressToggleButton = ({ availableToggleTypes, value, onChange }: InProgressToggleButtonProps) => {
  const onChangeStockType = (_: React.MouseEvent<HTMLElement>, value: InProgressToggleType) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <Box>
      <ToggleButtonGroup value={value} exclusive onChange={onChangeStockType}>
        {Object.keys(availableToggleTypes).map(type => {
          // @ts-ignore
          const value: boolean = availableToggleTypes[type];
          return value ? (
            <ToggleButton key={type} value={type}>
              {type}
            </ToggleButton>
          ) : undefined;
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

interface InProgressSectionProps {
  overviews: InProgressNewDealRequestOverview[];
  actionablePurchaseOrders: NewDealRequestOrganisationView[];
  nonActionablePurchaseOrders: NewDealRequestOrganisationView[];
  acknowledgeDealTerms: (purchaseOrderId: string, newStock: boolean, accept: boolean) => Promise<void>;
  markDepositAsPaid: (purchaseOrderId: string) => Promise<void>;
}
const InProgressSection = ({
  overviews,
  actionablePurchaseOrders,
  nonActionablePurchaseOrders,
  ...callbacks
}: InProgressSectionProps) => {
  const [selectedToggle, setSelectedToggle] = useState<InProgressToggleType>("Overview");

  return (
    <Stack spacing={1}>
      <InProgressToggleButton
        value={selectedToggle}
        onChange={setSelectedToggle}
        availableToggleTypes={{
          "Overview": true,
          "Action required": actionablePurchaseOrders.length > 0,
          "No action required": nonActionablePurchaseOrders.length > 0,
        }}
      />
      {/* Note: Using display:none to avoid components re-mounting on toggle switch and causing unnecessary network requests */}
      <OverviewTable overviews={overviews} visible={selectedToggle === "Overview"} />
      <PurchaseOrdersList
        purchaseOrders={actionablePurchaseOrders}
        display={selectedToggle === "Action required"}
        initiallyExpanded={true}
        {...callbacks}
      />
      <PurchaseOrdersList
        purchaseOrders={nonActionablePurchaseOrders}
        display={selectedToggle === "No action required"}
        initiallyExpanded={false}
        {...callbacks}
      />
    </Stack>
  );
};

interface CompletedSectionProps {
  purchaseOrders: NewDealRequestOrganisationView[];
}
const CompletedSection = ({ purchaseOrders }: CompletedSectionProps) => {
  return (
    <PurchaseOrdersList
      purchaseOrders={purchaseOrders}
      display={true}
      acknowledgeDealTerms={async _ => {
        /** noop */
      }}
      markDepositAsPaid={async _ => {
        /** noop */
      }}
    />
  );
};

interface PurchaseOrdersListProps {
  purchaseOrders: NewDealRequestOrganisationView[];
  display: boolean;
  acknowledgeDealTerms: (purchaseOrderId: string, newStock: boolean, accept: boolean) => Promise<void>;
  markDepositAsPaid: (purchaseOrderId: string) => Promise<void>;
  initiallyExpanded?: boolean;
}
const PurchaseOrdersList = ({
  purchaseOrders,
  display,
  acknowledgeDealTerms,
  markDepositAsPaid,
  initiallyExpanded,
}: PurchaseOrdersListProps) => {
  return (
    <Grid container rowSpacing={8} display={display ? undefined : "none"}>
      {purchaseOrders.map((po, idx) => (
        <Grid
          key={po.newDealRequestId}
          item
          xs={12}
          sx={{ [`&.${gridClasses.item}`]: { pt: idx === 0 ? 0 : undefined } }}>
          <NewDealRequest
            newDealRequest={po}
            acknowledgeDealTerms={(acceptOrReject: boolean) =>
              acknowledgeDealTerms(
                po.newDealRequestId,
                po.newDealRequestType.kind === "newStockNewDealRequest",
                acceptOrReject,
              )
            }
            markDepositAsPaid={() => markDepositAsPaid(po.newDealRequestId)}
            initiallyExpanded={initiallyExpanded}
          />
        </Grid>
      ))}
    </Grid>
  );
};

interface NewDealRequestProps {
  newDealRequest: NewDealRequestOrganisationView;
  acknowledgeDealTerms: (accept: boolean) => Promise<void>;
  markDepositAsPaid: () => Promise<void>;
  initiallyExpanded?: boolean;
}
const NewDealRequest = ({ newDealRequest, initiallyExpanded, ...actions }: NewDealRequestProps) => {
  const { newDealRequest: details, refresh: loadNewDealRequest } = useNewDealRequest(newDealRequest.newDealRequestId, {
    lazyLoad: !initiallyExpanded,
  });
  const { currentState, newDealRequestType } = newDealRequest;
  const variant: NewDealRequestType =
    newDealRequestType.kind === "newStockNewDealRequest" ? "newStock" : "existingStock";
  const isActionable = currentState === "feroAccepted" || currentState === "depositInvoiced";

  return (
    <ActionableSummaryCard
      title={newDealRequest.dealNumber}
      titleHref={`${AppRoutes.OrganisationNewDealRequestDetails}/${newDealRequest.newDealRequestId}`}
      subtitle={
        newDealRequest.stockAdjustmentDetails ? (
          <Stack spacing={1} direction="row">
            <Typography variant="body2" color="common.grey6" noWrap>
              Positive stock adjustment
            </Typography>

            <InfoTooltip title={`Extra stock was found at ${newDealRequest.storageLocationName}`} />
          </Stack>
        ) : newDealRequestType.kind === "newStockNewDealRequest" ? (
          "New stock"
        ) : (
          "Existing stock"
        )
      }
      expandText="Products in this deal"
      isInitiallyExpanded={initiallyExpanded ? initiallyExpanded && isLoaded(details) : false}
      isLoading={isLoading(details)}
      onExpandStateChange={expanded => {
        if (expanded && !isLoaded(details)) {
          loadNewDealRequest();
        }
      }}
      headerContent={<PurchaseOrderHeader purchaseOrder={newDealRequest} />}
      cardContent={
        isLoaded(details) ? (
          isActionable ? (
            <OrganisationNewDealRequestSummaryTable
              {...details.value}
              variantTotalsDetails={{
                kind: variant,
                netTotalDepositAmount: details.value.netTotalDepositAmount,
                totalDiscountAmount: details.value.totalDiscountAmount,
                grossTotalDepositAmount: details.value.grossTotalDepositAmount,
                totalNetAdvanceAmount: details.value.totalNetAdvanceAmount,
              }}
              actionDetails={{
                ...actions,
                currentState: currentState,
                dealTerms: details.value.dealTermsView,
              }}
            />
          ) : (
            <NewDealRequestBasicTable
              variant={variant}
              products={details.value.products}
              purchaseCurrency={details.value.purchaseCurrency}
              netTotalDepositAmount={details.value.netTotalDepositAmount}
              netAdvanceAmount={details.value.totalNetAdvanceAmount}
            />
          )
        ) : (
          <></>
        )
      }
    />
  );
};

interface PurchaseOrderHeaderProps {
  purchaseOrder: NewDealRequestOrganisationView;
}
const PurchaseOrderHeader = ({ purchaseOrder }: PurchaseOrderHeaderProps) => {
  const {
    currentState,
    newDealRequestType: { kind: variant },
    createdAt,
    newDealRequestEvents,
  } = purchaseOrder;
  const lastEventTime = [...newDealRequestEvents].sort((a, b) => b.time - a.time)[0]?.time ?? 0;
  const commonInfoFieldProps: Partial<InfoFieldProps> = {
    sx: { alignItems: "flex-end" },
    noWrap: true,
  };
  return (
    <>
      <Stack direction="row" spacing={7}>
        <InfoField {...commonInfoFieldProps} label="Created on" value={formatInstant(createdAt)} />
        <InfoField
          {...commonInfoFieldProps}
          label={variant === "newStockNewDealRequest" ? "Supplier" : "Storage location"}
          value={
            purchaseOrder.newDealRequestType.kind === "newStockNewDealRequest"
              ? getNewStockNewDealRequestSupplier(purchaseOrder.newDealRequestType.value).name
              : purchaseOrder.storageLocationName
          }
        />
      </Stack>
      <Typography color="common.grey6" sx={{ alignItems: "flex-end" }} variant="subtitle1Bold">
        {`${ORGANISATION_STEPS_BY_STATE[currentState].label} on ${formatInstant(lastEventTime)}`}
      </Typography>
    </>
  );
};

interface NewDealRequestBasicTableProps {
  variant: NewDealRequestType;
  products: ProductRequest[];
  purchaseCurrency: Currency;
  netTotalDepositAmount?: MonetaryValue;
  netAdvanceAmount?: MonetaryValue;
}
const NewDealRequestBasicTable = ({
  variant,
  products,
  purchaseCurrency,
  netTotalDepositAmount,
  netAdvanceAmount,
}: NewDealRequestBasicTableProps) => {
  return (
    <Stack>
      <PaginatedTable
        key={products.length}
        initialRows={products}
        totalRowCount={products.length}
        HeaderContent={<NewDealRequestBasicTableHeader variant={variant} />}
        BodyContent={<NewDealRequestBasicTableBody rows={products} purchaseCurrency={purchaseCurrency} />}
        BodyBaseline={<TotalRow total={variant === "newStock" ? netTotalDepositAmount : netAdvanceAmount} />}
      />
    </Stack>
  );
};

function NewDealRequestBasicTableHeader(props: { variant: NewDealRequestType }) {
  const { variant } = props;
  return (
    <TableRow>
      <TableCell>Product</TableCell>
      <BasicTableCell>Purchase price</BasicTableCell>
      <BasicTableCell>Deposit</BasicTableCell>
      <BasicTableCell>Quantity</BasicTableCell>
      <BasicTableCell>{variant === "newStock" ? "Deposit subtotal" : "Net advance amount"}</BasicTableCell>
    </TableRow>
  );
}

function NewDealRequestBasicTableBody(props: { rows: ProductRequest[]; purchaseCurrency: Currency }) {
  const { rows, purchaseCurrency } = props;
  return (
    <>
      {rows.map(productReq => (
        <NewDealRequestBasicTableRow
          key={productReq.product.id}
          productReq={productReq}
          purchaseCurrency={purchaseCurrency}
        />
      ))}
    </>
  );
}

const TotalRow = ({ total }: { total?: MonetaryValue }) => {
  const settlementCurrency = useSettlementCurrency();
  return (
    <TableRow sx={{ backgroundColor: "common.grey1" }}>
      <BasicTableCell colSpan={3} />
      <TableCell align="right">
        <Typography variant="subtitle1Bold">Total</Typography>
      </TableCell>
      <BasicTableCell>
        <CurrencyRenderer
          maximumFractionDigits={4}
          variant="subtitle1Bold"
          currency={settlementCurrency}
          value={total}
          emptyValue="-"
        />
      </BasicTableCell>
    </TableRow>
  );
};

interface NewDealRequestBasicTableRowProps {
  productReq: ProductRequest;
  purchaseCurrency: Currency;
}
const NewDealRequestBasicTableRow = ({ productReq, purchaseCurrency }: NewDealRequestBasicTableRowProps) => {
  const {
    purchasePrice,
    depositPrice,
    provisionalPurchasePrice,
    numberOfUnits,
    numberOfFreeUnits,
    product,
    grossPayableAmount,
  } = productReq;
  const totalNumberOfUnits = add(numberOfUnits, numberOfFreeUnits);
  const summaryProps: OrganisationProductSummaryProps = {
    ...product.value,
    productId: product.id,
    vesselCapacity: getVesselCapacity(product.value.vesselSize, totalNumberOfUnits),
  };
  const settlementCurrency = useSettlementCurrency();

  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary {...summaryProps} />
      </TableCell>
      <BasicTableCell>
        <ProvisionalCurrencyRenderer
          base={{
            value: purchasePrice,
            currency: purchaseCurrency,
          }}
          provisional={{
            value: provisionalPurchasePrice,
            currency: settlementCurrency,
          }}
          maximumFractionDigits={4}
        />
      </BasicTableCell>
      <BasicTableCell>
        <CurrencyRenderer maximumFractionDigits={4} currency={settlementCurrency} value={depositPrice} emptyValue="-" />
      </BasicTableCell>
      <BasicTableCell>{isSinglesUnitType(product.value.unitType) ? totalNumberOfUnits.value : 1}</BasicTableCell>
      <BasicTableCell>
        <CurrencyRenderer
          maximumFractionDigits={4}
          currency={settlementCurrency}
          value={grossPayableAmount}
          emptyValue="-"
        />
      </BasicTableCell>
    </TableRow>
  );
};

const BasicTableCell = styled(TableCell)({
  textAlign: "right",
});

function OverviewTable(props: { overviews: InProgressNewDealRequestOverview[]; visible: boolean }) {
  const { overviews, visible } = props;

  return (
    <PaginatedTable
      key={overviews.length}
      sx={{ display: visible ? undefined : "none" }}
      initialRows={overviews}
      totalRowCount={overviews.length}
      HeaderContent={<OverviewTableHeader />}
      BodyContent={<OverviewTableBody rows={overviews} />}
    />
  );
}

const SmallCellProps: TableCellProps = { align: "center", sx: { width: "10%" } };

function OverviewTableHeader() {
  return (
    <TableRow>
      <TableCell {...SmallCellProps}>Order Number</TableCell>
      <TableCell {...SmallCellProps}>Details</TableCell>
      <TableCell align="center">Progress</TableCell>
    </TableRow>
  );
}

function OverviewTableBody(props: { rows: InProgressNewDealRequestOverview[] }) {
  const { rows } = props;
  return (
    <>
      {rows.map(overview => {
        const { newDealRequestId, dealNumber, overviewType } = overview;
        return (
          <TableRow key={newDealRequestId}>
            <TableCell {...SmallCellProps}>
              <Link href={`${AppRoutes.OrganisationNewDealRequestDetails}/${newDealRequestId}`}>{dealNumber}</Link>
            </TableCell>
            <TableCell {...SmallCellProps}>
              {overviewType.kind === "newStock" ? (
                <Stack>
                  <Typography>New Stock from</Typography>
                  <Typography>{overviewType.value.supplierName}</Typography>
                </Stack>
              ) : overviewType.value.stockAdjustmentStorageLocationName ? (
                <Stack>
                  <Typography>Positive stock adjustment from</Typography>
                  <Typography>{overviewType.value.stockAdjustmentStorageLocationName}</Typography>
                </Stack>
              ) : (
                <Typography>Existing Stock Upload</Typography>
              )}
            </TableCell>
            <TableCell sx={{ paddingY: 4 }}>
              <NewDealRequestProgressStepper
                currentState={stateCodeToNewDealRequestState(overviewType.value.currentState)}
                lastStateSet={overviewType.value.currentStateSet}
                upcomingStateDeadline={
                  overviewType.kind === "newStock" ? overviewType.value.upcomingStateEta ?? undefined : undefined
                }
                variant={overviewType.kind}
                actionRequiredStateCode="ORGACCEPTED"
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
