import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";

export enum DateFieldType {
  Date = "date",
  TimeEarliest = "timeEarliest",
  TimeLatest = "timeLatest",
}
export type DateField = DateFieldType;

export interface DateAndTimeRangeProps {
  label?: string;
  name: string;
  minDate: Date;
  maxDate: Date;
  minDeliveryWindowInMinutes: number;
  dateInputFormat?: string;
  timeInputFormat?: string;
  handleDateChange: (value: Date, field: DateField) => void;
  formLabels: {
    date: string;
    timeEarliest: string;
    timeLatest: string;
  };
  values: {
    date: Date | null;
    timeEarliest: Date | null;
    timeLatest: Date | null;
  };
  errors: {
    date?: string;
    timeEarliest?: string;
    timeLatest?: string;
  };
}

function formatDeliveryWindowTimesInMinutes(minutes: number): string {
  return minutes >= 60 ? `${Math.floor(minutes / 60)}h ${minutes % 60 !== 0 ? `${minutes % 60}m` : ""}` : `${minutes}m`;
}

const timeOptionsInMinutes = [
  // sub 1 hour in 15 minute increments
  ...[15, 30, 45],
  // 1 hour to 12 hours in 30 minute increments
  ...Array.from({ length: 23 }, (_, i) => 60 + i * 30),
];

export const DateAndTimeRange = ({
  label,
  name,
  minDate,
  maxDate,
  minDeliveryWindowInMinutes,
  dateInputFormat = "dd/MM/yyyy",
  timeInputFormat = "HH:mm",
  handleDateChange,
  formLabels,
  values,
  errors,
}: DateAndTimeRangeProps) => {
  const { initWindowMinutes, timeOptions } = useMemo(() => {
    const timeOptions = timeOptionsInMinutes.filter(option => option >= minDeliveryWindowInMinutes);
    const { timeLatest, timeEarliest } = values;
    if (timeLatest && timeEarliest) {
      const timeWindowMinutes = (timeLatest.getTime() - timeEarliest.getTime()) / 60000;
      const closestTimeOption = timeOptions.findLast(option => option <= timeWindowMinutes);
      if (closestTimeOption) {
        return { initWindowMinutes: closestTimeOption, timeOptions };
      }
    }
    return { initWindowMinutes: minDeliveryWindowInMinutes, timeOptions };
  }, [values, minDeliveryWindowInMinutes]);

  const [selectedWindowMinutes, setSelectedWindowMinutes] = useState(initWindowMinutes);

  // NOTE (Dylan): The the error messages are not being displayed as the controls aren't spaced well enough to show them.
  // Spacing them out would make the form look bad. So I'm leaving them out for now.
  return (
    <Stack spacing={2}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <DatePicker
        label={formLabels.date}
        format={dateInputFormat}
        value={values.date}
        onChange={(value: Date | null) => {
          if (value !== null) {
            handleDateChange(value, DateFieldType.Date);
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        slots={{ textField: TextField }}
        slotProps={{
          textField: {
            fullWidth: true,
            name: `${name}.${DateFieldType.Date}`,
            error: Boolean(errors.date),
          },
        }}
      />
      <Stack direction={"row"} spacing={2}>
        <TimePicker
          label={formLabels.timeEarliest}
          value={values.timeEarliest}
          format={timeInputFormat}
          onChange={(value: Date | null) => {
            if (value !== null) {
              handleDateChange(value, DateFieldType.TimeEarliest);
              handleDateChange(addMinutes(value, selectedWindowMinutes), DateFieldType.TimeLatest);
            }
          }}
          slots={{ textField: TextField }}
          slotProps={{
            textField: {
              fullWidth: true,
              name: `${name}.${DateFieldType.TimeEarliest}`,
              error: Boolean(errors.timeEarliest),
            },
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="time-window-label">{formLabels.timeLatest}</InputLabel>
          <Select
            labelId="time-window-label"
            label={formLabels.timeLatest}
            value={selectedWindowMinutes}
            onChange={event => {
              const selectedMinutes = Number(event.target.value);
              setSelectedWindowMinutes(selectedMinutes);
              if (values.timeEarliest) {
                handleDateChange(addMinutes(values.timeEarliest, selectedMinutes), DateFieldType.TimeLatest);
              }
            }}
            error={Boolean(errors.timeLatest)}>
            {timeOptions.map(minutes => (
              <MenuItem key={minutes} value={minutes}>
                {formatDeliveryWindowTimesInMinutes(minutes)}
              </MenuItem>
            ))}
          </Select>
          {minDeliveryWindowInMinutes !== 15 && (
            <FormHelperText>
              {`Carrier requires a minimum ${formatDeliveryWindowTimesInMinutes(
                minDeliveryWindowInMinutes,
              )} delivery window`}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </Stack>
  );
};

function addMinutes(date: Date, minutes: number): Date {
  const newDate = new Date(date.getTime());
  newDate.setMinutes(date.getMinutes() + minutes);
  return newDate;
}
