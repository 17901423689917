import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, IconButton, InputAdornment, Stack, TextField, Typography, SvgIconProps, Box } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridComparatorFn,
  GridColDef,
  GridFilterOperator,
  GridRenderCellParams,
  GridSortCellParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { UnitType } from "adl-gen/ferovinum/app/db";
import React, { useMemo } from "react";
import { assertNever } from "utils/hx/util/types";
import { KeyByType } from "utils/utility-types";
import { FormField } from "../../types/formik-types";
import { Link } from "../link/link";
import { ProductSummary, ProductSummaryProps } from "../product-summary/product-summary";
import { useState } from "react";

type TypedGridComparatorFn<V> = (
  v1: V,
  v2: V,
  cellParams1: GridSortCellParams,
  cellParams2: GridSortCellParams,
) => number;

interface ColumnDefBase<V> {
  key: string;
  label: string;
  editable: boolean;
  flex?: number;
  sortComparator?: TypedGridComparatorFn<V>;
  filterOperators?: GridFilterOperator[];
  align?: "left" | "center" | "right";
}

type EditableColumnDef<RowType extends GridValidRowModel, V> = ColumnDefBase<V> & {
  editable: true;
  renderCell?: (params: GridRenderCellParams<RowType, V> & { formField: FormField<V> }) => React.ReactNode | string;
};

type ReadonlyColumnDef<RowType extends GridValidRowModel, V> = ColumnDefBase<V> & {
  editable: false;
  renderCell?: (params: GridRenderCellParams<RowType, V>) => React.ReactNode | string;
};

type SpecialColumnDef<RowType extends object> = {
  specialColumnType: "remove";
  header?: string;
  match: (r1: RowType, r2: RowType) => boolean;
};
function isSpecialColumnDef<RowType extends object, V>(
  columnDef: ColumnDef<RowType, V>,
): columnDef is SpecialColumnDef<RowType> {
  return (columnDef as SpecialColumnDef<RowType>).specialColumnType !== undefined;
}

export type ColumnDef<RowType extends object, V> =
  | EditableColumnDef<RowType, V>
  | ReadonlyColumnDef<RowType, V>
  | SpecialColumnDef<RowType>;

export interface NumberOfUnitsValue {
  unitType: UnitType;
  numUnits: number;
  maxUnits: number;
}

type ColumnKeyOrGetter<RowType, K extends keyof RowType, T> =
  | { key: K }
  | { name: string; getter: (row: RowType) => T };

type NumberInputParams = { range?: { min?: number; max?: number } };

function hasKey<RowType extends object, V>(columnDef: ColumnDef<RowType, V>): columnDef is ColumnDefBase<V> {
  return (columnDef as ColumnDefBase<V>).key !== undefined;
}

function getRow<RowType>(params: { id: any; api: any }): RowType {
  return params.api.getRow(params.id) as RowType;
}

export class GridTableColumnFactory<RowType extends object> {
  makeTextColumn<K extends KeyByType<RowType, string>>(
    params: { header: string } & ColumnKeyOrGetter<RowType, K, string>,
  ): ColumnDef<RowType, string> {
    if ("key" in params) {
      return {
        key: String(params.key),
        label: params.header,
        editable: false,
      };
    } else {
      return {
        key: params.name,
        label: params.header,
        editable: false,
        renderCell: cellParams => {
          const row = getRow<RowType>(cellParams);
          return params.getter(row);
        },
      };
    }
  }

  makeHyperLinkColumn<K extends KeyByType<RowType, string>>(
    params: {
      header: string;
      hrefGetter: (row: RowType) => string;
      icon?: React.FC<SvgIconProps>;
    } & ColumnKeyOrGetter<RowType, K, string>,
  ): ColumnDef<RowType, string> {
    if ("key" in params) {
      return {
        key: String(params.key),
        label: params.header,
        editable: false,
      };
    } else {
      return {
        key: params.name,
        label: params.header,
        editable: false,
        renderCell: ({ row }) => (
          <Link href={params.hrefGetter(row)} display="flex" alignItems="center">
            {params.getter(row)}
            {params.icon && React.createElement(params.icon, { fontSize: "small", style: { marginLeft: 4 } })}
          </Link>
        ),
      };
    }
  }

  makeProductSummaryColumn<K extends KeyByType<RowType, ProductSummaryProps>>(
    params: { header?: string } & ColumnKeyOrGetter<RowType, K, ProductSummaryProps>,
  ): ColumnDef<RowType, ProductSummaryProps> {
    if ("key" in params) {
      return {
        key: String(params.key),
        label: params.header ?? "Product",
        editable: false,
        flex: 2,
        renderCell: cellParams => {
          const row = getRow<RowType>(cellParams);
          const productSummaryProps = row[params.key] as ProductSummaryProps;
          return (
            <Stack p={1}>
              <ProductSummary {...productSummaryProps} />
            </Stack>
          );
        },
        sortComparator: (v1, v2) => v1.code.localeCompare(v2.code),
      };
    } else {
      return {
        key: params.name,
        label: params.header ?? "Product",
        editable: false,
        flex: 2,
        renderCell: cellParams => {
          const row = getRow<RowType>(cellParams);
          return (
            <Stack p={1}>
              <ProductSummary {...params.getter(row)} />
            </Stack>
          );
        },
        sortComparator: (v1, v2) => v1.code.localeCompare(v2.code),
      };
    }
  }

  makeCheckboxColumn<K extends KeyByType<RowType, boolean>>(params: {
    key: K;
    header?: string;
    getter: (row: RowType) => boolean;
    onChange: (row: RowType, checked: boolean) => void;
  }): ColumnDef<RowType, boolean> {
    return {
      key: String(params.key),
      label: params.header ?? "",
      editable: true,
      flex: 0.25,
      renderCell: cellParams => {
        const row = getRow<RowType>(cellParams);
        return <Checkbox checked={params.getter(row)} onChange={(_e, checked) => params.onChange(row, checked)} />;
      },
    };
  }

  makeRemovalColumn(params: {
    header?: string;
    match: (r1: RowType, r2: RowType) => boolean;
  }): ColumnDef<RowType, boolean> {
    return { specialColumnType: "remove", header: params.header, match: params.match } as SpecialColumnDef<RowType>;
  }

  makeEditableNumberColumn<K extends KeyByType<RowType, number>>(params: {
    key: K;
    header?: string;
    displayFormatter?: (row: RowType) => string;
    editableCheck?: (row: RowType) => boolean;
    endAdornmentTextProvider?: (row: RowType) => string;
    inputParams?: ((row: RowType) => NumberInputParams) | NumberInputParams;
  }): ColumnDef<RowType, number> {
    const { inputParams, displayFormatter, editableCheck, endAdornmentTextProvider } = params;
    return {
      key: String(params.key),
      label: params.header ?? "Number of units",
      editable: true,
      renderCell: this.makeRenderNumberOfUnitsCell(
        displayFormatter,
        editableCheck,
        endAdornmentTextProvider,
        inputParams,
      ),
    };
  }

  makeNumberColumn<K extends KeyByType<RowType, number>>(params: {
    key: K;
    header?: string;
    displayFormatter?: (row: RowType) => React.ReactNode;
  }): ColumnDef<RowType, number> {
    const { displayFormatter } = params;
    return {
      key: String(params.key),
      label: params.header ?? "Number of units",
      editable: false,
      renderCell: params => {
        const row = getRow<RowType>(params);
        return displayFormatter?.(row) ?? row[params.field as keyof RowType];
      },
    };
  }

  makeDateColumn<K extends KeyByType<RowType, string | Date>>(params: {
    key: K;
    header?: string;
    displayFormatter?: (row: RowType) => React.ReactNode;
  }): ColumnDef<RowType, Date> {
    const { displayFormatter } = params;

    const dateComparator: GridComparatorFn = (v1, v2) => {
      const date1 = new Date(v1 as string | number | Date);
      const date2 = new Date(v2 as string | number | Date);

      if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return 0;

      return date1.getTime() - date2.getTime();
    };

    return {
      key: String(params.key),
      label: params.header ?? "Date",
      editable: false,
      renderCell: params => {
        const row = getRow<RowType>(params);
        const fieldValue = row[params.field as keyof RowType];
        return displayFormatter ? displayFormatter(row) : fieldValue;
      },
      sortComparator: dateComparator,
    };
  }

  makeProgressStepperColumn<K extends KeyByType<RowType, React.ReactNode>>(params: {
    key: K;
    header?: string;
    flex?: number;
    align?: "left" | "center" | "right";
    sortComparator?: GridComparatorFn;
  }): ColumnDef<RowType, React.ReactNode> {
    return {
      key: String(params.key),
      label: params.header ?? "Widget",
      editable: false,
      align: params.align ?? "left",
      flex: params.flex ?? 1,
      renderCell: ({ row }) => (
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {row[params.key]}
        </Box>
      ),
      sortComparator: params.sortComparator,
    };
  }

  private makeRenderNumberOfUnitsCell(
    displayFormatter?: (row: RowType) => string,
    editableCheck?: (row: RowType) => boolean,
    endAdornmentTextProvider?: (row: RowType) => string,
    inputParams?: ((row: RowType) => NumberInputParams) | NumberInputParams,
  ): EditableColumnDef<RowType, number>["renderCell"] {
    const renderer: EditableColumnDef<RowType, number>["renderCell"] = params => {
      const row = getRow<RowType>(params);
      const value = params.value;
      /// Only able to partially move singles type of stock
      if (!editableCheck?.(row)) {
        return displayFormatter?.(row);
      }

      const inputParamsValues: NumberInputParams | undefined =
        typeof inputParams === "function" ? inputParams(row) : inputParams;

      return (
        <TextField
          value={value}
          onBlur={_e => params.api.stopCellEditMode({ id: params.id, field: params.field })}
          name={params.field}
          onChange={e => {
            //Prevent the input from being outside the range
            if (inputParamsValues?.range) {
              const value = Number(e.target.value);
              if (inputParamsValues.range.min && value < inputParamsValues.range.min) {
                return;
              } else if (inputParamsValues.range.max && value > inputParamsValues.range.max) {
                return;
              }
            }
            params.api.setEditCellValue({ id: params.id, field: params.field, value });
          }}
          type="number"
          InputProps={{
            inputProps: { ...inputParamsValues?.range },
            endAdornment: <InputAdornment position="end">{endAdornmentTextProvider?.(row)}</InputAdornment>,
          }}
          error={params.formField.hasError(true)}
          sx={{ paddingY: 1 }}
        />
      );
    };
    return renderer;
  }
}

const makeHeightProps = (tableHeight?: number | "auto") => ({
  grid: tableHeight === "auto" ? { autoHeight: true } : {},
  container: tableHeight === "auto" ? {} : { minHeight: 300, height: tableHeight },
});

export function GridTable<InputRowType extends object, SupportRowType extends object = object>(
  props: {
    columns: ColumnDef<InputRowType & SupportRowType, any>[];
    inputRowsField: FormField<InputRowType[]> | InputRowType[];
    tableHeight?: number | "auto";
    sortModel?: GridSortModel;
    onSortModelChange?: (sortModel: GridSortModel) => void;
    disabled?: boolean;
    searchField?: boolean;
    rowHeight?: number;
  } & Pick<DataGridProps, "sx">,
) {
  const { columns, inputRowsField, tableHeight, sortModel, onSortModelChange, sx, searchField, rowHeight } = props;
  const displayOnly = !(inputRowsField instanceof FormField);
  const [searchText, setSearchText] = useState("");
  const gridColumns: GridColDef[] = useMemo(() => {
    return columns.map(c => {
      const colDef = processColumnDef(c, inputRowsField);
      const col: GridColDef = {
        field: String(colDef.key),
        headerName: colDef.label,
        editable: false,
        flex: colDef.flex ?? 1,
        align: colDef.align,
      };
      if (!colDef.editable) {
        const renderCell = colDef.renderCell;
        col.renderCell = renderCell;
      } else if (!displayOnly) {
        const renderCell = colDef.renderCell;
        col.renderCell =
          renderCell &&
          (params => {
            const idx = Number(params.id);
            const formField = inputRowsField.getSubField([idx, colDef.key]);
            return renderCell({ ...params, formField });
          });
      } else {
        throw new Error("Editable columns are not supported in display only mode");
      }
      if (colDef.sortComparator) {
        col.sortComparator = colDef.sortComparator as GridComparatorFn;
      }
      if (colDef.filterOperators) {
        col.filterOperators = colDef.filterOperators;
      }
      return col;
    });
  }, [columns, displayOnly, inputRowsField]);

  const rows = useMemo(() => {
    const allRows = displayOnly ? inputRowsField : inputRowsField.get() ?? [];
    if (!searchText) {
      return allRows;
    }
    return allRows.filter(row => {
      return columns.some(col => {
        if (hasKey(col)) {
          const key = col.key as keyof InputRowType & keyof SupportRowType;
          if (key in row) {
            const value = row[key as keyof typeof row];
            return String(value).toLowerCase().includes(searchText.toLowerCase());
          }
        }
        return false;
      });
    });
  }, [displayOnly, inputRowsField, searchText, columns]);

  const errorValue = Array.isArray(inputRowsField) ? undefined : inputRowsField.getError(true);
  // error is a string means it's related to the overal union field
  const tableError = typeof errorValue === "string" ? errorValue : undefined;

  const heightProps = useMemo(() => makeHeightProps(tableHeight), [tableHeight]);
  return (
    <Stack {...heightProps.container} flexGrow={1}>
      {searchField && (
        <Box mb={2}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <DataGrid
        rows={rows.map((row, idx) => ({ ...row, id: idx }))}
        columns={gridColumns}
        getRowHeight={() => null}
        rowHeight={rowHeight ?? 100}
        isRowSelectable={params => params.row.isSelectable}
        checkboxSelection={false}
        disableRowSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        {...heightProps.grid}
        sx={{
          "backgroundColor": "white",
          "& .MuiDataGrid-footerContainer": {
            borderTop: "1px solid #E2E8F0",
          },
          // Note (Nicole): Currently this is the only way to disable the cell selection outline
          // https://github.com/mui/mui-x/issues/2429#issuecomment-905492502
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "& .monthlyFee-edit-input.MuiDataGrid-cell--editing": {
            padding: 0,
            maxWidth: "unset !important",
            outline: "none !important",
            backgroundColor: "common.lightYellow",
          },
          ".MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
          },
          ...sx,
        }}
      />
      <Typography color="error">{tableError}</Typography>
    </Stack>
  );
}

function processColumnDef<RowType extends object, ExpandedRowType extends RowType, V>(
  columnDef: ColumnDef<ExpandedRowType, V>,
  formField: FormField<RowType[]> | RowType[],
): EditableColumnDef<ExpandedRowType, V> | ReadonlyColumnDef<ExpandedRowType, V> {
  if (isSpecialColumnDef(columnDef)) {
    switch (columnDef.specialColumnType) {
      case "remove": {
        return makeRemoveColumnDef(columnDef.header, row => {
          if (Array.isArray(formField)) {
            formField.splice(formField.indexOf(row), 1);
          } else {
            const rows = formField.get()?.filter(r => !columnDef.match(r as ExpandedRowType, row));
            formField.set(rows);
          }
        });
      }
      default:
        assertNever(columnDef.specialColumnType);
    }
  }
  return columnDef;
}

function makeRemoveColumnDef<RowType extends object, V>(
  label: string | undefined,
  onRemove: (row: any) => void,
): ReadonlyColumnDef<RowType, V> {
  return {
    key: "remove",
    label: label ?? "",
    editable: false,
    flex: 0.25,
    renderCell: ({ row }) => (
      <IconButton
        onClick={() => onRemove(row)}
        size="small"
        sx={{
          color: "common.grey5",
        }}>
        <CloseIcon />
      </IconButton>
    ),
  };
}
