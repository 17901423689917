import { StorageLocationId, Product, UnitType } from "adl-gen/ferovinum/app/db";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { Loader } from "components/widgets/loader/loader";
import { Button, Box, Divider, Stack, styled, Typography } from "@mui/material";
import { unitTypeToString } from "utils/conversion-utils";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { useInfoDrawer } from "../../layouts/info-drawer/info-drawer";
import {
  AllWarehousesStock,
  isPendingStockLineItem,
  TotalsPerStorageLocationId,
  TransactionHistoryByYear,
  TransactionHistoryLineItem,
  TransactionHistoryLineItems,
} from "./product-movement";
import { format } from "date-fns";
import { Instant } from "adl-gen/common";
import { Link } from "components/widgets/link/link";
import { assertNever } from "assert-never";
import { formatInstant } from "utils/date-utils";
import { useRefDimensions } from "utils/hooks/use-ref-dimensions";
import { useUiConfig } from "../../../../../../packages/adl-service/hooks/use-ui-config";
import { AppRoutes } from "../../../app/app-routes";
import {
  add,
  formatNumberOfUnits,
  formatVesselSize,
  isSingles,
  isSinglesUnitType,
  numberOfUnitsForUnitType,
} from "utils/model-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";

export interface ProductMovementViewProps {
  product: LoadingValue<Product | undefined>;
  selectedWarehouse: StorageLocationId | null; // null => display stock in all warehouses
  warehousesStock: LoadingValue<AllWarehousesStock>;
  transactionHistory: LoadingValue<TransactionHistoryByYear>;
  onChangeStorageLocation: (loc: StorageLocationId | null) => void;
}
export const ProductMovementView = ({
  product,
  selectedWarehouse,
  warehousesStock,
  transactionHistory,
  onChangeStorageLocation,
}: ProductMovementViewProps) => {
  return (
    <Stack flex={1}>
      <Loader loadingStates={[product, warehousesStock, transactionHistory]}>
        {isLoaded(product) && isLoaded(warehousesStock) && product.value && (
          <>
            <TopBackgroundBox grey>
              <SectionContent>
                <ProductInfo product={product.value} />
              </SectionContent>
              <StretchedDivider />
              <SectionContent>
                <WarehouseInfo
                  onChangeStorageLocation={onChangeStorageLocation}
                  selectedWarehouse={selectedWarehouse}
                  warehousesTotals={warehousesStock.value.warehouseTotals}
                  unitType={product.value.unitType}
                />
              </SectionContent>
            </TopBackgroundBox>
            <SectionContent flex={1} noBottomPadding>
              {/* Avoid rendering until everything is loaded as the transaction history needs to know how much space is available */}
              {isLoaded(transactionHistory) && (
                <TransactionHistory
                  transactionHistory={
                    selectedWarehouse === null ? warehousesStock.value.transactionHistory : transactionHistory.value
                  }
                />
              )}
            </SectionContent>
          </>
        )}
      </Loader>
    </Stack>
  );
};

const ProductInfo = ({ product }: { product: Product }) => {
  const { code, name, productDate, producerName, unitType, productType, alcoholByVolumePc } = product;
  const year = productDate.kind === "vintageYear" ? productDate.value : null;
  const unitSizeStr = isSinglesUnitType(product.unitType)
    ? formatVesselSize({ vesselSize: product.vesselSize })
    : undefined;
  return (
    <Stack spacing={1}>
      <Typography variant="body2Bold" color="common.grey5">{`Product Code: ${code}`}</Typography>
      <Typography variant="h6">{`${year != null ? `${year} | ` : ""} ${name}`}</Typography>
      <Typography variant="body2" color="common.grey6">{`Produced by ${producerName}`}</Typography>
      {unitSizeStr && <Typography variant="body2" color="common.grey6">{`Unit size: ${unitSizeStr}`}</Typography>}
      <Typography variant="body2" color="common.grey6">{`Unit type: ${unitTypeToString(unitType)}`}</Typography>
      <Typography variant="body2" color="common.grey6">{`Product type: ${productType}`}</Typography>
      {year !== null && <Typography variant="body2" color="common.grey6">{`Vintage: ${year}`}</Typography>}
      {alcoholByVolumePc !== null && (
        <Typography variant="body2" color="common.grey6">{`Alcohol by volume: ${alcoholByVolumePc}%`}</Typography>
      )}
    </Stack>
  );
};

interface WarehouseInfoProps {
  selectedWarehouse: StorageLocationId | null;
  onChangeStorageLocation: (storageLoc: StorageLocationId | null) => void;
  warehousesTotals: TotalsPerStorageLocationId;
  unitType: UnitType;
}
const WarehouseInfo = ({
  selectedWarehouse,
  onChangeStorageLocation,
  warehousesTotals,
  unitType,
}: WarehouseInfoProps) => {
  const noStockInAnyStorageLocation = warehousesTotals.size === 0;
  const isSingleWarehouse = warehousesTotals.size === 1;

  const getDropdownMenuItems = useCallback(
    () => [...warehousesTotals.values()].map(w => ({ label: w.storageLocationName, value: w.storageLocationId })),
    [warehousesTotals],
  );

  const zero = useMemo(() => numberOfUnitsForUnitType(unitType, 0), [unitType]);

  const availableQty = useMemo(() => {
    if (selectedWarehouse) {
      return assertNotUndefined(warehousesTotals.get(selectedWarehouse)).totalAvailable;
    }
    return [...warehousesTotals.values()].reduce((sum, loc) => add(sum, loc.totalAvailable), zero);
  }, [selectedWarehouse, warehousesTotals, zero]);

  const pendingQty = useMemo(() => {
    if (selectedWarehouse) {
      return assertNotUndefined(warehousesTotals.get(selectedWarehouse)).totalPending;
    }
    return [...warehousesTotals.values()].reduce((sum, loc) => add(sum, loc.totalPending), zero);
  }, [selectedWarehouse, warehousesTotals, zero]);

  return (
    <Stack alignItems="center" spacing={3}>
      {isSingleWarehouse || noStockInAnyStorageLocation ? (
        <Typography variant="body2" color="common.grey6">
          {noStockInAnyStorageLocation
            ? `Not present in any storage location`
            : selectedWarehouse
            ? `Storage location: ${warehousesTotals.get(selectedWarehouse)?.storageLocationName}`
            : ""}
        </Typography>
      ) : (
        <Dropdown
          inputLabel="All Storage Locations"
          onChange={v => onChangeStorageLocation(v ?? null)}
          menuItems={getDropdownMenuItems()}
          defaultValue={
            selectedWarehouse
              ? {
                  label: assertNotUndefined(warehousesTotals.get(selectedWarehouse)).storageLocationName,
                  value: selectedWarehouse,
                }
              : { label: "", value: "" }
          }
          resettable
        />
      )}
      <Stack spacing={2} alignItems="center">
        {isSingles(availableQty) ? (
          <>
            <Typography variant="body2" color="common.grey6">
              Available
            </Typography>
            <Typography variant="h4" color="common.purple">
              {availableQty.value}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" color="common.grey6">
                Pending
              </Typography>
              <Typography variant="body2Bold">{pendingQty.value}</Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack spacing={0} textAlign="center">
              <Typography variant="body2" color="common.grey6">
                State
              </Typography>
              <Typography variant="subtitle1" color="common.purple" fontWeight="bold">
                {availableQty.value ? "Available for repurchase" : pendingQty.value ? "Pending" : "Repurchased"}
              </Typography>
            </Stack>
            <Stack spacing={0} textAlign="center">
              <Typography variant="body2" color="common.grey6">
                Current volume
              </Typography>
              <Typography variant="h4" color="common.purple">
                {formatNumberOfUnits(availableQty.value ? availableQty : pendingQty)}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const YEAR_HEADER_MAX_HEIGHT = 20;
const PENDING_LINE_ITEM_MAX_HEIGHT = 97;
const AVAILABLE_LINE_ITEM_MAX_HEIGHT = 84;
const READ_MORE_BUTTON_MAX_HEIGHT = 63;
function calculateDisplayedItems(availableSpace: number, transactionHistory: TransactionHistoryByYear) {
  let remainingSpace = availableSpace - READ_MORE_BUTTON_MAX_HEIGHT;
  let numItems = 0;
  for (const [, lineItems] of transactionHistory.entries()) {
    remainingSpace -= YEAR_HEADER_MAX_HEIGHT;
    if (remainingSpace <= PENDING_LINE_ITEM_MAX_HEIGHT) {
      break;
    }
    for (const lineItem of lineItems) {
      const minRequiredHeight = isPendingStockLineItem(lineItem)
        ? PENDING_LINE_ITEM_MAX_HEIGHT
        : AVAILABLE_LINE_ITEM_MAX_HEIGHT;
      if (remainingSpace >= minRequiredHeight) {
        remainingSpace -= minRequiredHeight;
        numItems++;
      } else {
        break;
      }
    }
  }
  return numItems;
}

interface TransactionHistoryProps {
  transactionHistory: TransactionHistoryByYear;
}
const TransactionHistory = ({ transactionHistory }: TransactionHistoryProps) => {
  const [, , expand, isExpanded] = useInfoDrawer();
  const transactionContainerRef = useRef<HTMLDivElement>(null);
  const { height: transactionContainerHeight } = useRefDimensions(transactionContainerRef);
  const [availableTransactionSpace, setAvailableTransactionSpace] = useState<number | undefined>(0);
  const totalTransactionLineItems = useMemo(
    () => [...transactionHistory.values()].flatMap(e => e).length,
    [transactionHistory],
  );

  const displayedQuantity = useMemo(() => {
    if (isExpanded) {
      return totalTransactionLineItems;
    }
    if (availableTransactionSpace) {
      return calculateDisplayedItems(availableTransactionSpace, transactionHistory);
    }
    return 0;
  }, [availableTransactionSpace, isExpanded, totalTransactionLineItems, transactionHistory]);

  useLayoutEffect(() => {
    if (isExpanded) {
      setAvailableTransactionSpace(undefined);
    }
    if (transactionContainerRef && transactionContainerRef.current) {
      setAvailableTransactionSpace(transactionContainerHeight);
    }
  }, [isExpanded, transactionContainerHeight]);

  const displayedHistory = useMemo(() => {
    if (isExpanded) {
      return transactionHistory;
    }
    let remainingItemsDisplayed = displayedQuantity;
    const partialHistory: TransactionHistoryByYear = new Map();
    for (const [year, lineItems] of transactionHistory.entries()) {
      if (remainingItemsDisplayed === 0) {
        break;
      }
      let selectedLineItems: TransactionHistoryLineItems = [];
      if (lineItems.length >= remainingItemsDisplayed) {
        selectedLineItems = lineItems.slice(0, remainingItemsDisplayed);
        remainingItemsDisplayed = 0;
      } else {
        selectedLineItems = [...lineItems];
        remainingItemsDisplayed -= lineItems.length;
      }
      partialHistory.set(year, selectedLineItems);
    }

    return partialHistory;
  }, [displayedQuantity, isExpanded, transactionHistory]);

  const readMoreQuantity = useMemo(
    () => (isExpanded ? 0 : totalTransactionLineItems - displayedQuantity),
    [displayedQuantity, isExpanded, totalTransactionLineItems],
  );

  return (
    <Stack spacing={2} justifyContent="space-between" height="100%">
      <Stack spacing={2} flex={1}>
        {displayedHistory.size > 0 && (
          <Typography variant="body2" color="common.grey6">
            Transaction history
          </Typography>
        )}
        <Stack flex={1} ref={transactionContainerRef}>
          {[...displayedHistory.entries()].map(([year, lineItems]) => (
            <TransactionHistoryYearlyEvents key={year} year={year} lineItems={lineItems} />
          ))}
        </Stack>
      </Stack>
      {readMoreQuantity > 0 && (
        <Box>
          <Button variant="outlined" onClick={expand}>
            {`Read more (${readMoreQuantity})`}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

interface TransactionHistoryYearlyEventsProps {
  year: number;
  lineItems: TransactionHistoryLineItems;
}
const TransactionHistoryYearlyEvents = ({ year, lineItems }: TransactionHistoryYearlyEventsProps) => {
  return (
    <Stack>
      <Divider />
      <Typography variant="caption" color="common.grey6">
        {year > 0 ? year : "Pending"}
      </Typography>
      <Divider />
      <Stack divider={<Divider flexItem />}>
        {lineItems.map((item, idx) => {
          const key = `${year}-${item.timestamp}-${idx}`;
          return <TransactionHistoryItem key={key} item={item} />;
        })}
      </Stack>
    </Stack>
  );
};

const SectionContent = styled(Box)<{ noBottomPadding?: boolean }>(({ theme, noBottomPadding }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: noBottomPadding ? 0 : theme.spacing(3),
}));

const StretchedDivider = styled(Divider)(({ theme }) => ({
  marginLeft: `-${theme.spacing(4)}`,
  marginRight: `-${theme.spacing(4)}`,
  color: theme.palette.common.grey3,
}));

const TopBackgroundBox = styled(Box, { shouldForwardProp: prop => prop !== "color" && prop !== "stretched" })<{
  grey?: boolean;
  stretched?: boolean;
}>(({ theme, grey, stretched }) => ({
  marginTop: `-${theme.spacing(11)}`,
  marginLeft: `-${theme.spacing(4)}`,
  marginRight: `-${theme.spacing(4)}`,
  backgroundColor: grey ? theme.palette.common.grey1 : theme.palette.common.white,
  ...(!stretched && { paddingTop: theme.spacing(9), paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }),
}));

function getMonth(instant: Instant): string {
  return format(instant, "LLL");
}
function getDay(instant: Instant): string {
  return format(instant, "d");
}
interface TransactionHistoryItemProps {
  item: TransactionHistoryLineItem;
}
const TransactionHistoryItem = ({ item }: TransactionHistoryItemProps) => {
  const { quantity: numberOfUnits, timestamp } = item;
  const day = timestamp ? getDay(timestamp).toString() : "-";
  const month = timestamp ? getMonth(timestamp).toString() : "";
  const [, closeDrawer] = useInfoDrawer();
  const { showProcurement, showNominatedPurchaser } = useUiConfig();

  const TransactionDescription = useMemo(() => {
    const itemType = item.lineItemType;
    const estimate = isPendingStockLineItem(item)
      ? item.availableTimestamp
        ? formatInstant(item.availableTimestamp)
        : undefined
      : undefined;

    switch (itemType.kind) {
      case "addedFromDealLeg":
        return (
          <>
            <Typography variant="body2Medium">Added from deal leg</Typography>
            <Typography variant="body2Medium" sx={{ wordBreak: "break-all" }}>
              {itemType.value.legIdentifier}
            </Typography>
          </>
        );
      case "resultOfProductionOrder":
        return (
          <>
            <Typography variant="body2Medium">Result of production order</Typography>
            <Link
              onClick={closeDrawer}
              href={`${AppRoutes.OrgProductionOrderDetails}/${itemType.value.productionOrderId}`}>
              {itemType.value.orderNumber}
            </Link>
            {estimate && <Typography variant="body2">{`Est. completion ${estimate}`}</Typography>}
          </>
        );
      case "productionOrderTaskRunLoss":
        return (
          <>
            <Typography variant="body2Medium">Loss from production order</Typography>
            <Link
              onClick={closeDrawer}
              href={`${AppRoutes.OrgProductionOrderDetails}/${itemType.value.productionOrderId}`}>
              {itemType.value.orderNumber}
            </Link>
          </>
        );
      case "allocatedToProductionOrder":
        return (
          <>
            <Typography variant="body2Medium">Reserved for production order</Typography>
            <Link
              onClick={closeDrawer}
              href={`${AppRoutes.OrgProductionOrderDetails}/${itemType.value.productionOrderId}`}>
              {itemType.value.orderNumber}
            </Link>
          </>
        );
      case "repurchased":
        return (
          <>
            <Typography variant="body2Medium">Repurchased via sale order</Typography>
            <Link onClick={closeDrawer} href={`${AppRoutes.SaleOrder}/${itemType.value.saleOrderId}`}>
              {itemType.value.invoiceNumber}
            </Link>
          </>
        );
      case "newDealRequest":
        return (
          <>
            <Typography variant="body2Medium">New stock from new deal request</Typography>
            {showProcurement ? (
              <Link
                onClick={closeDrawer}
                href={`${AppRoutes.OrganisationNewDealRequestDetails}/${itemType.value.newDealRequestId}`}>
                {itemType.value.dealNumber}
              </Link>
            ) : (
              <Typography variant={"body2"}>{itemType.value.newDealRequestId}</Typography>
            )}

            {estimate && <Typography variant="body2">{`Est. completion ${estimate}`}</Typography>}
          </>
        );
      case "allocatedToStockMovement":
        return <Typography variant="body2Medium">{`Moved to ${itemType.value.otherStorageLocationName}`}</Typography>;
      case "resultOfStockMovement":
        return <Typography variant="body2Medium">{`Moved from ${itemType.value.otherStorageLocationName}`}</Typography>;
      case "resultOfNewStockDelivery":
        return (
          <>
            <Typography variant="body2Medium">Stock from new deal request was delivered</Typography>
            {showProcurement ? (
              <Link
                onClick={closeDrawer}
                href={`${AppRoutes.OrganisationNewDealRequestDetails}/${itemType.value.newDealRequestId}`}>
                {itemType.value.dealNumber}
              </Link>
            ) : (
              <Typography variant={"body2"}>{itemType.value.newDealRequestId}</Typography>
            )}
          </>
        );
      case "resultOfExistingStockNewDealRequest":
        return (
          <>
            <Typography variant="body2Medium">Added via new deal request</Typography>
            {showProcurement ? (
              <Link
                onClick={closeDrawer}
                href={`${AppRoutes.OrganisationNewDealRequestDetails}/${itemType.value.newDealRequestId}`}>
                {itemType.value.dealNumber}
              </Link>
            ) : (
              <Typography variant={"body2"}>{itemType.value.newDealRequestId}</Typography>
            )}
          </>
        );
      case "allocatedToPurchaseRequest":
        return (
          <>
            <Typography variant="body2Medium">Reserved for third party sale order</Typography>
            {showNominatedPurchaser ? (
              <Link
                onClick={closeDrawer}
                href={`${AppRoutes.OrganisationPurchaseRequestDetails}/${itemType.value.purchaseRequestId}`}>
                {itemType.value.purchaseRequestNumber}
              </Link>
            ) : (
              <Typography variant={"body2"}>{itemType.value.purchaseRequestId}</Typography>
            )}
          </>
        );
      case "allocatedToNegativeStockAdjustment":
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2Medium">Negative stock adjustment</Typography>
            {itemType.value.reasonForStockAdjustment && <InfoTooltip title={itemType.value.reasonForStockAdjustment} />}
          </Stack>
        );
      default:
        return assertNever(itemType);
    }
  }, [closeDrawer, item, showNominatedPurchaser, showProcurement]);

  const isPending = isPendingStockLineItem(item);

  return (
    <Stack paddingTop={2} paddingBottom={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <CircleBackground color="common.grey6">
            <Typography variant="caption">{day}</Typography>
            <Typography variant="caption">{month}</Typography>
          </CircleBackground>
          <Stack>{TransactionDescription}</Stack>
        </Stack>
        <Stack justifyContent="space-between" alignItems="flex-end" height="100%">
          <Typography variant="h6" color={numberOfUnits.value < 0 ? "common.warning" : undefined}>
            {formatNumberOfUnits(numberOfUnits)}
          </Typography>
          {isPending && <Typography variant="body2">Pending</Typography>}
        </Stack>
      </Stack>
    </Stack>
  );
};

const CircleBackground = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.grey3,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "50px",
  minWidth: "50px",
  height: "50px",
}));
