import {
  UnitType,
  NumberOfUnits,
  makeVesselSize,
  VesselSize,
  CaseVessel,
  DealLegExpiryStageType,
  snDealLegExpiryStageType,
} from "adl-gen/ferovinum/app/db";
import { getFormLabelForUnionField, getTopLevelForUnitType } from "./adl-utils";
import { isValidNumber } from "./ts-utils";
import { LocalDate } from "adl-gen/common";
import { formatLocalDate } from "./date-utils";
import { correctFloatPrecision } from "./numeric-utils";

// Unit types
export function isCask(unitType?: UnitType | null): boolean {
  return unitType !== undefined && unitType !== null && getTopLevelForUnitType(unitType) === "cask";
}

export function isTank(unitType?: UnitType | null): boolean {
  return unitType !== undefined && unitType !== null && getTopLevelForUnitType(unitType) === "tank";
}

export function isBottle(unitType?: UnitType | null): boolean {
  return (
    unitType !== undefined && unitType !== null && !isCase(unitType) && getTopLevelForUnitType(unitType) === "bottles"
  );
}

export function isCan(unitType?: UnitType | null): boolean {
  return unitType !== undefined && unitType !== null && getTopLevelForUnitType(unitType) === "cans";
}

export function isCase(unitType?: UnitType | null): boolean {
  return unitType !== undefined && unitType !== null && unitType === "case";
}

export function isSinglesUnitType(unitType?: UnitType | null) {
  return isBottle(unitType) || isCan(unitType);
}

/**
 * Returns true if the given unit type can be sold in partial quantity (e.g. bottles, cans, case)
 * or false if it can only be sold as a whole unit (e.g. cask)
 * @param unitType
 */
export function canSellPartialQuantity(unitType?: UnitType | null) {
  return isBottle(unitType) || isCan(unitType) || isCase(unitType);
}
export function isFinishedProduct(unitType?: UnitType | null) {
  return unitType !== undefined && unitType !== null && ["bottles", "cans", "case"].includes(unitType);
}

/// Number of units related helpers
export function isSingles(numberOfUnits: NumberOfUnits): boolean {
  return numberOfUnits.kind === "singles";
}

export function singles(numberOfSingles: number): NumberOfUnits {
  return { kind: "singles", value: numberOfSingles };
}
export function litresOfPureAlcohol(lpa: number): NumberOfUnits {
  return { kind: "litresOfPureAlcohol", value: lpa };
}

export function hectolitres(hl: number): NumberOfUnits {
  return { kind: "hectolitres", value: hl };
}

export function convertCentilitresToHectolitres(centilitres: number): number {
  return centilitres / 10000;
}

export type BulkQuantityUnitType = Exclude<NumberOfUnits["kind"], "singles">;

export type TankUnit = "litresOfPureAlcohol" | "hectolitres";

export function numberOfUnitsForUnitType(
  unitType: UnitType,
  value: number,
  tankUnit?: BulkQuantityUnitType,
): NumberOfUnits {
  if (isCask(unitType)) {
    return litresOfPureAlcohol(value);
  }
  if (isTank(unitType)) {
    return { kind: tankUnit ?? "hectolitres", value };
  }
  if (isSinglesUnitType(unitType)) {
    return singles(value);
  }
  if (isCase(unitType)) {
    // case products are treated as single indivisible unit
    return singles(value);
  }
  throw new Error("Unsupported unit type");
}

// Number of units operations
export function subtract(numA: NumberOfUnits, numB: NumberOfUnits): NumberOfUnits {
  if (numA.kind !== numB.kind) {
    throw Error("Cannot subtract number of units in different measurements");
  }
  return { kind: numA.kind, value: numA.value - numB.value };
}

export function add(numA: NumberOfUnits, numB: NumberOfUnits): NumberOfUnits {
  if (numA.kind !== numB.kind) {
    throw Error("Cannot add number of units in different measurements");
  }
  return { kind: numA.kind, value: numA.value + numB.value };
}

export function minNumberOfUnits(numA: NumberOfUnits, numB: NumberOfUnits): NumberOfUnits {
  if (numA.kind !== numB.kind) {
    throw new Error(`Values ${JSON.stringify(numA)} and ${JSON.stringify(numB)} are of different kind`);
  }
  if (numA.value > numB.value) {
    return numB;
  }
  return numA;
}

export function negate(num: NumberOfUnits): NumberOfUnits {
  return { kind: num.kind, value: -num.value };
}

export function vesselSizeForUnitType(unitType: UnitType, value?: number | CaseVessel): VesselSize | null {
  if (isCaseVessel(value) && isCase(unitType)) {
    return caseVessel(value);
  }

  const topLevelUnitType = getTopLevelForUnitType(unitType);
  if (topLevelUnitType === "bottles" || topLevelUnitType === "cans") {
    if (!isValidNumber(value)) {
      throw new Error("Vessel size for bottles or cans must be a number");
    }

    return centilitresVessel(Number(value));
  }
  return null;
}

export function isCaseVessel(v: unknown): v is CaseVessel {
  return (
    typeof v === "object" &&
    v !== null &&
    Object.keys(v).length === 2 &&
    // @ts-ignore
    v["numberOfSingles"] &&
    // @ts-ignore
    v["centilitresPerSingle"]
  );
}

export function centilitresVessel(centilitres: number): VesselSize {
  return makeVesselSize("centilitres", centilitres);
}

export function caseVessel(value: CaseVessel): VesselSize {
  return makeVesselSize("case", value);
}

/// Because capacity for singles lives in the product and vessel capacity for casks / tanks lives in DealLeg
// we define this function to abstract this nuance from the frontend code as much as possible
export type VesselCapacity =
  | {
      kind: "centilitres" | NumberOfUnits["kind"];
      value: number;
      unitLabel: string;
    }
  | {
      kind: "case";
      value: CaseVessel;
      unitLabel: string;
    };
export function getVesselCapacity(vesselSize: VesselSize | null, size?: NumberOfUnits): VesselCapacity | undefined {
  if (vesselSize?.kind === "centilitres") {
    return {
      kind: vesselSize.kind,
      value: vesselSize.value,
      unitLabel: formatVesselUnitsLabel(vesselSize.kind),
    };
  }
  if (vesselSize?.kind === "case") {
    return {
      kind: vesselSize.kind,
      value: { ...vesselSize.value },
      unitLabel: formatVesselUnitsLabel(vesselSize.kind),
    };
  }
  if (size && size.kind !== "singles") {
    return { kind: size.kind, value: size.value, unitLabel: formatVesselUnitsLabel(size.kind) };
  }
}

/**
 * Returns the capacity of a single unit.
 * For example, for a case of 6 bottles of 75cl, the capacity of a single bottle is 75cl.
 * @param vesselSize The vessel size of the product
 */
export const vesselCapacityPerSingle = (vesselSize: VesselSize): number => {
  if (vesselSize.kind === "case") {
    return vesselSize.value.centilitresPerSingle;
  }
  return vesselSize.value;
};
// String formatters

/** returns a UI label with the unit used in the platform for the given unit type */
export function unitsLabelForUnitType(unitType?: UnitType | null, quantityUnits?: BulkQuantityUnitType): string {
  if (isCask(unitType)) {
    return "LPA";
  }
  if (isTank(unitType)) {
    return quantityUnits ? (quantityUnits === "hectolitres" ? "hL" : "LPA") : "hL";
  }
  if (isCase(unitType)) {
    return "cases";
  }
  if (isBottle(unitType)) {
    return "bottles";
  }
  if (isCan(unitType)) {
    return "cans";
  }
  return "";
}

/** returns a UI label with the metric used in the platform for the given unit type */
export function vesselSizeMetricForUnitType(unitType?: UnitType | null): string {
  if (isCask(unitType)) {
    return "LPA";
  }
  if (isTank(unitType)) {
    return "hL";
  }
  if (isBottle(unitType) || isCan(unitType) || isCase(unitType)) {
    return "cL";
  }
  return "";
}
function formatVesselUnitsLabel(metric: VesselSize["kind"] | Omit<NumberOfUnits["kind"], "singles">): string {
  if (metric === "centilitres" || metric === "case") {
    return "cL";
  }
  if (metric === "litresOfPureAlcohol") {
    return "LPA";
  }
  if (metric === "hectolitres") {
    return "hL";
  }
  return "";
}

export function formatVesselSize({
  vesselSize,
  size,
  defaultString,
}: {
  vesselSize: VesselSize | null;
  size?: NumberOfUnits;
  defaultString?: string;
}): string {
  const vesselCapacity = getVesselCapacity(vesselSize, size);
  return vesselCapacity ? formatVesselCapacity(vesselCapacity) : defaultString ?? "";
}

export function formatVesselCapacity(vesselCapacity: VesselCapacity): string {
  return vesselCapacity.kind === "case"
    ? `${correctFloatPrecision(vesselCapacity.value.numberOfSingles)}x${vesselCapacity.value.centilitresPerSingle} cL`
    : `${correctFloatPrecision(vesselCapacity.value)} ${vesselCapacity.unitLabel}`;
}

export function formatNumberOfUnits(n: NumberOfUnits, unitType?: UnitType): string {
  if (n.kind === "singles") {
    return `${correctFloatPrecision(n.value)} ${unitsLabelForUnitType(unitType)}`.trim();
  }
  return `${correctFloatPrecision(n.value)} ${formatVesselUnitsLabel(n.kind)}`.trim();
}

export function formatNumberOfUnits2(n: number, unitType: UnitType): string {
  return formatNumberOfUnits(numberOfUnitsForUnitType(unitType, n), unitType);
}

export function formatExpiryTypes(expiryTypes: Set<DealLegExpiryStageType>) {
  return [...expiryTypes.values()].map(t => getFormLabelForUnionField(snDealLegExpiryStageType, t)).join(" & ");
}
export function formatExpiryTypes2(CSD: LocalDate | null, FSD: LocalDate | null) {
  const expiryTypes = new Set<DealLegExpiryStageType>();
  if (CSD !== null) {
    expiryTypes.add("CSD");
  }
  if (FSD !== null) {
    expiryTypes.add("FSD");
  }
  return formatExpiryTypes(expiryTypes);
}

export function formatExpiryDates(CSDExpiryDate: LocalDate | null, FSDExpiryDate: LocalDate | null) {
  const result: string[] = [];
  if (CSDExpiryDate !== null) {
    result.push(formatLocalDate(CSDExpiryDate));
  }
  if (FSDExpiryDate !== null) {
    result.push(formatLocalDate(FSDExpiryDate));
  }
  return result.join(" & ");
}
