import React, { useState, useCallback, useMemo } from "react";
import {
  SvgIconProps,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  speedDialActionClasses,
  Typography,
  Box,
  speedDialIconClasses,
} from "@mui/material";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import { AppRoutes } from "../../../../app/app-routes";
import { useHistory } from "react-router-dom";
import { useSelectedEntity } from "../../../layouts/portal-page-layout/portal-page";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useUiConfig } from "../../../../../../../packages/adl-service/hooks/use-ui-config";
import { useActionsSpeedDial } from "./use-actions-speed-dial";
import { useNominatedPurchaserIsConfigured } from "../../../../hooks/use-nominated-purchaser-is-configured";

interface ActionsSpeedDialAction {
  icon(args?: SvgIconProps): React.ReactNode;
  name: string;
  route: AppRoutes;
}

export const ActionsSpeedDial = () => {
  const history = useHistory();
  const selectedEntity = useSelectedEntity();
  const [open, setOpen] = useState<boolean>(false);
  const { showProcurement, showNominatedPurchaser, showProductionOrder } = useUiConfig();
  const { isConfigured } = useNominatedPurchaserIsConfigured();

  const orgActions: ActionsSpeedDialAction[] = useMemo<ActionsSpeedDialAction[]>(() => {
    const actions: ActionsSpeedDialAction[] = [];

    if (showNominatedPurchaser && isConfigured) {
      actions.push({
        icon: (args?: SvgIconProps) => <StorefrontIcon {...args} />,
        name: "Sell to third party",
        route: AppRoutes.OrganisationPurchaseRequestByPurchaser,
      });
    }

    if (showProcurement) {
      actions.push({
        icon: (args?: SvgIconProps) => <SellOutlinedIcon {...args} />,
        name: "Upload stock",
        route: AppRoutes.CreateNewDealRequest,
      });
    }

    if (showProductionOrder) {
      actions.push({
        icon: (args?: SvgIconProps) => <HandymanOutlinedIcon {...args} />,
        name: "Production order",
        route: AppRoutes.OrgProductionOrderProductSelection,
      });
    }

    actions.push({
      icon: (args?: SvgIconProps) => <ShoppingBasketOutlinedIcon {...args} />,
      name: "Repurchase stock",
      route: AppRoutes.Repurchase,
    });

    return actions;
  }, [isConfigured, showNominatedPurchaser, showProcurement, showProductionOrder]);

  const showSpeedDial = useActionsSpeedDial();

  const actions = useMemo(() => {
    switch (selectedEntity.type) {
      case "org":
        return orgActions;
      default:
        break;
    }
  }, [selectedEntity.type, orgActions]);

  const navigateToRoute = useCallback(
    (route: AppRoutes) => {
      history.push(route);
      setOpen(false);
    },
    [history],
  );

  return showSpeedDial && actions ? (
    <SpeedDial
      ariaLabel="Actions speed dial"
      sx={{
        position: "fixed",
        bottom: theme => theme.spacing(4),
        right: theme => theme.spacing(4),
        height: "auto",
        alignItems: "flex-end",
      }}
      FabProps={{
        variant: open ? "circular" : "extended",
        color: "secondary",
        sx: { padding: 2, height: "auto" },
      }}
      icon={
        <SpeedDialIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            [`& .${speedDialIconClasses.icon}`]: {
              transform: "none !important",
            },
          }}
          icon={
            <Box sx={{ display: "flex" }}>
              <AddIcon fontSize="small" />
              {!open && <Typography sx={{ ml: 1 }}>ADD ORDER</Typography>}
            </Box>
          }
          openIcon={<CloseIcon fontSize="small" />}
        />
      }
      onClick={() => setOpen(!open)}
      open={open}>
      {actions.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon({ sx: { color: theme => theme.palette.common.grey } })}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => navigateToRoute(action.route)}
          FabProps={{ size: "large", sx: { mr: 0 } }}
          sx={{
            justifyContent: "flex-end",
            [`& .${speedDialActionClasses.staticTooltipLabel}`]: {
              whiteSpace: "nowrap",
              maxWidth: "none",
            },
          }}
        />
      ))}
    </SpeedDial>
  ) : null;
};
