import { useEffect, useState } from "react";
import { makeApiServices } from "adl-service/api-utils";
import { useHistory, useLocation } from "react-router-dom";
import { HttpServiceError } from "adl-service/http-service-error";
import { makeActionLinkConstants } from "adl-gen/ferovinum/app/api";
const { jwtParam } = makeActionLinkConstants({});

interface State {
  error?: string;
  loading: boolean;
}

/**
 * Custom hook to confirm that the purchaser has clicked on the link sent to their email
 * to authorize the related organization to trade on their behalf.
 */
const useConfirmNominatedPurchaserOrderAuth = () => {
  const history = useHistory();
  const location = useLocation(); // Get the location to access search params
  const [state, setState] = useState<State>({
    error: undefined,
    loading: true,
  });

  useEffect(() => {
    const confirmLink = async () => {
      const searchParams = new URLSearchParams(location.search);
      const jwt = searchParams.get(jwtParam);

      if (!jwt) {
        setState({ error: "Invalid link", loading: false });
        return;
      }

      const apiService = makeApiServices(jwt).app;

      try {
        await apiService.confirmTradeSalesOrderAuthorizationLink({});
        setState({ loading: false });
      } catch (error) {
        const errorMsg = error instanceof HttpServiceError ? error.publicMessage : String(error);
        setState({ error: errorMsg, loading: false });
      }
    };

    confirmLink();
  }, [history, location.search]);

  return state;
};

export default useConfirmNominatedPurchaserOrderAuth;
