import { Stack } from "@mui/material";
import {
  NominatedPurchaserTerms,
  PurchaseRequestDeliveryOption,
  PurchaseRequestDeliveryOption_CollectFromStorageLocation,
  PurchaseRequestDeliveryOption_DeliveryToNominatedPurchaser,
  PurchaseRequestPaymentTermsPeriod,
  StorageLocation,
} from "adl-gen/ferovinum/app/db";
import {
  OrgPurchaseRequestSummary,
  OrgRejectedPurchaseRequestSummary,
  PurchaseRequestLineItem,
} from "adl-gen/ferovinum/app/nompurchaser";
import { useTermsDialog } from "components/context/global-dialog/use-dialog";
import {
  makeCollectionLocationCard,
  makeDeliveryDetailsNoAddressCard,
  makeDeliveryLocationCard,
  makePaymentTermsCard,
  makeTradeTermsCard,
} from "components/library/widgets/card/card-makers";
import { CardGrid } from "components/library/widgets/card/card-widgets";
import React from "react";
import { assertNever } from "utils/hx/util/types";
import {
  OrganisationPurchaseRequestData,
  OrganisationRejectedOrExpiredPurchaseRequestData,
  OrganisationValidPurchaseRequestData,
} from "../../../../hooks/use-purchase-request";
import { getIncoterms } from "../../../../utils/purchase-request-utils";
import { OrganisationPurchaseRequestSummaryTable } from "../organisation-purchase-request-summary-table/organisation-purchase-request-summary-table";
import { OrganisationPurchaseRequestValuationCard } from "../organisation-purchase-request-valuation-card/organisation-purchase-request-valuation-card";
import { RejectedPurchaseRequestValuationCard } from "../rejected-purchase-request-valuation-card/rejected-purchase-request-valuation-card";

export const OrganisationPurchaseRequestSummarySection = ({
  previewProps,
  ...purchaseRequestData
}: {
  previewProps?: {
    isPreview: boolean;
    advanceCashUponDelivery: boolean;
    setAdvanceCashUponDelivery: (advanceUponDelivery: boolean) => void;
  };
} & OrganisationPurchaseRequestData) => {
  const { state } = purchaseRequestData;
  // Note(Dyl): Fees & other costs are estimated up to the point of purchaser paying, after which they are finalised
  const isEstimate = !purchaseRequestData.stateEvents.some(e => e.state === "purchaserPaid");
  const isRejectedOrExpired = state === "purchaserRejected" || state === "expired";
  return (
    <Stack spacing={5}>
      <StickyNotes {...purchaseRequestData} />
      <Stack>
        <OrganisationPurchaseRequestSummaryTable
          {...purchaseRequestData}
          isRejectedOrExpired={isRejectedOrExpired}
          products={purchaseRequestData.summary.lineItems as PurchaseRequestLineItem[]}
        />
        {isRejectedOrExpired ? (
          <RejectedPurchaseRequestValuationCard
            {...(purchaseRequestData as OrganisationRejectedOrExpiredPurchaseRequestData)}
            {...(purchaseRequestData.summary as OrgRejectedPurchaseRequestSummary)}
            showDuty={purchaseRequestData.summary.excludeDutyInAdvancedAmount}
          />
        ) : (
          <OrganisationPurchaseRequestValuationCard
            {...(purchaseRequestData as OrganisationValidPurchaseRequestData)}
            {...(purchaseRequestData.summary as OrgPurchaseRequestSummary)}
            {...(previewProps !== undefined
              ? {
                  ...previewProps,
                }
              : {
                  isPreview: false,
                })}
            isEstimate={isEstimate}
          />
        )}
      </Stack>
    </Stack>
  );
};

const StickyNotes = ({
  terms,
  paymentTermsPeriod,
  storageLocation,
  purchaseRequestDeliveryOption,
}: {
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  expiryDays: number;
  purchaseRequestDeliveryOption: PurchaseRequestDeliveryOption;
  storageLocation: StorageLocation;
  terms: NominatedPurchaserTerms;
}) => {
  switch (purchaseRequestDeliveryOption.kind) {
    case "collectFromStorageLocation": {
      return (
        <PurchaseRequestCollectionCardGrid
          deliveryOption={purchaseRequestDeliveryOption}
          storageLocation={storageLocation}
          paymentTermsPeriod={paymentTermsPeriod}
          terms={terms}
        />
      );
    }
    case "deliveryToNominatedPurchaser": {
      return (
        <PurchaseRequestDeliveryCardGrid
          deliveryOption={purchaseRequestDeliveryOption}
          storageLocation={storageLocation}
          paymentTermsPeriod={paymentTermsPeriod}
          terms={terms}
        />
      );
    }
    default: {
      assertNever(purchaseRequestDeliveryOption);
    }
  }
};

const PurchaseRequestCollectionCardGrid = ({
  deliveryOption,
  storageLocation,
  paymentTermsPeriod,
  terms,
}: {
  deliveryOption: PurchaseRequestDeliveryOption_CollectFromStorageLocation;
  storageLocation: StorageLocation;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  terms: NominatedPurchaserTerms;
}) => {
  const { showTermsDialog } = useTermsDialog();
  const { addressLine1, addressLine2, town, postCode, country } = storageLocation;
  return (
    <CardGrid
      spacing={4}
      direction="column"
      cardsGrid={[
        [
          // Column 1
          makeCollectionLocationCard(storageLocation.locationName, {
            addressLine1: addressLine1 ?? "",
            addressLine2: addressLine2 ?? "",
            town: town ?? "",
            postCode: postCode ?? "",
            country: country ?? "",
          }),
        ],
        [
          // Column 2
          makePaymentTermsCard(paymentTermsPeriod),
          makeTradeTermsCard(
            deliveryOption.value.nominatedPurchaserCollection.bonded,
            deliveryOption.value.incoterms,
            async () => await showTermsDialog({ terms: [terms.terms] }),
          ),
        ],
        [],
      ]}
    />
  );
};

const PurchaseRequestDeliveryCardGrid = ({
  deliveryOption,
  storageLocation,
  paymentTermsPeriod,
  terms,
}: {
  deliveryOption: PurchaseRequestDeliveryOption_DeliveryToNominatedPurchaser;
  storageLocation: StorageLocation;
  paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
  terms: NominatedPurchaserTerms;
}) => {
  const { showTermsDialog } = useTermsDialog();
  const { deliveryDetails } = deliveryOption.value;
  const { addressLine1, addressLine2, town, postCode, country } = storageLocation;
  return (
    <CardGrid
      spacing={4}
      direction="column"
      cardsGrid={[
        [
          // Column 1
          makeCollectionLocationCard(storageLocation.locationName, {
            addressLine1: addressLine1 ?? "",
            addressLine2: addressLine2 ?? "",
            town: town ?? "",
            postCode: postCode ?? "",
            country: country ?? "",
          }),
          makeDeliveryLocationCard(deliveryDetails.deliveryPointName, deliveryDetails),
        ],
        [
          // Column 2
          makeDeliveryDetailsNoAddressCard({
            ...deliveryDetails,
          }),
        ],
        [
          // Column 3
          makePaymentTermsCard(paymentTermsPeriod),
          makeTradeTermsCard(
            deliveryDetails.bonded,
            getIncoterms({ purchaseRequestDeliveryOption: deliveryOption }),
            async () => await showTermsDialog({ terms: [terms.terms] }),
          ),
        ],
      ]}
    />
  );
};
