import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NominatedPurchaserDetailsView } from "adl-gen/ferovinum/app/api";
import {
  CollectionIncoterms,
  DeliveryIncoterms,
  PurchaseRequestDeliveryOption,
  PurchaseRequestPaymentTermsPeriod,
  SavedDeliveryInfo,
  ShippingDelivery,
  snCollectionIncoterms,
  snDeliveryIncoterms,
  valuesCollectionIncoterms,
  valuesDeliveryIncoterms,
} from "adl-gen/ferovinum/app/db";
import { ScopedName } from "adl-gen/sys/adlast";
import { isSameFile } from "components/library/widgets/file-picker";
import { DeliveryInstructionDocUploadCard } from "components/library/widgets/wizard-card/delivery-instruction-doc-upload-card";
import { ExpandableRadio } from "components/widgets/inputs/expandable-radio/expandable-radio";
import { FormikProps, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { getFormDescriptionForUnionField, getFormLabelForUnionField } from "utils/adl-utils";
import { parseLocalTimeToDate } from "utils/date-utils";
import { number, object, string } from "yup";
import { useAppService } from "../../../../../hooks/use-app-service";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import {
  DeliveryDetailsWidget,
  setDeliveryDetailsSchemaShape,
} from "../../../../widgets/common/delivery-details/delivery-details-widget";
import { PurchaseRequestFlowStepper } from "../../../../widgets/flow-stepper/purchase-request-flow-stepper";
import { IncotermSelect, IncotermsInfo } from "../../../../widgets/incoterms/incoterms-select";
import {
  PaymentTermsFormValues,
  PaymentTermsInput,
  paymentTermsValidationSchema,
} from "components/library/widgets/payment-terms-input";
import { PurchaseRequestFlowState } from "../organisation-purchase-request-setup/organisation-purchase-request-setup-page";

function getIncotermsToDescriptionMap<T extends string>(
  options: T[],
  scopedName: ScopedName,
): Map<T, IncotermsInfo<T>> {
  return new Map(
    options.map(option => {
      return [
        option,
        {
          code: option,
          label: getFormLabelForUnionField(scopedName, option),
          description: getFormDescriptionForUnionField(scopedName, option),
          disabled: false,
        },
      ];
    }),
  );
}

const SETUP_STEPS = [
  {
    title: "Collection Date",
    question: "Please specify how long it will take to prepare the stock for collection / delivery?",
  },
  {
    title: "Customer Order Number",
  },
  {
    title: "Shipment Number",
  },
  {
    title: "Delivery Method",
    question: "Please select your preferred delivery option.",
  },
  {
    title: "Payment Date",
    question:
      "Please specify when payment will be made. Select the number of days, or months after which payment will be made following collection.",
  },
];

export enum PaymentResponsibilityOption {
  Purchaser = "Purchaser",
  Seller = "Seller",
}
const PaymentReponsabilityOptionKeys = Object.keys(
  PaymentResponsibilityOption,
) as (keyof typeof PaymentResponsibilityOption)[];

type DeliveryOptionType = PurchaseRequestDeliveryOption["kind"];

export type PreparationDaysType = "immediate" | "days";
export interface DeliveryPurchaserRequestFormValues extends PaymentTermsFormValues {
  customerPoRef?: string;
  shipmentNumber?: string;
  preparationDaysType: PreparationDaysType;
  preparationDays?: number;
  deliveryType: DeliveryOptionType;
  collectionIncoterms?: CollectionIncoterms;
  deliveryIncoterms?: DeliveryIncoterms;
  deliveryFeePaidBy?: keyof typeof PaymentResponsibilityOption;
  deliveryDetails: DeliveryDetailsForm;
  deliveryInstructionDocs?: File[];
}
interface DeliveryDetailsForm
  extends Omit<ShippingDelivery, "deliveryDate" | "deliveryTimeEarliest" | "deliveryTimeLatest" | "bonded"> {
  deliveryDate: Date | null;
  deliveryTimeEarliest: Date | null;
  deliveryTimeLatest: Date | null;
}

export interface OrganisationPurchaseRequestDeliveryOptionsPageViewProps {
  purchaser: NominatedPurchaserDetailsView;
  storageLocationName: string;
  deliveryToUkIsAvailable: boolean;
  hasProductionPlanAttached: boolean;
  nominatedPurchaserPaymentTerms: PurchaseRequestPaymentTermsPeriod;
  isBondedSale: boolean;
  onNext: (values: DeliveryPurchaserRequestFormValues) => Promise<void>;
  onBack: () => void;
  initialValues?: PurchaseRequestFlowState["delivery"];
}

const collectionIncoterms: Map<CollectionIncoterms, IncotermsInfo<CollectionIncoterms>> = getIncotermsToDescriptionMap(
  valuesCollectionIncoterms,
  snCollectionIncoterms,
);

export const OrganisationPurchaseRequestDeliveryOptionsPageView = ({
  purchaser,
  storageLocationName,
  deliveryToUkIsAvailable,
  hasProductionPlanAttached,
  nominatedPurchaserPaymentTerms,
  isBondedSale,
  onNext,
  onBack,
  initialValues,
}: OrganisationPurchaseRequestDeliveryOptionsPageViewProps) => {
  const appService = useAppService();
  const [savedDeliveryInfoList, setSavedDeliveryInfoList] = useState<SavedDeliveryInfo[]>([]);
  useEffect(() => {
    appService.getNominatedPurchaserSavedDeliveryInfoList(purchaser).then(setSavedDeliveryInfoList);
  }, [appService, purchaser]);

  const onSubmit = useCallback(
    async (formValues: DeliveryPurchaserRequestFormValues) => {
      //Note(Berto): Beware of .trim() and other transformations functionalities in yup, it will only apply the transformations
      // for validation purposes and not for the actual submitted values
      // We need to manually cast the submitted values in order to apply yup transformations
      const values = validationSchema.cast(formValues) as unknown as DeliveryPurchaserRequestFormValues;
      return await onNext(values);
    },
    [onNext],
  );

  const form = useFormik<DeliveryPurchaserRequestFormValues>({
    initialValues: getInitialFormValues(nominatedPurchaserPaymentTerms, initialValues, hasProductionPlanAttached),
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const isUkBasedSale = purchaser.currency === "GBP";
  return (
    <PortalPageContent
      header={
        <OrganisationPurchaseRequestDeliveryHeader
          purchaserName={purchaser.name}
          hasProductionPlanAttached={hasProductionPlanAttached}
        />
      }>
      <Stack spacing={5}>
        <Stack alignItems="flex-start">
          <DeliveryStep {...SETUP_STEPS[0]}>
            {hasProductionPlanAttached ? (
              <TextField
                label="Days required"
                name={"preparationDays"}
                required
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                }}
                value={form.values.preparationDays}
                error={form.touched.preparationDays && Boolean(form.errors.preparationDays)}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                helperText={form.touched.preparationDays && form.errors.preparationDays}
              />
            ) : (
              <ExpandableRadio
                value={form.values.preparationDaysType}
                onChange={preparationDaysType => {
                  form.setFieldValue("preparationDaysType", preparationDaysType);

                  if (preparationDaysType === "immediate") {
                    form.setFieldValue("preparationDays", 0);
                  } else {
                    form.setFieldValue("preparationDays", "");
                  }
                }}
                options={[
                  {
                    value: "immediate",
                    label: "The stock will be ready for collection/delivery immediately",
                  },
                  {
                    value: "days",
                    label: "The stock will not be ready for collection/delivery immediately",
                    children: (
                      <TextField
                        label="Days required"
                        name={"preparationDays"}
                        required
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                        }}
                        value={form.values.preparationDays}
                        error={form.touched.preparationDays && Boolean(form.errors.preparationDays)}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        helperText={form.touched.preparationDays && form.errors.preparationDays}
                      />
                    ),
                  },
                ]}
              />
            )}
          </DeliveryStep>
        </Stack>
        <DeliveryStep {...SETUP_STEPS[1]}>
          <TextField
            name="customerPoRef"
            label="Customer Order Number"
            value={form.values.customerPoRef}
            error={(form.touched.customerPoRef ?? false) && Boolean(form.errors.customerPoRef ?? false)}
            inputProps={{ maxLength: 20 }}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </DeliveryStep>
        <DeliveryStep {...SETUP_STEPS[2]}>
          <TextField
            name="shipmentNumber"
            label="Shipment Number"
            value={form.values.shipmentNumber}
            error={(form.touched.shipmentNumber ?? false) && Boolean(form.errors.shipmentNumber ?? false)}
            inputProps={{ maxLength: 50 }}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </DeliveryStep>
        <DeliveryStep {...SETUP_STEPS[3]}>
          <ExpandableRadio
            value={form.values.deliveryType}
            onChange={deliveryType => form.setFieldValue("deliveryType", deliveryType)}
            options={[
              {
                value: "collectFromStorageLocation",
                label: `Collect from ${storageLocationName}`,
                children: (
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Stack spacing={2}>
                        <FormLabel component="legend">Incoterms</FormLabel>
                        <IncotermSelect
                          direction="column"
                          value={form.values.collectionIncoterms}
                          incotermsToDescriptionMap={collectionIncoterms}
                          onChange={(v: string) => form.setFieldValue("collectionIncoterms", v)}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                ),
              },
              {
                value: "deliveryToNominatedPurchaser",
                label: `Specify a UK address to deliver to${
                  deliveryToUkIsAvailable && isUkBasedSale
                    ? ""
                    : isUkBasedSale
                    ? " - Currently unavailable at your selected storage location."
                    : " - Not available for purchases outside the UK."
                }`,
                disabled: !deliveryToUkIsAvailable || !isUkBasedSale,
                children: (
                  <ShippingLocationForm
                    form={form}
                    isBondedSale={isBondedSale}
                    savedDeliveryInfoList={savedDeliveryInfoList}
                    storageLocationName={storageLocationName}
                  />
                ),
              },
            ]}
          />
        </DeliveryStep>
        <DeliveryStep {...SETUP_STEPS[4]}>
          {/*// @ts-ignore - TODO: Fix the types of form */}
          <PaymentTermsInput form={form} />
        </DeliveryStep>
        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button onClick={form.submitForm} disabled={!form.isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </PortalPageContent>
  );
};

const OrganisationPurchaseRequestDeliveryHeader = ({
  purchaserName,
  hasProductionPlanAttached,
}: {
  purchaserName: string;
  hasProductionPlanAttached: boolean;
}) => {
  const title = (
    <Stack>
      <Typography variant={"h4"}>New Third Party Sale Order</Typography>
      <Typography>from {purchaserName}</Typography>
    </Stack>
  );
  return (
    <PortalPageContentHeader
      variant="split"
      title={title}
      right={
        <PurchaseRequestFlowStepper
          activeStep={hasProductionPlanAttached ? 2 : 1}
          withProductionPlan={hasProductionPlanAttached}
        />
      }
    />
  );
};

const DeliveryStep = ({
  title,
  question,
  children,
}: {
  title: string;
  question?: string;
  children: React.ReactNode;
}) => {
  return (
    <Stack spacing={2} sx={{ backgroundColor: "white", borderRadius: 1 }} padding={4} width={"100%"}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{question}</Typography>
      {children}
    </Stack>
  );
};

const deliveryIncotermsToDescriptionMap: Map<
  DeliveryIncoterms,
  IncotermsInfo<DeliveryIncoterms>
> = getIncotermsToDescriptionMap(valuesDeliveryIncoterms, snDeliveryIncoterms);

// Selectable subset of delivery incoterms for in-bond sales
const inBondDeliveryIncoterms: Set<DeliveryIncoterms> = new Set(["DAP"]);
const outOfBondDeliveryIncoterms: Set<DeliveryIncoterms> = new Set(["DDP"]);

const inBondDeliveryIncotermsToDescriptionMap = new Map(
  [...deliveryIncotermsToDescriptionMap.entries()].map(([key, value]) => {
    return [key, { ...value, disabled: !inBondDeliveryIncoterms.has(value.code) }];
  }),
);

const outOfBondDeliveryIncotermsToDescriptionMap = new Map(
  [...deliveryIncotermsToDescriptionMap.entries()].map(([key, value]) => {
    return [key, { ...value, disabled: !outOfBondDeliveryIncoterms.has(value.code) }];
  }),
);
const today = new Date();
today.setHours(0, 0, 0, 0);

const ShippingLocationForm = ({
  form,
  isBondedSale,
  savedDeliveryInfoList,
  storageLocationName,
}: {
  form: FormikProps<DeliveryPurchaserRequestFormValues>;
  isBondedSale: boolean;
  savedDeliveryInfoList: SavedDeliveryInfo[];
  storageLocationName: string;
}) => {
  return (
    <Stack spacing={3} direction="column">
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={2}>
            <FormLabel component="legend">Incoterms</FormLabel>
            <IncotermSelect
              direction="column"
              value={form.values.deliveryIncoterms}
              incotermsToDescriptionMap={
                isBondedSale ? inBondDeliveryIncotermsToDescriptionMap : outOfBondDeliveryIncotermsToDescriptionMap
              }
              onChange={(v: string) => form.setFieldValue("deliveryIncoterms", v)}
              overwriteInfo={
                <Stack spacing={1}>
                  <Typography variant="subtitle1Bold">Which one should I choose?</Typography>
                  <Typography>
                    For nation-wide deliveries where you are selling to the third-party purchaser on a delivered basis
                    out of bond (i.e. inclusive of delivery, duty & VAT) please select DDP. <br />
                    <br />
                    For bond-to-bond deliveries, in-bond warehouse transfers and export sales where you are selling to
                    the third party purchaser on a delivered basis (i.e. where you are shipping to the destination for
                    them) please select DAP.
                  </Typography>
                </Stack>
              }
            />

            <FormLabel component="legend">Delivery Payment Responsibility</FormLabel>
            <FormControl
              fullWidth
              required
              error={(form.touched.deliveryFeePaidBy ?? false) && Boolean(form.errors.deliveryFeePaidBy ?? false)}>
              <InputLabel>Select Responsible Party</InputLabel>
              <Select
                value={form.values.deliveryFeePaidBy ?? ""}
                label="Delivery Payment Responsibility"
                onChange={e => form.setFieldValue("deliveryFeePaidBy", e.target.value)}>
                {PaymentReponsabilityOptionKeys.map(key => {
                  return (
                    <MenuItem key={key} value={PaymentResponsibilityOption[key]}>
                      {PaymentResponsibilityOption[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <DeliveryDetailsWidget
        form={form}
        formKey="deliveryDetails"
        storageLocationName={storageLocationName}
        savedDeliveryInfoList={savedDeliveryInfoList}
      />

      <Stack spacing={3} flex={1} width={"100%"}>
        <DeliveryInstructionDocUploadCard
          fileValues={form.values.deliveryInstructionDocs ?? []}
          onFilesAccepted={acceptedFiles => {
            const existingFiles = form.values.deliveryInstructionDocs ?? [];
            const newFiles = acceptedFiles.filter(f => existingFiles.findIndex(file => isSameFile(file, f)) === -1);
            if (newFiles.length > 0) {
              form.setFieldValue("deliveryInstructionDocs", [...existingFiles, ...newFiles]);
            }
          }}
          removeFileAt={atIdx => {
            const files = form.values.deliveryInstructionDocs ?? [];
            const filtered = files.filter((_, idx) => idx != atIdx);
            form.setFieldValue("deliveryInstructionDocs", filtered);
          }}
        />
      </Stack>
    </Stack>
  );
};

const validationSchema = object().shape({
  ...paymentTermsValidationSchema,
  customerPoRef: string().trim().optional().max(20),
  shipmentNumber: string().trim().optional().max(50),
  preparationDaysType: string().oneOf(["immediate", "days"]).required(),
  preparationDays: number()
    .when("preparationDaysType", {
      is: "immediate",
      then: schema => schema.test(n => n === 0),
      otherwise: schema => schema.positive("Please input a number more than 0"),
    })
    .required("This is a required field"),
  deliveryType: string().oneOf(["collectFromStorageLocation", "deliveryToNominatedPurchaser"]).required(),
  collectionIncoterms: string().when("deliveryType", {
    is: (deliveryType: DeliveryOptionType) => deliveryType === "collectFromStorageLocation",
    then: schema => schema.oneOf(valuesCollectionIncoterms).required(),
  }),
  deliveryIncoterms: string().when("deliveryType", {
    is: (deliveryType: DeliveryOptionType) => deliveryType === "deliveryToNominatedPurchaser",
    then: schema => schema.oneOf(valuesDeliveryIncoterms).required(),
  }),
  deliveryFeePaidBy: string().when("deliveryType", {
    is: (deliveryType: DeliveryOptionType) => deliveryType === "deliveryToNominatedPurchaser",
    then: schema => schema.oneOf(Object.keys(PaymentResponsibilityOption)).required(),
  }),
  deliveryDetails: object().when("deliveryType", {
    is: (deliveryType: DeliveryOptionType) => deliveryType === "deliveryToNominatedPurchaser",
    then: schema => setDeliveryDetailsSchemaShape(schema),
  }),
});

function getInitialFormValues(
  nominatedPurchaserPaymentTerms: PurchaseRequestPaymentTermsPeriod,
  val: PurchaseRequestFlowState["delivery"],
  hasProductionPlanAttached: boolean,
): DeliveryPurchaserRequestFormValues {
  const deliveryDetails: DeliveryDetailsForm = {
    deliveryPointName: "",
    streetName: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    postCode: "",
    deliveryContact: "",
    deliveryContactEmail: "",
    deliveryContactNumber: "",
    deliveryDate: null,
    deliveryTimeEarliest: null,
    deliveryTimeLatest: null,
    deliveryInstructions: "",
  };

  const paymentTerms = val?.paymentTermsPeriod || nominatedPurchaserPaymentTerms;
  const deliveryFeePaidBy =
    val?.purchaserCoversDeliveryCost === true
      ? PaymentResponsibilityOption.Purchaser
      : val?.purchaserCoversDeliveryCost === false
      ? PaymentResponsibilityOption.Seller
      : undefined;
  return {
    preparationDaysType: val?.preparationDaysType ?? (hasProductionPlanAttached ? "days" : "immediate"),
    preparationDays: val?.preparationDays ?? (hasProductionPlanAttached ? undefined : 0),
    deliveryType: val?.deliveryOption?.kind ?? "collectFromStorageLocation",
    collectionIncoterms:
      val?.deliveryOption.kind === "collectFromStorageLocation" ? val.deliveryOption.value.incoterms : undefined,
    deliveryIncoterms:
      val?.deliveryOption.kind === "deliveryToNominatedPurchaser" ? val.deliveryOption.value.incoterms : undefined,
    paymentTermsType: paymentTerms.kind,
    numDaysAfterDelivery: paymentTerms.kind === "daysAfterCollection" ? paymentTerms.value : 0,
    numMonthsFollowingDelivery: paymentTerms.kind === "endOfMonth" ? paymentTerms.value : 0,
    deliveryFeePaidBy,
    customerPoRef: val?.customerPoRef,
    shipmentNumber: val?.shipmentNumber,
    deliveryDetails:
      val?.deliveryOption.kind === "deliveryToNominatedPurchaser"
        ? {
            ...val.deliveryOption.value.deliveryDetails,
            deliveryContact: val.deliveryOption.value.deliveryDetails.deliveryContact ?? "",
            deliveryContactEmail: val.deliveryOption.value.deliveryDetails.deliveryContactEmail ?? "",
            deliveryContactNumber: val.deliveryOption.value.deliveryDetails.deliveryContactNumber ?? "",
            deliveryDate: val.deliveryOption.value.deliveryDetails.deliveryDate
              ? new Date(val.deliveryOption.value.deliveryDetails.deliveryDate)
              : null,
            deliveryTimeEarliest: val.deliveryOption.value.deliveryDetails.deliveryTimeEarliest
              ? parseLocalTimeToDate(val.deliveryOption.value.deliveryDetails.deliveryTimeEarliest)
              : null,
            deliveryTimeLatest: val.deliveryOption.value.deliveryDetails.deliveryTimeLatest
              ? parseLocalTimeToDate(val.deliveryOption.value.deliveryDetails.deliveryTimeLatest)
              : null,
            deliveryInstructions: val.deliveryOption.value.deliveryDetails.deliveryInstructions ?? "",
          }
        : deliveryDetails,
    deliveryInstructionDocs: val?.deliveryInstructionDocs,
  };
}
