import { Autocomplete, AutocompleteRenderOptionState, AutocompleteOwnerState, TextField } from "@mui/material";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { DataFieldDef } from "utils/data-field/data-field-def";
import { FormField } from "../../types/formik-types";
import { TextFieldProps } from "@mui/material/TextField/TextField";

interface OptionPickerBaseProps<E> {
  fullWidth?: boolean;
  labelOverride?: string;
  options: E[];
  getOptionDisabled?: (option: E) => boolean;
  getOptionLabel?: (option: E) => string;
  onValueChange?: (v: E | undefined) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement> & { key: any },
    option: E,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<E, false, false, false, "div">,
  ) => React.ReactNode;
  initialValue?: E;
  disabled?: boolean;
}
interface FormOptionPickerProps<E> extends OptionPickerBaseProps<E> {
  field: FormField<E>;
  fieldDef: DataFieldDef<E>;
}

export function FormOptionPicker<E>(props: FormOptionPickerProps<E>) {
  const { field, fieldDef, initialValue } = props;
  const [fieldValue, setFieldValue] = useState<E | null>(field.get() ?? null);
  const handleChange = useCallback(
    (value: E | undefined) => {
      field.clearError();
      setTimeout(() => {
        field.setValidateAndTouch(value);
        setFieldValue(value ?? null);
      });
    },
    [field],
  );

  return (
    <OptionPicker<E>
      fullWidth={props.fullWidth}
      labelOverride={props.labelOverride}
      options={props.options}
      value={fieldValue}
      getOptionDisabled={props.getOptionDisabled}
      getOptionLabel={props.getOptionLabel ?? ((o: E) => fieldDef.toString(o) ?? "")}
      onValueChange={handleChange}
      renderOption={props.renderOption}
      initialValue={initialValue}
      textFieldProps={{
        label: props.labelOverride ?? fieldDef.label,
        name: field.path,
        error: field.hasError(true),
        helperText: field.getError(true),
        required: props.fieldDef.isRequired,
        disabled: props.disabled,
      }}
    />
  );
}

interface OptionPickerProps<E> extends OptionPickerBaseProps<E> {
  value: E | null;
  textFieldProps?: TextFieldProps;
}
export function OptionPicker<E>(props: OptionPickerProps<E> & TextFieldProps) {
  const { initialValue } = props;
  const handleChange = useCallback(
    (value: E | undefined) => {
      props.onValueChange?.(value);
    },
    [props],
  );

  const initialValueRef = React.useRef(initialValue);
  useEffect(() => {
    // Only set initial value if it changes / first render
    if (initialValueRef.current != initialValue) {
      handleChange(initialValue);
      initialValueRef.current = initialValue;
    }
  }, [handleChange, initialValue]);

  return (
    <Autocomplete<E>
      value={props.value}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      options={props.options}
      getOptionDisabled={props.getOptionDisabled}
      getOptionLabel={props.getOptionLabel}
      onChange={handleAutoCompleteChange(handleChange)}
      renderOption={props.renderOption}
      renderInput={inputParams => <TextField {...inputParams} {...props.textFieldProps} />}
    />
  );
}

/// Note: Autocomplete won't correctly set the text field name used by formik
/// need to set the state manually for autocomplete
function handleAutoCompleteChange<E>(onChange: (v: E | undefined) => void) {
  return (_e: SyntheticEvent, rawValue: unknown) => {
    const value = rawValue ? (rawValue as E) : undefined;
    onChange(value);
  };
}
