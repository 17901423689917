import React, { useMemo, useRef } from "react";
import { Box, Card, Divider, Stack, styled, Typography } from "@mui/material";
import { Link } from "components/widgets/link/link";
import { useRefDimensions } from "utils/hooks/use-ref-dimensions";
import { useInfoDialog } from "components/context/global-dialog/use-dialog";

export type StickyNoteAction = {
  label?: string;
  onClick: () => void;
};
interface BaseStickyNoteProps {
  title?: string;
  action?: StickyNoteAction;
  children?: React.ReactNode;
}

/** Note(Berto): Avoid using directly unless strictly necessary */
export const BaseStickyNote = ({ title, action, children }: BaseStickyNoteProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLElement>(null);
  const { height: availableContentHeight } = useRefDimensions(containerRef);
  const { height: contentHeight } = useRefDimensions(contentRef);
  const { height: titleHeight } = useRefDimensions(titleRef);
  const contentIsOverflowing = useMemo(
    () => titleHeight + contentHeight > availableContentHeight,
    [availableContentHeight, contentHeight, titleHeight],
  );
  const { showInfoDialog } = useInfoDialog();

  return (
    <Card title={title} sx={{ height: "200px" }}>
      <Stack height="100%" paddingBottom={action ? undefined : 2}>
        <Stack
          flex={1}
          sx={{ p: 2, cursor: contentIsOverflowing ? "pointer" : undefined }}
          spacing={1}
          overflow="hidden"
          ref={containerRef}
          position="relative"
          onClick={
            contentIsOverflowing
              ? async () =>
                  await showInfoDialog({
                    body: (
                      <Stack spacing={2}>
                        {title && (
                          <Typography variant="body2" color="common.grey6">
                            {title}
                          </Typography>
                        )}
                        {children}
                      </Stack>
                    ),
                  })
              : undefined
          }>
          <Typography variant="caption" color="common.grey6" ref={titleRef}>
            {title}
          </Typography>
          <EllipsizedContent ref={contentRef} isOverflowing={contentIsOverflowing}>
            {children}
          </EllipsizedContent>
        </Stack>
        {action && (
          <Box>
            <Divider />
            <Box sx={{ p: 1, pl: 2, pr: 2 }}>
              <Link variant="small" onClick={action.onClick} color="grey6">
                {action.label ?? "Edit"}
              </Link>
            </Box>
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const EllipsizedContent = styled(Box, { shouldForwardProp: prop => prop !== "isOverflowing" })<{
  isOverflowing: boolean;
}>(({ theme, isOverflowing }) => ({
  ...(isOverflowing && {
    "&:before": {
      position: "absolute",
      content: "''",
      width: "100%",
      height: "100%",
      background: "linear-gradient(transparent 10px, white)",
    },
    "&:after": {
      position: "absolute",
      content: "''",
      right: theme.spacing(4),
      bottom: 0,
    },
  }),
}));

export interface BaseNoteProps {
  title: string;
  action?: StickyNoteAction;
}
export interface StickyNoteProps extends BaseNoteProps {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
  emphasizeFirstLine?: boolean;
}

export const StickyNote = ({ title, line1, line2, line3, line4, action }: StickyNoteProps) => {
  return (
    <BaseStickyNote title={title} action={action}>
      <Stack spacing={0.5}>
        <Typography variant={"body1"}>{line1}</Typography>
        {line2 && <Typography variant="body1">{line2}</Typography>}
        {line3 && <Typography variant="body1">{line3}</Typography>}
        {line4 && <Typography variant="body1">{line4}</Typography>}
      </Stack>
    </BaseStickyNote>
  );
};

export interface SplitStickyNoteProps {
  top: {
    title: string;
    line?: string;
  };
  bottom: {
    title: string;
    line?: string;
  };
}

export const SplitStickyNote = ({ top, bottom }: SplitStickyNoteProps) => {
  return (
    <BaseStickyNote>
      <Stack justifyContent="space-between" divider={<Divider />} spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="caption" color="common.grey6">
            {top.title}
          </Typography>
          <Typography>{top.line ?? "-"}</Typography>
        </Stack>
        <Stack spacing={0.5}>
          <Typography variant="caption" color="common.grey6">
            {bottom.title}
          </Typography>
          <Typography>{bottom.line ?? "-"}</Typography>
        </Stack>
      </Stack>
    </BaseStickyNote>
  );
};
