import React from "react";
import { FormikProps } from "formik";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { PurchaseRequestPaymentTermsPeriod } from "adl-gen/ferovinum/app/db";
import { number, string } from "yup";
import { ExpandableRadio } from "../../widgets/inputs/expandable-radio/expandable-radio";

export interface PaymentTermsFormValues {
  paymentTermsType: PurchaseRequestPaymentTermsPeriod["kind"];
  numDaysAfterDelivery: number;
  numMonthsFollowingDelivery: number;
}
export interface PaymentTermsInputProps<T extends PaymentTermsFormValues> {
  form: FormikProps<T>;
}

export const paymentTermsValidationSchema = {
  paymentTermsType: string().oneOf(["daysAfterCollection", "endOfMonth"]).required(),
  numDaysAfterDelivery: number().when("paymentTermsType", {
    is: (paymentTermsType: string) => paymentTermsType === "daysAfterCollection",
    then: schema =>
      schema
        .required("This is a required field")
        .positive("Payment terms cannot be less than 0 days")
        .max(90, "Payment Terms cannot be more than 90 days"),
  }),
  numMonthsFollowingDelivery: number().when("paymentTermsType", {
    is: (paymentTermsType: string) => paymentTermsType === "endOfMonth",
    then: schema => schema.required("This is a required field").min(0).max(2),
  }),
};
export const PaymentTermsInput = <T extends PaymentTermsFormValues>({ form }: PaymentTermsInputProps<T>) => (
  <ExpandableRadio
    value={form.values.paymentTermsType}
    onChange={paymentTermsType => {
      form.setFieldValue("paymentTermsType", paymentTermsType);
    }}
    options={[
      {
        value: "daysAfterCollection",
        label: "Number of days after collection",
        children: (
          <TextField
            label="Number of days"
            name={"numDaysAfterDelivery"}
            required
            type="number"
            InputProps={{
              inputProps: { min: 0, max: 90 },
              endAdornment: <InputAdornment position="end">Days</InputAdornment>,
            }}
            value={form.values.numDaysAfterDelivery || ""}
            error={form.touched.numDaysAfterDelivery && Boolean(form.errors.numDaysAfterDelivery)}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            helperText={form.touched.numDaysAfterDelivery && form.errors.numDaysAfterDelivery}
          />
        ),
      },
      {
        value: "endOfMonth",
        label: "At the end of a number of months following collection",
        children: (
          <FormControl>
            <InputLabel>Select month</InputLabel>
            <Select
              name={"numMonthsFollowingDelivery"}
              label="Select month"
              value={form.values.numMonthsFollowingDelivery || 0}
              onChange={e =>
                e.target.value !== undefined && form.setFieldValue("numMonthsFollowingDelivery", e.target.value)
              }>
              {VALID_MONTHS_AFTER_DELIVERY_INPUT_VALUES.map(monthOffset => (
                <MenuItem key={monthOffset} value={monthOffset}>
                  {MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS[monthOffset]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
    ]}
  />
);

// Descriptions to be used for display purposes (including deprecated values)
// The index of the array corresponds to the number of months after delivery e.g. 0 = "End of this month"
export const MONTHS_AFTER_DELIVERY_FIELD_DESCRIPTIONS = [
  "End of this month",
  "End of next month",
  "End of the 2nd month",
  "End of the 3rd month", // Deprecated value, this can exceed the maximum of 90 days
];

// These are offsetting months for payment terms that would produce less than 90 days of payment term.
const VALID_MONTHS_AFTER_DELIVERY_INPUT_VALUES = Object.freeze([0, 1, 2]);
