import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { AlcoholDetail, Country, PhysicalDetails, ProductDate, ProductType, UnitType } from "adl-gen/ferovinum/app/db";
import { countryCodeToCountryName, titleCase, unitTypeToString } from "utils/conversion-utils";
import { Link } from "components/widgets/link/link";
import { VesselCapacity, formatVesselCapacity } from "utils/model-utils";
import { keysOf } from "utils/type-utils";
import { startCase } from "lodash";
import { assertNever } from "assert-never";
import { isNotNull } from "utils/ts-utils";

export interface ProductSummaryProps {
  code: string;
  productDate: ProductDate;
  name: string;
  producerName: string;
  vesselCapacity?: VesselCapacity;
  unitType?: UnitType;
  abv?: number | null;
  country?: Country;
  productId?: string;
  disabled?: boolean;
  includeAdditionalDetails?: {
    productType?: ProductType;
    regionOrigin?: string;
  };
  physicalDetails?: PhysicalDetails | null;
  includedAlcoholDetail?: AlcoholDetail;
  onClick?: () => void;
}

/** Displays a small summary of a product (To be used in table cells) */
export const ProductSummary = ({
  code,
  productDate,
  name,
  producerName,
  vesselCapacity,
  unitType,
  abv,
  country,
  disabled,
  includeAdditionalDetails,
  includedAlcoholDetail,
  physicalDetails,
  onClick,
}: ProductSummaryProps) => {
  const vesselCapacityStr = useMemo(() => {
    return vesselCapacity && formatVesselCapacity(vesselCapacity);
  }, [vesselCapacity]);
  const year = productDate.kind === "vintageYear" ? productDate.value : undefined;

  const additionalInfo = useMemo(() => {
    const details: string[] = [];

    if (includeAdditionalDetails?.productType) {
      details.push(titleCase(includeAdditionalDetails.productType));
    }
    if (abv) {
      details.push(`${abv}% ABV`);
    }
    if (country) {
      details.push(countryCodeToCountryName(country));
    }
    if (includeAdditionalDetails?.regionOrigin) {
      details.push(titleCase(includeAdditionalDetails.regionOrigin));
    }

    return details.join(" | ");
  }, [abv, country, includeAdditionalDetails]);

  const alcoholDetailInfo = useMemo(() => {
    return includedAlcoholDetail
      ? extractAlcoholDetails(includedAlcoholDetail)
          .filter(s => !!s.trim())
          .join(" | ")
      : "";
  }, [includedAlcoholDetail]);

  return (
    <Box sx={{ "& > *": { color: disabled ? theme => `${theme.palette.common.grey5} !important` : undefined } }}>
      {!disabled && onClick !== undefined ? (
        <Link onClick={() => onClick()}>{code}</Link>
      ) : (
        <Typography variant="body2" color="common.grey6">
          {code}
        </Typography>
      )}
      <Typography variant="body1">{`${year ? `${year} | ` : ""} ${name}`}</Typography>
      <Typography variant="body2" color="common.grey7">
        {`${producerName} ${vesselCapacityStr || unitType ? "| " : ""} ${
          vesselCapacityStr ? `${vesselCapacityStr}` : ""
        } ${unitType ? ` ${unitTypeToString(unitType)}` : ""}`}
      </Typography>
      <Typography variant="body2" color="common.grey7">
        {additionalInfo}
      </Typography>
      <Typography variant="body2" color="common.grey7">
        {alcoholDetailInfo}
      </Typography>
      {physicalDetails && (
        <Typography variant="body2" color="common.grey7">
          Batch {`${physicalDetails.batchNumber} | Expiry ${physicalDetails.expiryDate}`}
        </Typography>
      )}
    </Box>
  );
};

function extractAlcoholDetails(alcoholDetail: AlcoholDetail): string[] {
  switch (alcoholDetail.kind) {
    case "unknown":
      return [];
    case "caskedWhisky":
      return keysOf(alcoholDetail.value)
        .map(k => {
          switch (k) {
            case "area": /* (string|null) */
            case "make": /* string */
            case "aysDate": /* common.LocalDate */ {
              return alcoholDetail.value[k];
            }
            case "liquid": /* WhiskyLiquid */
            case "stage": /* (WhiskyStage|null) */
            case "caskFormat": /* CaskFormat */
            case "caskFill": /* (CaskFill|null) */
            case "caskComposition": /* (CaskComposition|null) */
            case "caskWood": /* (CaskWood|null) */
            case "caskOrientation": /* (CaskOrientation|null) */
            case "caskGeographicOrigin": /* (CaskGeographicOrigin|null) */
            case "caskNumber": /* (string|null) */
            case "distilleryProductionParcel": /* (string|null) */
            case "locationInfo": /* (string|null) */
            case "additionalNotes": /* (string|null) */ {
              return startCase(alcoholDetail.value[k] ?? undefined) ?? null;
            }
            case "filledAt": /* (PartialDate|null) */
            case "regaugedAt": /* (PartialDate|null) */ {
              return alcoholDetail.value[k]?.value ?? null;
            }

            default:
              return assertNever(k);
          }
        })
        .filter(isNotNull);
    default:
      assertNever(alcoholDetail);
  }
}
