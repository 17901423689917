import { DatePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import { Button, ButtonProps, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";

export const ButtonWithDatePickerDialog = ({
  buttonLabel,
  color,
  onConfirm,
  dialogTitle,
  dateFieldLabel,
  initialDate,
  minDate,
  maxDate,
}: {
  buttonLabel: string;
  color?: ButtonProps["color"];
  onConfirm(date: Date): Promise<void>;
  dialogTitle?: string;
  dateFieldLabel: string;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button color={color} onClick={() => setDialogOpen(true)}>
        {buttonLabel}
      </Button>
      <DatePickerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={async date => {
          await onConfirm(date);
          setDialogOpen(false);
        }}
        dialogTitle={dialogTitle ?? buttonLabel}
        dateFieldLabel={dateFieldLabel}
        initialDate={initialDate}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
};

const DatePickerDialog = ({
  open,
  onClose,
  onConfirm,
  dialogTitle,
  dateFieldLabel,
  initialDate,
  minDate,
  maxDate,
}: {
  open: boolean;
  onClose(): void;
  onConfirm(date: Date): Promise<void>;
  dialogTitle: string;
  dateFieldLabel: string;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
}) => {
  const [date, setDate] = useState(initialDate ?? new Date());
  return (
    <ConfirmationDialog
      title={dialogTitle}
      open={open}
      acceptAction={{
        onClick: async () => await onConfirm(date),
      }}
      cancelAction={{ onClick: onClose }}>
      <Stack spacing={2}>
        <Typography variant="body1Bold">{dateFieldLabel}</Typography>
        <DatePicker
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          value={date}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: { fullWidth: true },
          }}
          onChange={d => d && setDate(d)}
        />
      </Stack>
    </ConfirmationDialog>
  );
};
