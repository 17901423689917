import React, { useState } from "react";
import { Stack, Tab, TableCell, TableRow, Tabs, Typography } from "@mui/material";

import { DbKey, WithDbId } from "adl-gen/common/db";
import { RevaluationDetailsResp } from "adl-gen/ferovinum/app/api";
import { NumberOfUnits, Product, RevaluationRequest } from "adl-gen/ferovinum/app/db";
import { LabelledCard } from "components/widgets/labelled-card/labelled-card";
import { TermsLink } from "components/widgets/link/terms-link";
import { ContractNoteView } from "adl-gen/ferovinum/app/views";
import { RevaluationPricingTotalsTable } from "components/library/widgets/revaluation-pricing-totals-table";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import { isSinglesUnitType } from "utils/model-utils";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";
import { PdfDownloader } from "components/widgets/buttons/pdf-downloader";

type RevaluationRequestSections = "progress" | "summary" | "contractNote";

export interface RevaluationRequestDetailsPageViewProps {
  revaluationDetails: RevaluationDetailsResp;
  contractNoteView: ContractNoteView;
  revaluationRequestId: DbKey<RevaluationRequest>;
}

export const OrgRevaluationRequestDetailsPageView = ({
  revaluationDetails,
  contractNoteView,
  revaluationRequestId,
}: RevaluationRequestDetailsPageViewProps) => {
  const [selectedSection, setSelectedSection] = useState<RevaluationRequestSections>("progress");

  let allAvailablePdfs: AvailablePdf[] = [];
  if (revaluationDetails?.availablePdfs) {
    allAvailablePdfs = allAvailablePdfs.concat(revaluationDetails.availablePdfs);
  }

  if (contractNoteView?.availablePdfs) {
    allAvailablePdfs = allAvailablePdfs.concat(contractNoteView.availablePdfs);
  }

  return (
    <PortalPageContent
      header={
        <PortalPageContentHeader
          variant="split"
          title={"Stock Revaluation"}
          // subtitles={[{ text: `requested on ${formatLocalDate(revaluationDetails.dateOfRequest)}` }]}
          right={
            <Stack width={"100%"} textAlign={"right"}>
              <Typography sx={{ fontSize: 12, color: "common.grey5" }}>Reference Number</Typography>
              <Typography variant="subtitle1Bold">{revaluationRequestId}</Typography>
            </Stack>
          }
        />
      }>
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Tabs value={selectedSection} onChange={(_, v) => setSelectedSection(v)}>
            <Tab label="Progress" value="progress" sx={{ textTransform: "none" }} />
            <Tab label="Summary" value="summary" sx={{ textTransform: "none" }} />
            <Tab label="Contract Note" value="contractNote" sx={{ textTransform: "none" }} />
          </Tabs>

          <Stack direction="row" justifyContent={"space-between"} spacing={2} marginTop={2}>
            <PdfDownloader availablePdfs={allAvailablePdfs} />
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={6} marginTop={5}>
        {selectedSection === "progress" && (
          <LabelledCard label="Next Action">
            <Stack spacing={6}>
              <Typography>Contract note for the revaluation for {revaluationDetails.orgInfo.businessName}</Typography>
              <RevaluationPricingTotalsTable revaluationDetails={revaluationDetails} />
            </Stack>
            <Stack direction="row" justifyContent={"flex-end"} margin={3}>
              <TermsLink dealTermsView={revaluationDetails.dealTermsView} clickText={"Deal Terms"} />
            </Stack>
            <Stack direction="row" justifyContent={"flex-end"} spacing={2} marginTop={3}></Stack>
          </LabelledCard>
        )}
        {selectedSection === "summary" && (
          <SummaryTable
            summaries={revaluationDetails.lineItems.map(item => ({
              product: item.product,
              quantity: item.quantity,
            }))}
          />
        )}
        {selectedSection === "contractNote" && (
          <LabelledCard label="">
            <Stack spacing={6}>
              <RevaluationPricingTotalsTable revaluationDetails={revaluationDetails} />
            </Stack>
            <Stack direction="row" justifyContent={"space-between"} marginTop={5} marginLeft={3}>
              <TermsLink dealTermsView={revaluationDetails.dealTermsView} />
            </Stack>
            <Stack direction="row" justifyContent={"flex-end"} spacing={2} marginTop={3}></Stack>
          </LabelledCard>
        )}
      </Stack>
    </PortalPageContent>
  );
};

interface SummaryTableProps {
  product: WithDbId<Product>;
  quantity: NumberOfUnits;
}

const SummaryTable = ({ summaries }: { summaries: SummaryTableProps[] }) => {
  return (
    <PaginatedTable
      HeaderContent={<RevaluationRequestSummaryHeader />}
      BodyContent={<RevaluationRequestSummaryTableBody rows={summaries} />}
      initialRows={summaries}
      totalRowCount={summaries.length}
    />
  );
};

function RevaluationRequestSummaryHeader() {
  return (
    <TableRow>
      <TableCell>Product</TableCell>
      <TableCell>Quantity</TableCell>
    </TableRow>
  );
}

function RevaluationRequestSummaryTableBody(props: { rows: SummaryTableProps[] }) {
  const { rows } = props;
  return (
    <>
      {rows.map(productSummary => (
        <RevaluationRequestBasicTableRow key={productSummary.product.id} productSummary={productSummary} />
      ))}
    </>
  );
}

const RevaluationRequestBasicTableRow = ({ productSummary }: { productSummary: SummaryTableProps }) => {
  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary {...productSummary.product.value} />
      </TableCell>
      <TableCell>
        {isSinglesUnitType(productSummary.product.value.unitType) ? productSummary.quantity.value : 1}
      </TableCell>
    </TableRow>
  );
};
