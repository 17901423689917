import { Stack } from "@mui/material";
import { CardText, Card as DetailsCard, stackStrings } from "components/library/widgets/card/card";
import React, { useMemo } from "react";
import { PurchaserPurchaseRequestData } from "../../../../hooks/use-purchase-request";

export const RelevantPartiesStickyNotes = ({
  purchaseRequest,
}: {
  purchaseRequest: Pick<PurchaserPurchaseRequestData, "organisationName" | "purchaserDetails">;
}) => {
  const { organisationName, purchaserDetails } = purchaseRequest;

  const purchaserAddress = useMemo(
    () =>
      stackStrings([
        purchaserDetails.addressLine1,
        purchaserDetails.addressLine2,
        purchaserDetails.addressLine3,
        purchaserDetails.town,
        purchaserDetails.postCode,
      ]),
    [purchaserDetails],
  );

  return (
    <Stack spacing={4} display={"flex"} height="100%" flex={1}>
      <DetailsCard
        header={{
          title: "Supplier Details",
        }}
        body={<CardText title="Supplier Name" content={organisationName} />}
        // sx={{ flex: 1 }}
      />
      <DetailsCard
        header={{
          title: "Purchaser Details",
          titleTooltip: "Entity responsible for ensuring payment of the order",
        }}
        body={
          <>
            <CardText title="Purchaser Name" content={purchaserDetails.name} />
            <CardText title="Address" content={purchaserAddress} lineNumbers={3} />
          </>
        }
        sx={{ flex: 1 }}
      />
    </Stack>
  );
};
