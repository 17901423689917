import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "components/widgets/link/link";
import {
  FEROVINUM_CONTACT_URL,
  FEROVINUM_SELLER_DETAILS,
  FEROVINUM_TERMS_AND_CONDITIONS_URL,
} from "components/assets/url";
import useConfirmNominatedPurchaserOrderAuth from "./useConfirmNominatedPurchaserOrderAuth";
import OpenInNewIcon from "@mui/icons-material/OpenInNew"; // Import the open-in-new icon
const AuthorisationSuccess = () => {
  const viewTermsAndConditions = () => {
    window.open(FEROVINUM_TERMS_AND_CONDITIONS_URL, "_blank");
  };
  const viewSellerDetails = () => {
    window.open(FEROVINUM_SELLER_DETAILS, "_blank");
  };

  return (
    <Stack spacing={4} justifyContent={"space-between"}>
      <Stack
        direction={{
          xs: "column",
          lg: "row",
        }}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}>
        <CheckCircleRoundedIcon
          color="success"
          sx={{
            fontSize: 50,
          }}
        />
        <Typography variant={"h5"}>Order Authorisation Complete</Typography>
      </Stack>
      <Stack
        direction={{
          xs: "column",
          lg: "row",
        }}
        spacing={2}
        justifyContent={"center"}>
        <Button onClick={viewTermsAndConditions} startIcon={<OpenInNewIcon />}>
          Read Ts&Cs
        </Button>
        <Button onClick={viewSellerDetails} startIcon={<OpenInNewIcon />}>
          Seller Details
        </Button>
      </Stack>
    </Stack>
  );
};

const AuthorisationFailure = ({ error }: { error: string }) => {
  return (
    <Stack justifyContent={"center"} textAlign={"center"}>
      <Stack
        direction={{
          xs: "column",
          lg: "row",
        }}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}>
        <CancelIcon
          color="error"
          sx={{
            fontSize: 50,
          }}
        />
        <Typography variant={"h5"}> Order Authorisation Failed</Typography>
      </Stack>
      <Typography variant={"body1"}>{error}</Typography>
      <Typography variant="body1" component="span">
        Please{" "}
        <Link variant="big" href={FEROVINUM_CONTACT_URL} target="_blank" typographyProps={{ component: "span" }}>
          contact Ferovinum
        </Link>{" "}
        for assistance
      </Typography>
    </Stack>
  );
};

export const NominatedPurchaserOrderAuthorisationPage = () => {
  const { error, loading } = useConfirmNominatedPurchaserOrderAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <AuthorisationFailure error={error} />;
  }

  return <AuthorisationSuccess />;
};
