import { Grid } from "@mui/material";
import {
  CaskComposition,
  CaskFill,
  CaskFormat,
  CaskGeographicOrigin,
  CaskOrientation,
  CaskWood,
  Country,
  WhiskyLiquid,
  WhiskyStage,
} from "adl-gen/ferovinum/app/db";
import { InputUiBuilder } from "components/library/helpers/input-ui-builder";
import { FormikForm } from "components/types/formik-types";
import React, { useMemo } from "react";
import { makeObjectSchema, pickData } from "utils/data-field/object-field-def";
import { mapNullToUndef, mapUndefToNull } from "utils/null-and-undefined-utils";
import { mapOptional } from "utils/type-utils";
import { ObjectSchema, Schema } from "yup";
import {
  BASE_PRODUCT_DATA_DEF,
  CASKED_WHISKY_PRODUCT_INFO_DEF,
  CaskedWhiskyProductInfo,
} from "../../../service/casked-whisky-product-csv-parser";
import { updateProductCodeSchema } from "../../../service/csv-product-data-yup-schemas";
import { NewProductData } from "../../page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { ProductFormHandler } from "./product-editor-form-types";

export class CaskedWhiskyFormHandler implements ProductFormHandler<CaskedWhiskyProductInfo> {
  createValidationSchema(
    uniqueProductCodes: Set<string>,
    onValidateProductCode: (productCode: string) => Promise<boolean>,
  ): ObjectSchema<CaskedWhiskyProductInfo> {
    return makeObjectSchema(
      CASKED_WHISKY_PRODUCT_INFO_DEF,
      // TODO Zhi: SIMPLIFY do these validations ouside yup
      (def, key) => {
        if (key === "code") {
          return updateProductCodeSchema(def.getSchema() as Schema<string>, uniqueProductCodes, onValidateProductCode);
        } else {
          return def.getSchema();
        }
      },
    );
  }

  fromProductData(productData?: NewProductData): Partial<CaskedWhiskyProductInfo> {
    const { alcoholDetail, vesselType } = productData ?? {};
    if (alcoholDetail && alcoholDetail.kind !== "caskedWhisky") {
      throw "Alcohol Detail must be of type CaskedWhisky";
    }
    if (vesselType && vesselType.kind !== "cask") {
      throw "Vessel Type must be of type Cask";
    }
    const productInfo: Partial<CaskedWhiskyProductInfo> = {
      ...(productData && pickData(BASE_PRODUCT_DATA_DEF, productData)),
      ...mapOptional(alcoholDetail?.value, mapNullToUndef),
      lpa: vesselType?.value.lpa ?? undefined,
    };
    return mapNullToUndef(productInfo);
  }

  toProductData(values: CaskedWhiskyProductInfo): NewProductData {
    const baseProductData = mapUndefToNull(pickData(BASE_PRODUCT_DATA_DEF, values));
    const caskedWhiskyData = mapUndefToNull(pickData(CASKED_WHISKY_PRODUCT_INFO_DEF, values));
    const { lpa } = values;
    return {
      ...baseProductData,
      alcoholDetail: {
        kind: "caskedWhisky",
        value: caskedWhiskyData,
      },
      vesselType: {
        kind: "cask",
        value: { lpa },
      },
      physicalDetails: null,
    };
  }
}

export function CaskedWhikyEditorForm(props: { form: FormikForm<CaskedWhiskyProductInfo> }) {
  const { form } = props;

  const uiBuilder = useMemo(() => {
    return new InputUiBuilder<CaskedWhiskyProductInfo>(CASKED_WHISKY_PRODUCT_INFO_DEF, form);
  }, [form]);

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      {uiBuilder.createTextField("code", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("name", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("producerName", { grid: { xs: 6 } })}
      {uiBuilder.createNumberField("lpa", { grid: { xs: 6 } })}
      {uiBuilder.createNumberField("alcoholByVolumePc", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<Country>("countryOfOrigin", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("regionOrigin", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("area", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<WhiskyLiquid>("liquid", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("make", { grid: { xs: 6 } })}
      {uiBuilder.createLocalDateField("aysDate", { maxDate: new Date(), grid: { xs: 6 } })}
      {uiBuilder.createPartialDateField("regaugedAt", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskFormat>("caskFormat", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<WhiskyStage>("stage", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskFill>("caskFill", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskComposition>("caskComposition", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskWood>("caskWood", { grid: { xs: 6 } })}
      {uiBuilder.createPartialDateField("filledAt", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskOrientation>("caskOrientation", { grid: { xs: 6 } })}
      {uiBuilder.createEnumField<CaskGeographicOrigin>("caskGeographicOrigin", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("caskNumber", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("distilleryProductionParcel", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("locationInfo", { grid: { xs: 6 } })}
      {uiBuilder.createTextField("additionalNotes", { grid: { xs: 6 } })}
    </Grid>
  );
}
