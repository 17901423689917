import React from "react";
import { Country, PhysicalDetails, ProductDate, ProductType, UnitType } from "adl-gen/ferovinum/app/db";
import { useInfoDrawer } from "../../layouts/info-drawer/info-drawer";
import { ProductMovement } from "../product-movement/product-movement";
import { VesselCapacity } from "utils/model-utils";
import { ProductSummary } from "components/widgets/product-summary/product-summary";
import { assertNotUndefined } from "utils/hx/util/types";

export interface OrganisationProductSummaryProps {
  code: string;
  productDate: ProductDate;
  name: string;
  producerName: string;
  vesselCapacity?: VesselCapacity;
  unitType?: UnitType;
  abv?: number | null;
  country?: Country;
  productId?: string;
  disabled?: boolean;
  includeAdditionalDetails?: {
    productType?: ProductType;
    regionOrigin?: string;
  };
  physicalDetails?: PhysicalDetails | null;
}

/** Displays a small summary of a product (To be used in table cells) */
export const OrganisationProductSummary = (props: OrganisationProductSummaryProps) => {
  const [openProductInfo] = useInfoDrawer();

  return (
    <ProductSummary
      {...props}
      onClick={
        props.productId !== undefined
          ? () => openProductInfo({ children: <ProductMovement productId={assertNotUndefined(props.productId)} /> })
          : undefined
      }
    />
  );
};
