import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { AvailablePdf, PdfType } from "adl-gen/ferovinum/app/uploads";
import { useAppService } from "app-client/src/hooks/use-app-service";
import { useConfirmationDialog } from "../../context/global-dialog/use-dialog";
import { Box } from "@mui/system";
import { Checkbox, FormControlLabel, IconButton, Popover } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useAdminUIRootRoute } from "app-client/src/hooks/use-admin-ui-root-route";
import { LoggedInContext } from "app-client/src/app/app";

export interface PdfDownloaderButtonProps {
  availablePdfs: AvailablePdf[];
}

export const PdfDownloader = ({ availablePdfs }: PdfDownloaderButtonProps) => {
  const service = useAppService();
  const appAdminUIHref = useAdminUIRootRoute();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<Record<PdfType, AvailablePdf | null>>(
    {} as Record<PdfType, AvailablePdf | null>,
  );
  const { showConfirmationDialog } = useConfirmationDialog();

  const loggedInUserContext = useContext(LoggedInContext);
  const userProfile = loggedInUserContext.loginState?.user?.profile;
  const userIsAdmin = userProfile && (userProfile.adminRole === "admin" || userProfile.adminRole === "superAdmin");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedFiles({} as Record<PdfType, AvailablePdf | null>);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, pdf: AvailablePdf) => {
    setSelectedFiles({
      ...selectedFiles,
      [pdf.pdfType]: event.target.checked ? pdf : null,
    });
  };

  const handleDownload = async () => {
    const selectedPdfs = Object.values(selectedFiles).filter((file): file is AvailablePdf => file !== null);

    for (const availablePdf of selectedPdfs) {
      const getDownloadUrlResp = await service.getDownloadUrl({
        downloadCategory: availablePdf.category,
        path: availablePdf.url,
      });

      if (getDownloadUrlResp.downloadUrl) {
        window.open(getDownloadUrlResp.downloadUrl, "_blank");
      }
    }

    handleClose();
  };

  const editPdf = async (availablePdf: AvailablePdf) => {
    const editUrl = `${appAdminUIHref}/value/invoice/${availablePdf.invoiceId}`;
    window.open(editUrl, "_blank");
  };

  const regeneratePdf = async (availablePdf: AvailablePdf) => {
    if (availablePdf.invoiceId) {
      const submit = await showConfirmationDialog({
        title: `Are you sure you want to regenerate this invoice? The previous invoice will be replaced and emails will be resent to recipients associated to the organization.`,
        confirmAction: {
          onClick: async () => true,
        },
      });

      if (submit.kind === "ok") {
        await service.generateInvoicePdf(availablePdf.invoiceId);
      }
    }
  };

  const isAnyCheckboxSelected = Object.values(selectedFiles).some(file => file !== null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!availablePdfs.length) {
    return <div></div>;
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        sx={{
          border: "2px solid #000",
          borderRadius: "25px",
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        startIcon={<DownloadIcon />}>
        Supporting Documents
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "left",
        }}>
        <Box sx={{ padding: "16px 16px 0 16px", display: "flex", flexDirection: "column" }}>
          {availablePdfs.map(pdf => (
            <Box
              key={pdf.pdfType}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                whiteSpace: "nowrap",
              }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(selectedFiles[pdf.pdfType])}
                    onChange={event => handleCheckboxChange(event, pdf)}
                  />
                }
                label={getLabel(pdf)}
              />

              {userIsAdmin && pdf.invoiceId !== null && (
                <Box>
                  <IconButton onClick={() => editPdf(pdf)} sx={{ marginRight: "8px" }}>
                    <EditIcon />
                  </IconButton>

                  <IconButton onClick={() => regeneratePdf(pdf)}>
                    <AutorenewIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "10px" }}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            sx={{
              borderRadius: "25px",
              padding: "8px 16px", // Add padding to the button
            }}
            onClick={handleDownload}
            disabled={!isAnyCheckboxSelected}>
            Download
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

function getLabel(availablePdf: AvailablePdf) {
  switch (availablePdf.pdfType) {
    case "SaleInvoice":
      return "Sale Invoice";
    case "DepositInvoice":
      return "Deposit Invoice";
    case "StockRevaluationInvoice":
      return "Stock Revaluation Invoice";
    case "DealRenewalInvoice":
      return "Deal Renewal Invoice";
    case "PurchaseOrder":
      return "Purchase Order";
    case "ProductionLossInvoice":
      return "Production Loss Invoice";
    case "DeliveryLossInvoice":
      return "Delivery Loss Invoice";
    case "PurchaseInvoice":
      return "Purchase Invoice";
    case "DeliveryOrder":
      return "Delivery Order";
    case "ContractNote":
      return "Contract Note";
    case "TradeSalesPurchaseOrder":
      return "Purchase Order";
    case "DeliveryInstructionDocument":
      return "Delivery Document Page " + availablePdf.page;
    default:
      return "PDF";
  }
}
