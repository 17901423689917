import * as React from "react";
import * as ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";
import { UiConfig } from "adl-gen/ferovinum/app/uiconfig";
import { App } from "./app/app";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppTheme } from "components/theme/app-theme";
import { AlertManager } from "components/context/global-alert/alert-manager";
import { GlobalAlert } from "components/context/global-alert/global-alert";
import { makeApiServices } from "adl-service/api-utils";
import { ScrollToTop } from "components/utils/scroll-to-top/scroll-to-top";
import { ErrorBoundary } from "./utils/error-boundary";
import { InfoDrawerManager } from "./ui/layouts/info-drawer/info-drawer";
import { GlobalDialog } from "components/context/global-dialog/global-dialog";
import { DialogManager } from "components/context/global-dialog/dialog-manager";
import { addMethod, string } from "yup";
import { LayoutInfoProvider } from "./ui/layouts/layout-info";
import Hotjar from "@hotjar/browser";
import { parseUiConfig } from "adl-service/ui-config";
import { HttpHeaders } from "utils/hx/service/http";

if (module.hot) {
  module.hot.accept();
}

const UI_CONFIG: UiConfig = parseUiConfig();

/* eslint-disable no-console */
console.log(`Environment: ${UI_CONFIG.environment}`);
console.log(`Release name: ${UI_CONFIG.releaseName}`);
/* eslint-enable no-console */

// initialize hotjar
const { hotjarConfig } = UI_CONFIG;
const hotjarSiteId = parseInt(hotjarConfig.siteId);
const hotjarVersion = parseInt(hotjarConfig.version);
if (!isNaN(hotjarSiteId) && !isNaN(hotjarVersion)) {
  Hotjar.init(hotjarSiteId, hotjarVersion);
}

// Add custom yup transform method
addMethod(string, "toNull", function toNull() {
  return this.transform(value => (value.trim().length ? value : null));
});

const handleNewVersionRelease = (headers: HttpHeaders) => {
  const serverReleaseName = headers["x-release-name"];
  const clientReleaseName = UI_CONFIG.releaseName;

  if (serverReleaseName && serverReleaseName !== clientReleaseName) {
    // eslint-disable-next-line no-console
    console.error(
      `Server release name ${serverReleaseName} does not match client release name ${clientReleaseName} will refresh the client code`,
    );
    //TODO(Berto): Update to a less intrusive refresh if releases happen often during users timezone
    // https://www.codemzy.com/blog/clients-reload-single-page-application-update
    window.location.reload();
  }
};
//Create an unauthenticated service for logins, and other public endpoints.
const service = makeApiServices();

function createApiServices(authToken?: string) {
  if (authToken === undefined) {
    return service;
  } else {
    return makeApiServices(authToken, handleNewVersionRelease);
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <InfoDrawerManager>
      <AlertManager>
        <DialogManager>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={AppTheme}>
              <LayoutInfoProvider>
                <CssBaseline />
                <Router>
                  <ScrollToTop />
                  <GlobalAlert />
                  <GlobalDialog />
                  <ErrorBoundary>
                    <App createApiServices={createApiServices} uiconfig={UI_CONFIG} />
                  </ErrorBoundary>
                </Router>
              </LayoutInfoProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </DialogManager>
      </AlertManager>
    </InfoDrawerManager>
  </React.StrictMode>,
  rootElement,
);
