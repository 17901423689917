import { DbKey, WithDbId } from "adl-gen/common/db";
import {
  NominatedPurchaserDetailsView,
  ProductSalePriceInfo,
  ThirdPartySaleFinishableProductListing,
  ThirdPartySaleFinishedProductListing,
} from "adl-gen/ferovinum/app/api";
import {
  Product,
  PurchaseRequestDeliveryOption,
  PurchaseRequestPaymentTermsPeriod,
  PurchaseRequestSalePriceType,
  StorageLocation,
} from "adl-gen/ferovinum/app/db";
import { useUiConfig } from "adl-service/hooks/use-ui-config";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { isValidNumber } from "utils/ts-utils";
import { isLoaded } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { PreparationDaysType } from "../organisation-purchase-request-delivery-options/organisation-purchase-request-delivery-options-page-view";
import { ProductionPlanPurchaseRequest } from "../organisation-purchase-request-production-plan/organisation-purchase-request-production-plan-page-view";
import {
  FormStorageLocProductSale,
  OrganisationPurchaseRequestSetupPageView,
  SetupPurchaserRequestFormValues,
} from "./organisation-purchase-request-setup-page-view";

export interface StorageLocationProductSale {
  product: WithDbId<Product>;
  unitPrice: number;
  priceDiscount?: number;
  paidUnits: number;
  freeUnits?: number;
  totalAvailable: number;
  totalProducible: number;
}

export interface PurchaseRequestFlowState {
  setup?: {
    storageLocation?: WithDbId<StorageLocation>;
    purchaser: NominatedPurchaserDetailsView;
    // Products that can be purchased directly
    products: StorageLocationProductSale[];
    dealDiscountAmount: string | undefined;
    bondedSale: boolean;
    // Sale price type is only relevant for out of bond sales
    salePriceType: PurchaseRequestSalePriceType;
    poFile?: File;
  };
  productionPlan?: ProductionPlanPurchaseRequest;
  delivery?: {
    customerPoRef?: string;
    shipmentNumber?: string;
    preparationDaysType: PreparationDaysType;
    preparationDays: number;
    deliveryOption: PurchaseRequestDeliveryOption;
    purchaserCoversDeliveryCost: boolean;
    paymentTermsPeriod: PurchaseRequestPaymentTermsPeriod;
    deliveryInstructionDocs?: File[];
  };
}

export interface StorageListing {
  storageLocation: WithDbId<StorageLocation>;
  products: ThirdPartySaleFinishableProductListing[] | ThirdPartySaleFinishedProductListing[];
}

export const OrganisationPurchaseRequestSetupPage = () => {
  const location = useLocation();
  const history = useHistory();
  const service = useAppService();
  const organisationId = assertNotUndefined(useSelectedOrgId());
  const { showProductionOrder } = useUiConfig();
  const purchaseRequestFlowState = location.state as PurchaseRequestFlowState | undefined;
  const [activeStorageLocation, setActiveStorageLocation] = useState<WithDbId<StorageLocation>>();
  const [storageLocations, setStorageLocations] = useState<WithDbId<StorageLocation>[]>([]);

  const loadFinishedProductListings = useCallback(async () => {
    const finishedProductListings = await service.getThirdPartySaleFinishedProductListings({
      organisationId: organisationId,
      searchTerm: null,
    });
    const finishedProductListingsArr: StorageListing[] = [];
    return finishedProductListings.reduce((acc, cur) => {
      acc.push({
        storageLocation: cur.key,
        products: cur.value,
      });
      return acc;
    }, finishedProductListingsArr);
  }, [service, organisationId]);

  const loadFinishableProductListings = useCallback(async () => {
    if (!showProductionOrder) {
      return [];
    }

    const finishableProductListings = await service.getThirdPartySaleFinishableProductListings({
      organisationId: organisationId,
      searchTerm: null,
    });

    const finishableProductListingsArr: StorageListing[] = [];
    return finishableProductListings.reduce((acc, cur) => {
      acc.push({
        storageLocation: cur.key,
        products: cur.value,
      });
      return acc;
    }, finishableProductListingsArr);
  }, [showProductionOrder, service, organisationId]);

  const loadProductSalePrices = useCallback(async () => {
    const productSalePrices = await service.getProductSalePrices({
      nominatedPurchaserId: purchaseRequestFlowState?.setup?.purchaser.nominatedPurchaserId ?? "",
    });
    return productSalePrices;
  }, [service, purchaseRequestFlowState?.setup?.purchaser.nominatedPurchaserId]);

  const [loadingFinishedProductListings] = useLoadingDataState<StorageListing[]>(loadFinishedProductListings);

  const [loadingFinishableProductListings] = useLoadingDataState<StorageListing[]>(loadFinishableProductListings);

  const [loadingProductSalePrices] = useLoadingDataState<ProductSalePriceInfo[]>(loadProductSalePrices);

  useEffect(() => {
    let newLocs: WithDbId<StorageLocation>[] = [];
    if (isLoaded(loadingFinishableProductListings)) {
      newLocs = [...newLocs, ...loadingFinishableProductListings.value.map(e => e.storageLocation)];
    }
    if (isLoaded(loadingFinishedProductListings)) {
      newLocs = [...newLocs, ...loadingFinishedProductListings.value.map(e => e.storageLocation)];
    }

    const uniqueIdsSet = new Set<DbKey<StorageLocation>>(storageLocations.map(e => e.id));
    const newStorageLocations: WithDbId<StorageLocation>[] = [...storageLocations];
    newLocs.forEach(e => {
      if (!uniqueIdsSet.has(e.id)) {
        uniqueIdsSet.add(e.id);
        newStorageLocations.push(e);
      }
    });

    if (newStorageLocations.length === storageLocations.length) {
      // Break claus - If this is an empty set we can assume that all
      // locations are loaded and we don't need to do anything
      return;
    }

    setStorageLocations(newStorageLocations);
    const previousStorageLocation = purchaseRequestFlowState?.setup?.storageLocation;
    const maybeStorageLocation = newStorageLocations.find(loc => loc.id === previousStorageLocation?.id);
    if (maybeStorageLocation) {
      setActiveStorageLocation(maybeStorageLocation);
    }
  }, [
    loadingFinishableProductListings,
    loadingFinishedProductListings,
    purchaseRequestFlowState?.setup?.storageLocation,
    storageLocations,
  ]);

  const onNext = async (values: SetupPurchaserRequestFormValues) => {
    const filterSelectedProducts = (products: FormStorageLocProductSale[]) => {
      return products
        .filter(p => (isValidNumber(p.paidUnits) && isValidNumber(p.unitPrice)) || isValidNumber(p.freeUnits))
        .map(p => ({
          ...p,
          paidUnits: p.paidUnits ? Number(p.paidUnits) : 0,
          freeUnits: p.freeUnits !== undefined ? Number(p.freeUnits) : undefined,
          unitPrice: p.unitPrice ? Number(p.unitPrice) : 0,
          priceDiscount: p.priceDiscount !== undefined ? Number(p.priceDiscount) : undefined,
        }));
    };
    const newState: PurchaseRequestFlowState = {
      ...purchaseRequestFlowState,
      // Note: For now reset the production plan if there was any
      // too much hassle to check if the previous plan is still compatible or not if they change the sources
      productionPlan: undefined,
      setup: {
        storageLocation: assertNotUndefined(activeStorageLocation),
        purchaser: assertNotUndefined(purchaseRequestFlowState?.setup?.purchaser),
        products: filterSelectedProducts(values.products),
        dealDiscountAmount: values.dealDiscountAmount,
        bondedSale: values.bondedSale,
        // Sale price type is only relevant for out of bond sales. For in bond sales, the price is always excluding duty
        salePriceType: values.bondedSale === true ? "exDutyAndVat" : values.salePriceType,
        poFile: values.poFile,
      },
    };
    if (extractNonFinishedProducts(newState.setup?.products ?? []).length > 0) {
      history.push(AppRoutes.OrganisationPurchaseRequestProductionPlan, newState);
    } else {
      // reset production plan as it might not be applicable anymore
      newState.productionPlan = undefined;
      history.push(AppRoutes.OrganisationPurchaseRequestDeliveryOptions, newState);
    }
  };

  const onChangeStorageLocation = async (loc: WithDbId<StorageLocation> | null) => {
    if (purchaseRequestFlowState?.setup) {
      purchaseRequestFlowState.setup.products = [];
    }
    setActiveStorageLocation(loc === null ? undefined : loc);
  };

  return (
    <OrganisationPurchaseRequestSetupPageView
      selectedPurchaser={purchaseRequestFlowState?.setup?.purchaser}
      activeStorageLocation={activeStorageLocation}
      storageLocations={storageLocations}
      onChangeStorageLocation={onChangeStorageLocation}
      initialValues={purchaseRequestFlowState?.setup}
      onNext={onNext}
      showProductionOrders={showProductionOrder}
      finishableProducts={loadingFinishableProductListings}
      finishedProducts={loadingFinishedProductListings}
      productsDefaultSalePrices={loadingProductSalePrices}
    />
  );
};

export function extractNonFinishedProducts(products: StorageLocationProductSale[]): StorageLocationProductSale[] {
  return products
    .map(p => ({ ...p, ...splitQuantities(p).produced }))
    .filter(p => p.paidUnits + (p.freeUnits ?? 0) > 0);
}

export function extractFinishedProducts(products: StorageLocationProductSale[]): StorageLocationProductSale[] {
  return products.map(p => ({ ...p, ...splitQuantities(p).finished }));
}

interface ProductQuantities {
  paidUnits: number;
  freeUnits?: number;
}

function splitQuantities({ paidUnits, freeUnits, totalAvailable, totalProducible }: StorageLocationProductSale): {
  finished: ProductQuantities;
  produced: ProductQuantities;
} {
  // the split must add up to paidUnits
  const paidQtySplit =
    paidUnits <= totalAvailable
      ? { finished: paidUnits, produced: 0 }
      : { finished: totalAvailable, produced: paidUnits - totalAvailable };

  const availableAfterPaidQty = totalAvailable - paidQtySplit.finished;
  // the split must add up to freeUnits
  const freeQtySplit =
    freeUnits == undefined
      ? undefined
      : freeUnits <= availableAfterPaidQty
      ? { finished: freeUnits, produced: 0 }
      : { finished: availableAfterPaidQty, produced: freeUnits - availableAfterPaidQty };

  if (paidQtySplit.produced + (freeQtySplit?.produced ?? 0) > totalProducible) {
    throw new Error("Produced quantity exceeds total producible quantity");
  }
  return {
    finished: { paidUnits: paidQtySplit.finished, freeUnits: freeQtySplit?.finished },
    produced: { paidUnits: paidQtySplit.produced, freeUnits: freeQtySplit?.produced },
  };
}
