import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { WithDbId } from "adl-gen/common/db";
import { Product } from "adl-gen/ferovinum/app/db";
import { Link } from "components/widgets/link/link";
import { throttle } from "lodash";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { OrganisationProductSummary } from "../organisation-product-summary/organisation-product-summary";

interface ProductSearchProps {
  searchProducts: (term: string) => Promise<WithDbId<Product>[]>;
  selectedProductCodes: Set<string>;
  onSelectProduct: (product: WithDbId<Product>) => void;
  onAddNewProduct?: () => void;
  disabled?: boolean;
}

export const ProductSearch = ({
  searchProducts,
  selectedProductCodes,
  onSelectProduct,
  onAddNewProduct,
  disabled,
}: ProductSearchProps) => {
  const MIN_SEARCH_TERM_LENGTH = 3;
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<WithDbId<Product> | null>(null);
  const [options, setOptions] = useState<readonly WithDbId<Product>[]>([]);
  const [loading, setLoading] = useState(false);
  const filterOptions = useCallback(
    (products: WithDbId<Product>[]) => products.filter(p => !selectedProductCodes.has(p.value.code)),
    [selectedProductCodes],
  );

  const fetch = React.useMemo(
    () =>
      throttle(async (searchTerm: string): Promise<WithDbId<Product>[]> => {
        setLoading(true);
        const result = await searchProducts(searchTerm);
        setLoading(false);
        return result;
      }, 200),
    [searchProducts],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue.trim().length < MIN_SEARCH_TERM_LENGTH) {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue)?.then(results => {
      if (active) {
        setOptions([...results]);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const open = inputValue.trim().length >= MIN_SEARCH_TERM_LENGTH;

  return (
    <Autocomplete<WithDbId<Product>>
      disabled={disabled}
      filterOptions={filterOptions}
      noOptionsText={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">{"Product could not be found."}</Typography>
          {onAddNewProduct && (
            <Link
              color="grey5"
              onClick={() => {
                onAddNewProduct();
                setInputValue("");
              }}>
              Add new product
            </Link>
          )}
        </Stack>
      }
      inputValue={inputValue}
      getOptionLabel={p => p.value.name}
      loading={loading}
      value={value}
      renderInput={args => (
        <TextField
          {...args}
          label="Search by product code, name or producer"
          fullWidth
          InputProps={{
            ...args.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {args.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      options={options}
      onInputChange={(_event: SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onChange={(_, product) => {
        if (product) {
          setValue(null);
          setInputValue("");
          onSelectProduct(product);
        }
      }}
      renderOption={(props, product) => {
        const { code, name, alcoholByVolumePc, countryOfOrigin } = product.value;
        // @ts-ignore
        const isLastOption = props["data-option-index"] === options.length - 1;
        return (
          <React.Fragment key={`${code}-${name}`}>
            <ListItem {...props} sx={{ pl: 4 }}>
              <Box sx={{ p: 2 }}>
                <OrganisationProductSummary {...product.value} abv={alcoholByVolumePc} country={countryOfOrigin} />
              </Box>
            </ListItem>

            <Divider sx={{ display: isLastOption ? "none" : "block" }} />

            {isLastOption && (
              <Paper sx={{ pl: 4, paddingY: 2 }} elevation={5}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1" color="common.grey6">
                    {"Can't find what you're looking for?"}
                  </Typography>
                  {onAddNewProduct && (
                    <Link
                      color="grey5"
                      onClick={() => {
                        onAddNewProduct();
                        setInputValue("");
                      }}>
                      Add new product
                    </Link>
                  )}
                </Stack>
              </Paper>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
