import { Grid, Box } from "@mui/material";
import { FerovinumLogo } from "components/logos/logos";
import React from "react";

export interface PublicPageLayoutProps {
  children: React.ReactNode;
  imageURL: string;
  showMobileLogo?: boolean;
}

/** Page layout used in public pages accessible by not logged-in users */
export const PublicPageLayout = ({ children, imageURL, showMobileLogo = true }: PublicPageLayoutProps) => {
  return (
    <Grid container sx={{ minHeight: "100vh" }}>
      {/* Left side: Image and Logo (hidden on smaller screens) */}
      <Grid
        item
        xs={6}
        sx={{
          display: { xs: "none", lg: "flex" }, // Hide on small screens
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        <Box sx={{ position: "absolute", paddingRight: "40px", bottom: "30px" }}>
          <FerovinumLogo color="white" />
        </Box>

        <Box
          component="img"
          src={imageURL}
          sx={{
            objectFit: "cover",
            maxWidth: "100%",
            minHeight: "100%",
          }}
        />
      </Grid>

      {/* Right side: Content */}
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          paddingLeft: { xs: "20px", sm: "125px" },
          paddingRight: { xs: "20px", sm: "125px" },
          alignItems: "center",
          justifyContent: "center",
        }}>
        {showMobileLogo && (
          <Box
            sx={{
              display: { xs: "block", lg: "none" },
              position: "absolute",
              top: "30px",
              left: "50%",
              transform: "translateX(-50%)",
            }}>
            <FerovinumLogo />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
