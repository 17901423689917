import { BigDecimal, LocalDate } from "adl-gen/common";
import { PurchaseRequestReportData } from "adl-gen/ferovinum/app/api";
import { PurchaseRequest } from "adl-gen/ferovinum/app/db";
import { camelCaseToReadableFormat, downloadObjectsToCSV, CSVDownloadRowData } from "utils/csv-utils";
import { daysOverdue, formatInstant, formatLocalDate } from "utils/date-utils";
import { correctFloatPrecision } from "utils/numeric-utils";

export interface PurchaseRequestCSVData {
  tradeSaleReferenceNo: CSVDownloadRowData;
  customerOrderNumber: CSVDownloadRowData;
  currentStatus: CSVDownloadRowData;
  purchaser: CSVDownloadRowData;
  invoiceCurrency: CSVDownloadRowData;
  settlementCurrency: CSVDownloadRowData;
  goodsValueExcludingVatAndDuty: CSVDownloadRowData;
  vat: CSVDownloadRowData;
  duty: CSVDownloadRowData;
  totalInvoiceValue: CSVDownloadRowData;
  dateRaised: CSVDownloadRowData;
  datePurchaserAccepted: CSVDownloadRowData;
  collectedDate: CSVDownloadRowData;
  deliveredDate: CSVDownloadRowData;
  advanceUponDeliveryAmount: CSVDownloadRowData;
  advanceUponDeliveryPaymentDate: CSVDownloadRowData;
  invoiceDueDate: CSVDownloadRowData;
  invoicePaidAmount: CSVDownloadRowData;
  invoiceLastPaymentDate: CSVDownloadRowData;
  invoiceOutstandingAmount: CSVDownloadRowData;
  invoiceOverdueBalance: CSVDownloadRowData;
  overdueInvoiceDays: CSVDownloadRowData;
  finalAdvanceUponDeliveryAmount: CSVDownloadRowData;
  finalAdvanceUponDeliveryPaymentDate: CSVDownloadRowData;
  finalAdvanceUponDeliveryPaymentActualAmount: CSVDownloadRowData;
}

const findEventDate = (pr: PurchaseRequest, eventType: string): string | undefined => {
  const eventTimestamp = pr.stateEvents.find(event => event.state === eventType)?.time;
  return eventTimestamp ? formatInstant(eventTimestamp) : undefined;
};

const formatDate = (date: LocalDate | null | undefined): string => {
  return date ? formatLocalDate(date) : "";
};

const processPurchaseRequestViewData = (reportData: PurchaseRequestReportData): PurchaseRequestCSVData => {
  const pr = reportData.purchaseRequest;
  const overdueDays = daysOverdue(reportData.invoiceDueDate);

  const formatCurrencyValue = (value: BigDecimal | null | undefined): string => {
    return value ? correctFloatPrecision(Number(value), 2).toString() : "";
  };

  return {
    tradeSaleReferenceNo: { value: pr.purchaseRequestNumber },
    customerOrderNumber: { value: pr.customerPoRef ?? "" },
    currentStatus: { value: camelCaseToReadableFormat(pr.state) },
    purchaser: { value: reportData.purchaserName },
    invoiceCurrency: { value: pr.purchaserCurrency },
    settlementCurrency: { value: pr.settlementCurrency },
    goodsValueExcludingVatAndDuty: {
      value: formatCurrencyValue(reportData.goodsValueExVatExDuty),
      header: "Goods Value Excluding Vat And Duty (Invoice Currency)",
    },
    vat: {
      value: formatCurrencyValue(pr.dutyAndVat?.vat),
      header: "VAT (Invoice Currency)",
    },
    duty: {
      value: formatCurrencyValue(pr.dutyAndVat?.duty),
      header: "Duty (Invoice Currency)",
    },
    totalInvoiceValue: {
      value: formatCurrencyValue(reportData.purchaserInvoiceAmount),
      header: "Total Invoice Value (Invoice Currency)",
    },
    dateRaised: { value: findEventDate(pr, "new") ?? "" },
    datePurchaserAccepted: { value: findEventDate(pr, "purchaserAccepted") ?? "" },
    collectedDate: { value: formatDate(pr.collectedDate) },
    deliveredDate: { value: formatDate(pr.deliveredDate) },
    advanceUponDeliveryAmount: {
      value: formatCurrencyValue(reportData.advanceUponDeliveryAmount),
      header: "Advance Upon Delivery Amount (Settlement Currency)",
    },
    advanceUponDeliveryPaymentDate: {
      value: reportData.advanceUponDeliveryAmount ? formatDate(pr.advanceUponDeliveryPaymentDate) : "",
    },
    invoiceDueDate: { value: formatDate(reportData.invoiceDueDate) },
    invoicePaidAmount: {
      value: formatCurrencyValue(reportData.invoicePaidAmount),
      header: "Invoice Paid Amount (Invoice Currency)",
    },
    invoiceLastPaymentDate: { value: pr.paidAt ? formatInstant(pr.paidAt) : "" },
    invoiceOutstandingAmount: {
      value: formatCurrencyValue(reportData.invoiceOutstandingAmount),
      header: "Invoice Outstanding Amount (Invoice Currency)",
    },
    invoiceOverdueBalance: {
      value: formatCurrencyValue(reportData.invoiceOverdueBalance),
      header: "Invoice Overdue Balance (Invoice Currency)",
    },
    overdueInvoiceDays: { value: overdueDays > 0 ? overdueDays.toString() : "" },
    finalAdvanceUponDeliveryAmount: {
      value: formatCurrencyValue(reportData.finalAdvanceUponDeliveryAmount),
      header: "Final balance due from (to) Ferovinum upon invoice payment: Expected Amount (Settlement Currency)",
    },
    finalAdvanceUponDeliveryPaymentDate: {
      value: reportData.advanceUponDeliveryAmount ? formatDate(pr.advanceUponDeliveryPaymentDate) : "",
      header: "Final balance due from (to) Ferovinum upon invoice payment: Payment Date",
    },
    finalAdvanceUponDeliveryPaymentActualAmount: {
      value: reportData.advanceUponDeliveryAmount
        ? formatCurrencyValue(reportData.finalAdvanceUponDeliveryPaymentActualAmount)
        : "",
      header: "Final balance due from (to) Ferovinum upon invoice payment: Payment Actual Amount (Settlement Currency)",
    },
  };
};

export function downloadTPSDataToCSV(purchaseRequests: PurchaseRequestReportData[], filePrefix: string) {
  downloadObjectsToCSV<PurchaseRequestReportData, PurchaseRequestCSVData>(
    purchaseRequests,
    processPurchaseRequestViewData,
    filePrefix,
  );
}
