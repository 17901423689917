import { Stack } from "@mui/material";
import React from "react";
import { PurchaserPurchaseRequestData } from "../../../../hooks/use-purchase-request";
import { DeliveryDetailsCard } from "../../../widgets/card/delivery-details-card/delivery-details-card";
import { PurchaseRequestCollection } from "../../../widgets/card/purchase-request-collection/purchase-request-collection";

export const RelevantInfoStickyNotes = ({
  purchaseRequest,
}: {
  purchaseRequest: Pick<
    PurchaserPurchaseRequestData,
    "storageLocation" | "purchaseRequestDeliveryOption" | "paymentTermsPeriod" | "terms"
  >;
}) => {
  const { storageLocation, purchaseRequestDeliveryOption } = purchaseRequest;
  return (
    <Stack spacing={4} display={"flex"} flex={1} height={"100%"}>
      {purchaseRequestDeliveryOption.kind === "collectFromStorageLocation" && (
        <PurchaseRequestCollection
          locationName={storageLocation.name}
          {...storageLocation}
          {...purchaseRequestDeliveryOption.value.nominatedPurchaserCollection}
          sx={{ flex: 1 }}
        />
      )}
      {purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
        <DeliveryDetailsCard {...purchaseRequestDeliveryOption.value.deliveryDetails} sx={{ flex: 1 }} />
      )}
    </Stack>
  );
};
