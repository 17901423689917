import { Alert, Stack, Tab, Tabs } from "@mui/material";
import { PageHeader } from "components/library/widgets/page-header";
import { ButtonWithDatePickerDialog } from "components/widgets/buttons/button-with-date-picker-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import React, { useMemo, useState } from "react";
import { formatDateToLocalDate } from "utils/date-utils";
import { OrganisationPurchaseRequestData } from "../../../../../hooks/use-purchase-request";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { OrganisationPurchaseRequestProgressSection } from "../../../../widgets/purchase-requests/organisation-purchase-request-progress-section/organisation-purchase-request-progress-section";
import { OrganisationPurchaseRequestSummarySection } from "../../../../widgets/purchase-requests/organisation-purchase-request-summary-section/organisation-purchase-request-summary-section";
import { OrganisationPurchaserSummarySection } from "../../../../widgets/purchase-requests/organisation-purchaser-summary-section/organisation-purchaser-summary-section";
import { PurchaseRequestAdminAction } from "../organisation-purchase-requests-by-purchaser/organisation-purchase-requests-by-purchaser-page";
import { PdfDownloader } from "components/widgets/buttons/pdf-downloader";

export interface OrganisationPurchaseRequestDetailsPageViewProps {
  purchaseRequestData: OrganisationPurchaseRequestData;
  onMarkReadyForCollection: () => Promise<void>;
  onAdminAction?: (action: PurchaseRequestAdminAction) => Promise<void>;
}

type Sections = "progress" | "order-summary" | "purchaser-summary";
export const OrganisationPurchaseRequestDetailsPageView = ({
  purchaseRequestData,
  ...actionCallbacks
}: OrganisationPurchaseRequestDetailsPageViewProps) => {
  const [selectedTab, setSelectedTab] = useState<Sections>("progress");

  return (
    <PortalPageContent header={<OrganisationPurchaseRequestDetailsHeader purchaseRequest={purchaseRequestData} />}>
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Tabs value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
            <Tab label="Progress" value="progress" />
            <Tab label="Order Summary" value="order-summary" />
            <Tab label="Purchaser Summary" value="purchaser-summary" />
          </Tabs>

          <Stack direction="row" spacing={2}>
            <PdfDownloader availablePdfs={purchaseRequestData.availablePdfs} />
          </Stack>
        </Stack>

        {selectedTab === "progress" && <OrganisationPurchaseRequestProgressSection {...purchaseRequestData} />}

        {selectedTab === "order-summary" && <OrganisationPurchaseRequestSummarySection {...purchaseRequestData} />}

        {selectedTab === "purchaser-summary" && (
          <OrganisationPurchaserSummarySection purchaseRequestData={purchaseRequestData} />
        )}

        <ActionButtons purchaseRequest={purchaseRequestData} {...actionCallbacks} />
      </Stack>
    </PortalPageContent>
  );
};

interface OrganisationPurchaserRequestDetailsHeaderProps {
  purchaseRequest: OrganisationPurchaseRequestData;
}

const OrganisationPurchaseRequestDetailsHeader = ({
  purchaseRequest,
}: OrganisationPurchaserRequestDetailsHeaderProps) => {
  const { stateEvents, purchaserName, purchaseRequestNumber } = purchaseRequest;
  const customerOrderNumber = purchaseRequest.summary.customerPoRef;
  const shipmentNumber = purchaseRequest.summary.shipmentNumber;
  const lastState = stateEvents[stateEvents.length - 1]?.state || "";
  const baseTitle = "Third Party Sale Order";
  const warning = lastState === "expired" ? "Expired " : lastState === "purchaserRejected" ? "Rejected " : "";

  const titledTexts = [
    {
      title: "Reference Number",
      text: purchaseRequestNumber,
    },
  ];
  if (customerOrderNumber) {
    titledTexts.push({
      title: "Customer Order Number",
      text: customerOrderNumber,
    });
  }
  if (shipmentNumber) {
    titledTexts.push({
      title: "Shipment Number",
      text: shipmentNumber,
    });
  }
  return (
    <PageHeader
      title={warning + baseTitle}
      subtitle={"from " + purchaserName}
      right={{
        type: "titledText",
        titledTexts: titledTexts,
      }}
    />
  );
};

interface ActionButtonsProps {
  purchaseRequest: OrganisationPurchaseRequestData;
  onMarkReadyForCollection: () => Promise<void>;
  onAdminAction?: (action: PurchaseRequestAdminAction) => Promise<void>;
}

const ActionButtons = ({ purchaseRequest, onMarkReadyForCollection, onAdminAction }: ActionButtonsProps) => {
  const { state, stateEvents, productionOrderReference } = purchaseRequest;

  // It can be marked as ready for collection if it has been accepted by the purchaser and there is no production order
  // or if the linked production order is complete
  const canBeMarkedAsReadyForCollection =
    (state === "purchaserAccepted" && productionOrderReference === undefined) || state === "productionOrderComplete";
  const events = useMemo(() => {
    return {
      readyForCollectionEvent: stateEvents.find(events => events.state === "readyForCollection"),
      collectedEvent: stateEvents.find(events => events.state === "collected"),
      purchaserInvoicedEvent: stateEvents.find(events => events.state === "purchaserInvoiced"),
    };
  }, [stateEvents]);
  const collectedDate = purchaseRequest.collectedDate ?? events.collectedEvent?.time;
  return (
    <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
      <>
        {canBeMarkedAsReadyForCollection && (
          <LoadingActionButton onClick={onMarkReadyForCollection}>Mark as ready for collection</LoadingActionButton>
        )}

        {onAdminAction && (
          <>
            {state === "readyForCollection" && (
              <ButtonWithDatePickerDialog
                buttonLabel="Mark as collected"
                color="secondary"
                dateFieldLabel="Collected at"
                minDate={events.readyForCollectionEvent && new Date(events.readyForCollectionEvent.time)}
                maxDate={new Date()}
                onConfirm={date => onAdminAction({ action: "markCollected", collectedAt: formatDateToLocalDate(date) })}
              />
            )}

            {state === "collected" &&
              purchaseRequest.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
                <ButtonWithDatePickerDialog
                  buttonLabel="Mark as delivered"
                  color="secondary"
                  dateFieldLabel="Delivered at"
                  minDate={collectedDate ? new Date(collectedDate) : undefined}
                  maxDate={new Date()}
                  onConfirm={date =>
                    onAdminAction({ action: "markDelivered", deliveredAt: formatDateToLocalDate(date) })
                  }
                />
              )}

            {state === "purchaserInvoiced" && (
              <ButtonWithDatePickerDialog
                buttonLabel="Purchaser has paid"
                color="secondary"
                onConfirm={date => onAdminAction({ action: "markPurchaserPaid", paidAt: date, fxRate: null })}
                dialogTitle="Purchase Paid State Information"
                dateFieldLabel="When did the purchaser pay?"
                minDate={events.purchaserInvoicedEvent && new Date(events.purchaserInvoicedEvent.time)}
                maxDate={new Date()}
              />
            )}

            {state === "purchaserPaid" && (
              <Alert severity="info">You can mark organisation as paid in Admin Portal - Treasury</Alert>
            )}
          </>
        )}
      </>
    </Stack>
  );
};
