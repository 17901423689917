import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { CollectionDetails, PurchaseRequestLineItemForPurchaser } from "adl-gen/ferovinum/app/api";
import { Currency, PurchaseRequestSalePriceType } from "adl-gen/ferovinum/app/db";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import {
  useConfirmationDialog,
  useTermsDialog,
  useTradeSaleTermsDialog,
} from "components/context/global-dialog/use-dialog";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Link } from "components/widgets/link/link";
import React, { useCallback, useState } from "react";
import { getVesselCapacity } from "utils/model-utils";
import { PurchaserPurchaseRequestData } from "../../../../hooks/use-purchase-request";
import { formatDiscountPct, getIncoterms } from "../../../../utils/purchase-request-utils";
import { PortalPageContentHeader } from "../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { PurchaseRequestTermsCard } from "../../../widgets/card/purchase-request-terms/purchase-request-terms-card";
import { ColGroup } from "../../../widgets/colgroup";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { buildSalePriceSuffix } from "../../../widgets/purchase-requests/organisation-product-selection-table/organisation-product-selection-table";
import { PurchaseRequestStepper } from "../../../widgets/purchase-requests/purchase-request-stepper/purchase-requests-stepper";
import { AcceptPurchaseRequestCollectionDialog } from "../../../widgets/purchase-requests/purchaser/accept-purchase-request-dialog/accept-purchase-request-collection-dialog";
import { AcceptPurchaseRequestDeliveryDialog } from "../../../widgets/purchase-requests/purchaser/accept-purchase-request-dialog/accept-purchase-request-delivery-dialog";
import { RejectedPurchaseRequestValuationCard } from "../../../widgets/purchase-requests/rejected-purchase-request-valuation-card/rejected-purchase-request-valuation-card";
import { useIsPaymentOverdue } from "../hooks/purchaser-payment-request-hooks";
import { RelevantPartiesStickyNotes, RelevantInfoStickyNotes } from "../components";

export interface PurchaserPurchaseRequestDetailsPageViewProps {
  purchaseRequestData: PurchaserPurchaseRequestData;

  onTermsAccepted(collectionDetails: CollectionDetails | null): Promise<void>;

  onTermsRejected(): Promise<void>;
}

export const PurchaserPurchaseRequestDetailsPageView = ({
  purchaseRequestData,
  onTermsAccepted,
  onTermsRejected,
}: PurchaserPurchaseRequestDetailsPageViewProps) => {
  const isPaymentOverdue = useIsPaymentOverdue(purchaseRequestData);
  const { showTermsDialog } = useTermsDialog();
  const onShowTerms = useCallback(async () => {
    await showTermsDialog({
      terms: [purchaseRequestData.terms.terms],
    });
  }, [purchaseRequestData.terms.terms, showTermsDialog]);
  const renderPurchaseRequestTermsStickyNote = () => (
    <PurchaseRequestTermsCard
      paymentTermsPeriod={purchaseRequestData.paymentTermsPeriod}
      showTerms={async () => {
        await showTermsDialog({
          terms: [purchaseRequestData.terms.terms],
        });
      }}
      incoterms={getIncoterms(purchaseRequestData)}
      sx={{ flex: 1 }}
    />
  );
  return (
    <PortalPageContent header={<PurchaserPurchaseRequestDetailsHeader purchaseRequest={purchaseRequestData} />}>
      <Grid container spacing={4}>
        {isPaymentOverdue && (
          <Grid item xs={12}>
            <Alert severity="error">
              Payment is overdue. Please make a payment to Ferovinum. For more information,{" "}
              <Link href={FEROVINUM_CONTACT_URL} target="_blank" typographyProps={{ sx: { display: "inline" } }}>
                contact us.
              </Link>
            </Alert>
          </Grid>
        )}
        <PurchaserPurchaseRequestDetailsInnerView purchaseRequestData={purchaseRequestData} />
        <Grid item xs={12} lg={4}>
          <Stack spacing={4}>
            <ProgressStepperCard purchaseRequest={purchaseRequestData} />
            {purchaseRequestData.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" &&
              renderPurchaseRequestTermsStickyNote()}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={4} display={"flex"} flex={1} height={"100%"}>
            <RelevantPartiesStickyNotes purchaseRequest={purchaseRequestData} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={4} display={"flex"} flex={1} height={"100%"}>
            <RelevantInfoStickyNotes purchaseRequest={{ ...purchaseRequestData }} />
            {/* TODO move this into relevant info sticky */}
            {purchaseRequestData.purchaseRequestDeliveryOption.kind === "collectFromStorageLocation" &&
              renderPurchaseRequestTermsStickyNote()}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ActionButtons
            purchaseRequest={purchaseRequestData}
            showTermsDialog={onShowTerms}
            onTermsRejected={onTermsRejected}
            onTermsAccepted={onTermsAccepted}
          />
        </Grid>
      </Grid>
    </PortalPageContent>
  );
};

export const PurchaserPurchaseRequestDetailsInnerView = ({
  purchaseRequestData,
}: {
  purchaseRequestData: Pick<
    PurchaserPurchaseRequestData,
    "state" | "salePriceType" | "breakdown" | "purchaserCurrency" | "purchaserCoversDeliveryCost"
  >;
}) => {
  const isRejectedOrExpired =
    purchaseRequestData.state === "expired" || purchaseRequestData.state === "purchaserRejected";

  // If the sale price type is excluding duty, we need to show the duty line item
  const showDuty = purchaseRequestData.salePriceType === "exDutyAndVat";
  const subtotalIncVat = purchaseRequestData.salePriceType === "incDutyAndVat";
  return (
    <>
      <Grid item xs={12}>
        <PurchaserPurchaseRequestSummaryTable
          products={purchaseRequestData.breakdown.lineItems}
          currency={purchaseRequestData.purchaserCurrency}
          salePriceType={purchaseRequestData.salePriceType}
        />
        {isRejectedOrExpired ? (
          <RejectedPurchaseRequestValuationCard
            {...purchaseRequestData.breakdown}
            purchaserCurrency={purchaseRequestData.purchaserCurrency}
            showDuty={showDuty}
            subtotalIncVat={subtotalIncVat}
          />
        ) : (
          <PurchaserPurchaseRequestValuationCard
            {...purchaseRequestData.breakdown}
            purchaserCoversDeliveryCost={purchaseRequestData.purchaserCoversDeliveryCost}
            currency={purchaseRequestData.purchaserCurrency}
            showDuty={showDuty}
            subtotalIncVat={subtotalIncVat}
            salePriceType={purchaseRequestData.salePriceType}
          />
        )}
      </Grid>
    </>
  );
};

const PurchaserPurchaseRequestDetailsHeader = ({
  purchaseRequest,
}: {
  purchaseRequest: PurchaserPurchaseRequestData;
}) => {
  const { stateEvents, organisationName, purchaseRequestNumber } = purchaseRequest;
  const { showTradeSaleTermsDialog } = useTradeSaleTermsDialog();

  const lastState = stateEvents[stateEvents.length - 1]?.state || "";
  const baseTitle = "Third Party Sale Order";
  const warning = lastState === "expired" ? "Expired " : lastState === "purchaserRejected" ? "Rejected " : "";
  const title = (
    <Stack spacing={2}>
      <Stack>
        <Typography variant={"h4"}>{warning + baseTitle}</Typography>
        <Typography>from {organisationName}</Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="body2">
          Unless agreed otherwise in writing, this sale is subject to the{" "}
          <Link onClick={showTradeSaleTermsDialog} variant="body2" typographyProps={{ sx: { display: "inline" } }}>
            Ferovinum General Terms and Conditions
          </Link>{" "}
          as amended from time to time. Notwithstanding anything in the above, title to the goods shall not pass from
          Ferovinum to the buyer until the buyer has paid Ferovinum in full
        </Typography>
      </Stack>
    </Stack>
  );
  return (
    <PortalPageContentHeader
      variant="split"
      title={title}
      right={
        <Stack width={"100%"} textAlign={"right"}>
          <Typography sx={{ fontSize: 12, color: "common.grey5" }}>Reference Number</Typography>
          <Typography variant="subtitle1Bold">{purchaseRequestNumber}</Typography>
        </Stack>
      }
    />
  );
};

const ProgressStepperCard = ({
  purchaseRequest,
}: {
  purchaseRequest: Pick<
    PurchaserPurchaseRequestData,
    | "timeline"
    | "stateEvents"
    | "paymentTermsPeriod"
    | "collectionDays"
    | "state"
    | "purchaseRequestDeliveryOption"
    | "purchaserName"
    | "collectedDate"
    | "deliveredDate"
  >;
}) => {
  return (
    <Card>
      <Stack divider={<Divider />} height="100%">
        <Typography variant="caption" padding={2} paddingTop={1} paddingBottom={1} color="common.grey5">
          Status
        </Typography>
        <Box paddingLeft={2} paddingBottom={2}>
          <PurchaseRequestStepper purchaseRequest={{ ...purchaseRequest }} timeline={purchaseRequest.timeline} />
        </Box>
      </Stack>
    </Card>
  );
};

const PurchaserPurchaseRequestSummaryTable = ({
  products,
  currency,
  salePriceType,
}: {
  products: PurchaseRequestLineItemForPurchaser[];
  currency: Currency;
  salePriceType: PurchaseRequestSalePriceType;
}) => {
  const hasFreeUnit = products.some(product => product.freeUnits != null);
  const hasDiscount = products.some(product => product.priceDiscount != null);

  return (
    <Table sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
      <ColGroup widths={[...[50, 20, 20, 20], ...(hasDiscount ? [20] : [])]} />
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <SummaryTableCell textAlign="center">Quantity</SummaryTableCell>
          <SummaryTableCell textAlign="center">
            Purchase Price Per
            <br />
            {hasFreeUnit ? " Paid " : " "}Unit ({buildSalePriceSuffix(salePriceType)})
          </SummaryTableCell>
          {hasDiscount && (
            <SummaryTableCell textAlign="center">
              Unit Discount Included
              <br />
              <Typography variant="caption">% of unit price</Typography>
            </SummaryTableCell>
          )}
          <SummaryTableCell paddingRight>Subtotal</SummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map(productData => (
          <PurchaseRequestSummaryTableRow
            key={productData.product.id}
            productData={productData}
            currency={currency}
            hasDiscount={hasDiscount}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const PurchaseRequestSummaryTableRow = ({
  productData,
  currency,
  hasDiscount,
}: {
  productData: PurchaseRequestLineItemForPurchaser;
  currency: Currency;
  hasDiscount: boolean;
}) => {
  const { product, subTotal, paidUnits, unitPrice, priceDiscount, freeUnits } = productData;
  return (
    <TableRow>
      <TableCell>
        <OrganisationProductSummary {...product.value} vesselCapacity={getVesselCapacity(product.value.vesselSize)} />
      </TableCell>
      <SummaryTableCell textAlign="center">
        <Typography>{paidUnits.value}</Typography>
        {freeUnits && <Typography variant="caption">+{freeUnits.value} free</Typography>}
      </SummaryTableCell>
      <SummaryTableCell textAlign="center">
        <CurrencyRenderer value={unitPrice} currency={currency} />
      </SummaryTableCell>
      {hasDiscount && (
        <SummaryTableCell textAlign="center">
          {priceDiscount ? (
            <>
              <CurrencyRenderer value={priceDiscount} currency={currency} />
              <Typography variant="caption">{formatDiscountPct(unitPrice, priceDiscount)}%</Typography>
            </>
          ) : (
            "-"
          )}
        </SummaryTableCell>
      )}
      <SummaryTableCell paddingRight>
        <CurrencyRenderer value={subTotal} currency={currency} />
      </SummaryTableCell>
    </TableRow>
  );
};

const SummaryTableCell = styled(TableCell, {
  shouldForwardProp: prop => !["textAlign", "paddingRight"].includes(prop as string),
})<{ paddingRight?: boolean; textAlign?: "left" | "center" | "right" }>(({ theme, paddingRight, textAlign }) => ({
  textAlign: textAlign ?? "right",
  paddingRight: paddingRight ? theme.spacing(4) : 0,
}));

interface PurchaserPurchaseRequestValuationCardProps
  extends Omit<PurchaserPurchaseRequestData["breakdown"], "lineItems"> {
  purchaserCoversDeliveryCost: boolean;
  currency: Currency;
  showDuty: boolean;
  subtotalIncVat: boolean;
  salePriceType: PurchaseRequestSalePriceType;
}

const PurchaserPurchaseRequestValuationCard = ({
  dealDiscountAmount,
  netSubtotal,
  dutyAndVat,
  deliveryFee,
  totalPayable,
  purchaserCoversDeliveryCost,
  currency,
  showDuty,
  subtotalIncVat,
}: PurchaserPurchaseRequestValuationCardProps) => {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));
  const PriceRowWithCurrency = (props: Omit<PriceRowProps, "currency">) => <PriceRow currency={currency} {...props} />;
  return (
    <Card sx={{ backgroundColor: "common.grey1", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <TableContainer>
        <Table stickyHeader={false} sx={{ backgroundColor: "common.grey1" }}>
          <colgroup>
            <col style={{ width: isSmallScreen ? "50%" : "85%" }} />
            <col style={{ width: isSmallScreen ? "50%" : "15%" }} />
          </colgroup>
          <TableBody>
            {dealDiscountAmount && (
              <PriceRowWithCurrency bottomBorder title="Deal Discount" value={dealDiscountAmount} />
            )}
            <PriceRowWithCurrency title="Net Subtotal" value={netSubtotal} />
            {dutyAndVat && (
              <>
                {showDuty && <PriceRowWithCurrency title="Duty" value={dutyAndVat.duty} />}
                <PriceRowWithCurrency title={subtotalIncVat ? "Includes VAT" : "VAT"} value={dutyAndVat.vat} />
              </>
            )}
            {deliveryFee && purchaserCoversDeliveryCost && (
              <>
                <PriceRowWithCurrency title="Delivery" value={deliveryFee.deliveryFee} />
                {deliveryFee.vat !== null && <PriceRowWithCurrency title="Delivery VAT" value={deliveryFee.vat} />}
              </>
            )}
            <PriceRowWithCurrency topBorder title="Total Payable" value={totalPayable} />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const ValuationCell = styled(TableCell, {
  shouldForwardProp: prop => !["topBorder", "bottomBorder", "paddingRight"].includes(prop as string),
})<{ topBorder?: boolean; bottomBorder?: boolean; paddingRight?: boolean }>(
  ({ theme, topBorder, bottomBorder, paddingRight }) => ({
    textAlign: "right",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: paddingRight ? theme.spacing(4) : 0,
    borderBottom: 0,
    ...(topBorder && {
      borderTop: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.common.grey3,
    }),
    ...(bottomBorder && {
      borderBottom: 1,
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.common.grey3,
    }),
  }),
);

interface PriceRowProps {
  title: string;
  value: string;
  currency: Currency;
  topBorder?: boolean;
  bottomBorder?: boolean;
}
const PriceRow = ({ title, value, currency, topBorder, bottomBorder }: PriceRowProps) => {
  return (
    <TableRow>
      <ValuationCell topBorder={topBorder} bottomBorder={bottomBorder}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          <Typography variant="subtitle1Bold">{title}</Typography>
        </Stack>
      </ValuationCell>
      <ValuationCell topBorder={topBorder} bottomBorder={bottomBorder} paddingRight>
        <CurrencyRenderer variant="subtitle1" noWrap value={value} currency={currency} />
      </ValuationCell>
    </TableRow>
  );
};

const ActionButtons = ({
  purchaseRequest,
  showTermsDialog,
  onTermsAccepted,
  onTermsRejected,
}: {
  purchaseRequest: PurchaserPurchaseRequestData;
  showTermsDialog(): void;
  onTermsRejected(): Promise<void>;
  onTermsAccepted(collectionDetails: CollectionDetails | null): Promise<void>;
}) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { state } = purchaseRequest;
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptDialog, showAcceptDialog] = useState(false);

  return (
    <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
      {state === "new" && (
        <>
          {purchaseRequest.purchaseRequestDeliveryOption.kind == "collectFromStorageLocation" && (
            <AcceptPurchaseRequestCollectionDialog
              {...purchaseRequest}
              open={acceptDialog}
              onAccept={onTermsAccepted}
              onCancel={() => showAcceptDialog(false)}
            />
          )}
          {purchaseRequest.purchaseRequestDeliveryOption.kind == "deliveryToNominatedPurchaser" && (
            <AcceptPurchaseRequestDeliveryDialog
              {...purchaseRequest}
              open={acceptDialog}
              onAccept={() => onTermsAccepted(null)}
              onCancel={() => showAcceptDialog(false)}
            />
          )}
          <FormGroup>
            <FormControlLabel
              control={<Checkbox value={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} />}
              label={
                <Stack direction="row" spacing={1}>
                  <Typography>I agree to the </Typography>
                  <Link
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      showTermsDialog();
                    }}>
                    Terms
                  </Link>
                </Stack>
              }
            />
          </FormGroup>
          <Button
            variant="outlined"
            color="error"
            onClick={async () => {
              await showConfirmationDialog({
                title: "Are you sure you want to reject these terms?",
                confirmAction: {
                  onClick: async () => {
                    await onTermsRejected();
                  },
                },
              });
            }}>
            Reject
          </Button>
          <Button variant="contained" color="success" disabled={!acceptTerms} onClick={() => showAcceptDialog(true)}>
            Accept
          </Button>
        </>
      )}
    </Stack>
  );
};
