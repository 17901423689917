import { DatePicker } from "@mui/x-date-pickers";
import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormikProps, useFormik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Incoterms,
  NewDealRequestState,
  ProductLineItem,
  UnitType,
  valuesIncoterms,
  valuesUnitType,
} from "adl-gen/ferovinum/app/db";
import { isEarlierState } from "../../../../models/new-deal-request";
import { useLayoutInfo } from "../../../layouts/layout-info";
import { Dropdown, DropdownMenuItem } from "components/widgets/dropdown/dropdown";
import { incotermsToString } from "utils/conversion-utils";
import { array, date, number, object, string } from "yup";
import { DbKey } from "adl-gen/common/db";
import { SupplierPurchaseOrdersListingType } from "adl-gen/ferovinum/app/api";
import { SupplierPOView } from "adl-gen/ferovinum/app/procurement";
import { MapEntry, makeMapEntry } from "adl-gen/sys/types";
import { AppRoutes } from "../../../../app/app-routes";
import { formatNumberOfUnits, isSinglesUnitType } from "utils/model-utils";
import { formatDateToISO8601, now } from "utils/date-utils";
import { LoadingValue, isLoaded } from "utils/utility-types";
import { PortalPageContent } from "../../../layouts/portal-page-content/portal-page-content";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { Loader } from "components/widgets/loader/loader";
import {
  SupplierNewDealRequestState,
  SupplierNewDealRequestStateStepper,
  newDealRequestStateEventsToSupplierStepsData,
} from "../../../widgets/new-deal-requests/procurement-stepper/supplier-new-deal-request-state-stepper";
import { OrganisationProductSummary } from "../../../widgets/organisation-product-summary/organisation-product-summary";
import { SupplierNewDealRequestAction } from "./supplier-new-deal-requests-page";
import { PortalPageContentHeader } from "../../../../../../../apps/client/src/ui/layouts/portal-page-content-header/portal-page-content-header";
import { TabbedPage } from "components/library/widgets/tabbed-page";

export interface SupplierNewDealRequestsPageViewProps {
  filter: SupplierPurchaseOrdersListingType;
  loadingNewDealRequests: LoadingValue<SupplierPOView[]>;
  refreshNewDealRequests(): void;
  onNewDealRequestAction(action: SupplierNewDealRequestAction): Promise<void>;
}

const getSupplierState = (state: NewDealRequestState, paidAt: number | null): SupplierNewDealRequestState => {
  if (
    state === "poCreated" ||
    state === "poAccepted" ||
    state === "collectionRequested" ||
    state === "collectionAccepted"
  ) {
    return state;
  } else if (state === "collectionConfirmed") {
    return paidAt ? "supplierIsPaid" : "collectionConfirmed";
  } else {
    throw new Error(`Unhandled carrier state: ${state}`);
  }
};

const isLcbStorageLocationName = (name: string) => {
  return name.startsWith("LCB,");
};

export const SupplierNewDealRequestsPageView: React.FC<SupplierNewDealRequestsPageViewProps> = ({
  filter,
  loadingNewDealRequests,
  refreshNewDealRequests,
  onNewDealRequestAction,
}) => {
  const history = useHistory();

  const handleTabChange = ({ key }: { key?: string }) => {
    history.push(`${AppRoutes.SupplierNewDealRequests}/${key}`);
  };

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Purchase Orders" />}>
      <TabbedPage
        handleTabChange={handleTabChange}
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <SupplierNewDealRequestsTabView
                filter={filter}
                loadingNewDealRequests={loadingNewDealRequests}
                refreshNewDealRequests={refreshNewDealRequests}
                onNewDealRequestAction={onNewDealRequestAction}
              />
            ),
          },
          {
            label: "Completed",
            key: "completed",
            content: (
              <SupplierNewDealRequestsTabView
                filter={filter}
                loadingNewDealRequests={loadingNewDealRequests}
                refreshNewDealRequests={refreshNewDealRequests}
                onNewDealRequestAction={onNewDealRequestAction}
              />
            ),
          },
        ]}
      />
    </PortalPageContent>
  );
};

export interface SupplierNewDealRequestsTabViewProps extends SupplierNewDealRequestsPageViewProps {
  disallowUpdate?: boolean;
}

export const SupplierNewDealRequestsTabView: React.FC<SupplierNewDealRequestsTabViewProps> = ({
  filter,
  loadingNewDealRequests,
  refreshNewDealRequests,
  onNewDealRequestAction,
  disallowUpdate,
}) => {
  return (
    <Loader loadingStates={[loadingNewDealRequests]} fullScreen>
      {isLoaded(loadingNewDealRequests) &&
        loadingNewDealRequests.value.length > 0 &&
        loadingNewDealRequests.value.map(deal => (
          <SupplierNewDealRequestCard
            newDealRequest={deal}
            key={deal.newDealsRequestId}
            refreshNewDealRequests={refreshNewDealRequests}
            onNewDealRequestAction={onNewDealRequestAction}
            disallowUpdate={disallowUpdate}
          />
        ))}
      {isLoaded(loadingNewDealRequests) && loadingNewDealRequests.value.length === 0 && (
        <Alert severity="info">
          You currently have no {filter === "completed" ? "completed" : "in progress"} purchase orders.
        </Alert>
      )}
    </Loader>
  );
};

interface SupplierNewDealRequestCardProps {
  newDealRequest: SupplierPOView;
  refreshNewDealRequests(): void;
  onNewDealRequestAction(action: SupplierNewDealRequestAction): Promise<void>;
  disallowUpdate?: boolean;
}

interface SinglesPerCaseInfo {
  singlesPerCase: number | undefined;
  productLineItemId: DbKey<ProductLineItem>;
  unitType: UnitType;
}
interface SupplierNewDealRequestFormValues {
  earliestCollectionDate: Date;
  incoterms: Incoterms;
  singlesPerCases: SinglesPerCaseInfo[];
}

const TabPanel: React.FC<{ tabIndex: number; value: number }> = ({ tabIndex, value, children }) => {
  if (value === tabIndex) {
    return <Box>{children}</Box>;
  }

  return null;
};

const updateStates: SupplierNewDealRequestState[] = ["poAccepted", "collectionRequested", "collectionAccepted"];
const SupplierNewDealRequestCard: React.FC<SupplierNewDealRequestCardProps> = ({
  newDealRequest,
  refreshNewDealRequests,
  onNewDealRequestAction,
  disallowUpdate,
}) => {
  const { incoterms, earliestCollectionDate, state, stateEvents, carrierCollectionDate, newDealsRequestId, paidAt } =
    newDealRequest;
  const theme = useTheme();
  const [tab, setTab] = useState<number>(0);
  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);
  const stepsData = newDealRequestStateEventsToSupplierStepsData(
    stateEvents,
    paidAt,
    earliestCollectionDate,
    carrierCollectionDate,
  );

  const isUpdateState = useMemo(() => {
    return !disallowUpdate && updateStates.includes(getSupplierState(state, paidAt));
  }, [disallowUpdate, paidAt, state]);

  const handleSubmit = useCallback(
    async (formValues: SupplierNewDealRequestFormValues): Promise<void> => {
      const productLineItemToSinglesPerCaseMap: MapEntry<DbKey<ProductLineItem>, number | null>[] =
        formValues.singlesPerCases.map(spc => {
          return makeMapEntry({ key: spc.productLineItemId, value: spc.singlesPerCase ?? null });
        });

      if (isUpdateState) {
        await onNewDealRequestAction({
          kind: "update",
          value: {
            newDealRequestId: newDealsRequestId,
            earliestCollectionDate: formatDateToISO8601(formValues.earliestCollectionDate),
          },
        });
      } else {
        await onNewDealRequestAction({
          kind: "accept",
          value: {
            newDealRequestId: newDealsRequestId,
            earliestCollectionDate: formatDateToISO8601(formValues.earliestCollectionDate),
            incoterms: formValues.incoterms,
            productLineItemToSinglesPerCaseMap,
            acceptanceTime: now(),
          },
        });
      }

      refreshNewDealRequests();
    },
    [isUpdateState, refreshNewDealRequests, onNewDealRequestAction, newDealsRequestId],
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      earliestCollectionDate: date()
        .typeError("Please input a date")
        .min(new Date(), "Must be later than today")
        .required("This is a required field"),
      incoterms: string().oneOf(valuesIncoterms).required(),
      singlesPerCases: array().of(
        object().shape({
          unitType: string().oneOf(valuesUnitType).required(),
          singlesPerCase: number()
            .min(1)
            .when(["unitType"], {
              is: (unitType: UnitType) =>
                isSinglesUnitType(unitType) && isLcbStorageLocationName(newDealRequest.storageLocationName),
              then: schema => schema.required("This is a required field"),
            }),
        }),
      ),
    });
  }, [newDealRequest.storageLocationName]);

  const initialValues: SupplierNewDealRequestFormValues = useMemo(() => {
    return {
      earliestCollectionDate: earliestCollectionDate ? new Date(earliestCollectionDate) : new Date(),
      // Note: Incoterms should have already been set by the creator of the new deal request
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      incoterms: incoterms!,
      singlesPerCases: newDealRequest.productsInShipment.map(p => {
        return {
          productLineItemId: p.productLineItemId,
          singlesPerCase: p.singlesPerCase ?? undefined,
          unitType: p.product.unitType,
        };
      }),
    };
  }, [earliestCollectionDate, incoterms, newDealRequest.productsInShipment]);

  const form = useFormik<SupplierNewDealRequestFormValues>({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit,
    validateOnMount: true,
  });

  const IncotermField = useMemo(() => {
    if (isEarlierState("poAccepted", state)) {
      return <IncotermDropdown value={form.values.incoterms} onChange={i => form.setFieldValue("incoterms", i)} />;
    } else if (incoterms) {
      return incotermsToString(incoterms);
    } else {
      return "-";
    }
  }, [state, incoterms, form]);

  const onClickSubmit = useCallback(async () => {
    await form.submitForm();
    return form.setSubmitting(false);
  }, [form]);

  const TabView = () => {
    return (
      <>
        <SupplierNewDealRequestDetailsHeader newDealRequest={newDealRequest} incotermField={IncotermField} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={"Progress"} tabIndex={0} />
            <Tab label={"Details"} tabIndex={1} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel tabIndex={0} value={tab}>
            <SupplierNewDealRequestStateStepper currentState={state} stepsData={stepsData} />
          </TabPanel>
          <TabPanel tabIndex={1} value={tab}>
            <SupplierNewDealRequestDetails newDealRequest={newDealRequest} incotermField={IncotermField} form={form} />
          </TabPanel>
        </Box>
      </>
    );
  };
  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Box sx={{ paddingBottom: theme.spacing(3), borderBottom: 1, borderColor: "divider" }}>
            {state === "poCreated" ? (
              <SupplierNewDealRequestDetails
                showHeader
                newDealRequest={newDealRequest}
                incotermField={IncotermField}
                form={form}
              />
            ) : (
              <TabView />
            )}
          </Box>
          {(state === "poCreated" || isUpdateState) && (
            <Stack spacing={3} direction="row" alignItems="center">
              <Typography>
                {isUpdateState ? "Update earliest collection date" : "Earliest date of collection"}
              </Typography>
              <DatePicker
                value={form.values["earliestCollectionDate"]}
                onChange={date => {
                  form.setFieldTouched("earliestCollectionDate", true);
                  form.setFieldValue("earliestCollectionDate", date);
                }}
                format="dd/MM/yyyy"
                minDate={new Date()}
                slots={{ textField: TextField }}
                slotProps={{
                  textField: {
                    name: "earliestCollectionDate",
                    margin: "dense",
                    variant: "outlined",
                    error: form.touched.earliestCollectionDate && Boolean(form.errors?.earliestCollectionDate),
                    helperText: form.touched.earliestCollectionDate && form.errors?.earliestCollectionDate,
                  },
                }}
              />
            </Stack>
          )}
        </Stack>
      </CardContent>
      {(state === "poCreated" || isUpdateState) && (
        <CardActions>
          <LoadingActionButton onClick={onClickSubmit} disabled={!form.isValid}>
            {isUpdateState ? "Update" : "Accept PO"}
          </LoadingActionButton>
        </CardActions>
      )}
    </Card>
  );
};

interface SupplierNewDealRequestDetailsProps {
  newDealRequest: SupplierPOView;
  showHeader?: boolean;
  incotermField: React.ReactNode;
  form: FormikProps<SupplierNewDealRequestFormValues>;
}

const SupplierNewDealRequestDetails: React.FC<SupplierNewDealRequestDetailsProps> = ({
  newDealRequest,
  showHeader,
  incotermField,
  form,
}) => {
  return (
    <Box>
      <Stack spacing={3}>
        {showHeader && (
          <SupplierNewDealRequestDetailsHeader newDealRequest={newDealRequest} incotermField={incotermField} />
        )}
        <ShipmentProductListing newDealRequest={newDealRequest} form={form} />
      </Stack>
    </Box>
  );
};

const StyledLabelCell = styled(TableCell)(() => ({
  maxWidth: "100px",
}));

const DetailField: React.FC<{ label: string; value: React.ReactNode }> = props => {
  const { isMobile } = useLayoutInfo();
  return <TableRow>{isMobile ? <DetailFieldMobile {...props} /> : <DetailFieldDesktop {...props} />}</TableRow>;
};

const DetailFieldMobile: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
  return (
    <TableCell>
      <div>
        <Typography fontWeight={"bold"}>{label}</Typography>
      </div>
      <div>{value}</div>
    </TableCell>
  );
};

const DetailFieldDesktop: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
  return (
    <>
      <StyledLabelCell>
        <Typography fontWeight={"bold"}>{label}</Typography>
      </StyledLabelCell>
      <TableCell>{value}</TableCell>
    </>
  );
};

interface SupplierNewDealRequestDetailsHeaderProps {
  newDealRequest: SupplierPOView;
  incotermField: React.ReactNode;
}

const SupplierNewDealRequestDetailsHeader: React.FC<SupplierNewDealRequestDetailsHeaderProps> = ({
  newDealRequest,
  incotermField,
}) => {
  const { poNumber, poDate, carrierName, earliestCollectionDate, carrierCollectionDate } = newDealRequest;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <DetailField label={"Purchase order number"} value={poNumber} />
          <DetailField label={"Purchase order date"} value={poDate} />
          <DetailField label={"Carrier"} value={carrierName} />
          <DetailField label={"Incoterms"} value={incotermField} />
          {earliestCollectionDate && (
            <DetailField label={"Your specified earliest collection date"} value={earliestCollectionDate} />
          )}
          {carrierCollectionDate && (
            <DetailField label={"Carrier specified collection date"} value={newDealRequest.carrierCollectionDate} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IncotermDropdownProps {
  value: Incoterms;
  onChange(i?: Incoterms): void;
}
const incotermMenuItems: DropdownMenuItem<Incoterms>[] = [...valuesIncoterms].map(incoterms => ({
  label: incotermsToString(incoterms),
  value: incoterms,
}));
const IncotermDropdown = ({ value, onChange }: IncotermDropdownProps) => {
  const defaultValue = incotermMenuItems.find(item => item.value === value);
  return (
    <Box width={235}>
      <Dropdown<Incoterms>
        inputLabel=""
        menuItems={incotermMenuItems}
        defaultValue={defaultValue}
        onChange={onChange}
        fullWidth
      />
    </Box>
  );
};

export interface ShipmentProductListingProps {
  newDealRequest: SupplierPOView;
  form: FormikProps<SupplierNewDealRequestFormValues>;
}

export const ShipmentProductListing: React.FC<ShipmentProductListingProps> = ({ newDealRequest, form }) => {
  const { isMobile } = useLayoutInfo();
  return (
    <Stack spacing={3}>
      <Typography variant="h6">Products to be delivered</Typography>
      {isMobile ? (
        <ShipmentProductListingCards newDealRequest={newDealRequest} form={form} />
      ) : (
        <ShipmentProductListingTable newDealRequest={newDealRequest} form={form} />
      )}
    </Stack>
  );
};

const ShipmentProductListingTable: React.FC<ShipmentProductListingProps> = ({ newDealRequest, form }) => {
  const { state, productsInShipment, purchaseCurrency } = newDealRequest;
  const currencyFormatter = createBigDecimalCurrencyFormatter(purchaseCurrency)();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="center">Singles per case</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsInShipment.map((p, idx) => (
            <TableRow key={p.productLineItemId}>
              <TableCell>
                <OrganisationProductSummary {...p.product} />
              </TableCell>
              <TableCell align="right">{formatNumberOfUnits(p.qty, p.product.unitType)}</TableCell>
              <TableCell align="right">{currencyFormatter.format(p.price)}</TableCell>
              {isSinglesUnitType(p.product.unitType) && state === "poCreated" ? (
                <TableCell align="center">
                  <TextField
                    name={`singlesPerCases[${idx}].singlesPerCase`}
                    label="Singles per case"
                    required={isLcbStorageLocationName(newDealRequest.storageLocationName)}
                    type="number"
                    value={form.values.singlesPerCases[idx]?.singlesPerCase ?? ""}
                    onChange={e => {
                      form.handleChange(e);
                      form.setFieldError(e.target["name"], undefined);
                    }}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.singlesPerCases &&
                      Boolean(
                        form.errors.singlesPerCases !== undefined &&
                          form.errors.singlesPerCases[idx] !== undefined &&
                          // @ts-ignore
                          form.errors.singlesPerCases[idx]["singlesPerCase"],
                      )
                    }
                    // @ts-ignore
                    helperText={form.touched.singlesPerCases && form.errors.singlesPerCases?.[idx]?.["singlesPerCase"]}
                  />
                </TableCell>
              ) : (
                <TableCell align="center">{p.singlesPerCase ?? "-"}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ShipmentProductListingCards: React.FC<ShipmentProductListingProps> = ({ newDealRequest, form }) => {
  const { state, productsInShipment, purchaseCurrency } = newDealRequest;
  const currencyFormatter = createBigDecimalCurrencyFormatter(purchaseCurrency)();

  return (
    <>
      {productsInShipment.map((p, idx) => (
        <Card key={p.productLineItemId}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OrganisationProductSummary {...p.product} />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>Quantity:</b> {formatNumberOfUnits(p.qty, p.product.unitType)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>Price:</b> {currencyFormatter.format(p.price)}
                </Typography>
              </Grid>
              {isSinglesUnitType(p.product.unitType) && state === "poCreated" ? (
                <Grid item xs={12}>
                  <TextField
                    name={`singlesPerCases[${idx}].singlesPerCase`}
                    label="Singles per case"
                    required={isLcbStorageLocationName(newDealRequest.storageLocationName)}
                    type="number"
                    value={form.values.singlesPerCases[idx]?.singlesPerCase ?? ""}
                    onChange={e => {
                      form.handleChange(e);
                      form.setFieldError(e.target["name"], undefined);
                    }}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.singlesPerCases &&
                      Boolean(
                        form.errors.singlesPerCases !== undefined &&
                          form.errors.singlesPerCases[idx] !== undefined &&
                          // @ts-ignore
                          form.errors.singlesPerCases[idx]["singlesPerCase"],
                      )
                    }
                    // @ts-ignore
                    helperText={form.touched.singlesPerCases && form.errors.singlesPerCases?.[idx]?.["singlesPerCase"]}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography>Singles per case: {p.singlesPerCase ?? "-"}</Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
