import { useParams } from "react-router-dom";
import { SaleOrderId } from "adl-gen/ferovinum/app/db";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { LoggedInContext } from "../../../../../app/app";
import { AppService } from "adl-gen/app-service";
import { useAppService } from "../../../../../hooks/use-app-service";
import { isLoaded } from "utils/utility-types";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { Loader } from "components/widgets/loader/loader";
import { OrganisationRepurchaseDetailsPageView } from "./organisation-repurchase-details-page-view";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useSelectedEntity } from "../../../../layouts/portal-page-layout/portal-page";
import { isAdmin } from "utils/user-utils";
import { AvailablePdf } from "adl-gen/ferovinum/app/uploads";

export const OrganisationRepurchaseDetailsPage = () => {
  const { saleOrderId } = useParams<{ saleOrderId: SaleOrderId }>();
  const loggedInUserContext = useContext(LoggedInContext);
  const service: AppService = useAppService();
  const [showAlert] = useAlert();
  const [refresh, setRefresh] = useState<boolean>(false);
  const selectedEntity = useSelectedEntity();

  const getSaleOrderDetailsView = useCallback(async () => {
    if (refresh) {
      setRefresh(false);
    }
    return await service.getSaleOrder({ saleOrderId });
  }, [refresh, saleOrderId, service]);

  const [loadingSaleOrderDetailsView] = useLoadingDataState(getSaleOrderDetailsView);

  const onConfirmClientPaid = useCallback(
    async _ => {
      await service
        .ackSaleOrder({ saleOrderId })
        .then(_ => setRefresh(true))
        .catch(err => {
          void showAlert({ title: "Error", body: `${err.publicMessage}. Please try again.` });
        });
    },
    [service, saleOrderId, showAlert],
  );

  const onAdminCancel = useCallback(
    async _ => {
      await service
        .cancelSaleOrder({ saleOrderId })
        .then(_ => setRefresh(true))
        .catch(err => {
          void showAlert({ title: "Error", body: `${err.publicMessage}. Please try again.` });
        });
    },
    [service, saleOrderId, showAlert],
  );

  const onRelease = useCallback(
    async _ => {
      await service
        .releaseSaleOrder({ saleOrderId })
        .then(_ => setRefresh(true))
        .catch(err => {
          void showAlert({ title: "Error", body: `${err.publicMessage}. Please try again.` });
        });
    },
    [service, saleOrderId, showAlert],
  );

  const userProfile = loggedInUserContext.loginState?.user?.profile;

  const pdfDownloadActions = useMemo(() => {
    let availablePdfs: AvailablePdf[] = [];
    if (isLoaded(loadingSaleOrderDetailsView)) {
      availablePdfs = loadingSaleOrderDetailsView.value?.availablePdfs || [];
    }

    return {
      availablePdfs: availablePdfs,
    };
  }, [loadingSaleOrderDetailsView]);

  return (
    <Loader loadingStates={[loadingSaleOrderDetailsView]} fullScreen>
      {isLoaded(loadingSaleOrderDetailsView) && (
        <OrganisationRepurchaseDetailsPageView
          pdfDownloadActions={pdfDownloadActions}
          organisationName={selectedEntity.type === "org" ? selectedEntity.value.value.businessName : ""}
          adminView={userProfile !== undefined && isAdmin(userProfile)}
          saleOrderDetailsView={loadingSaleOrderDetailsView.value}
          onConfirmClientPaid={onConfirmClientPaid}
          onAdminCancel={onAdminCancel}
          onAdminRelease={onRelease}
        />
      )}
    </Loader>
  );
};
